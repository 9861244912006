import React from 'react';

const Bag = props => (
  <svg {...props} width="33px" height="33px" viewBox="0 0 33 33">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-933.000000, -563.000000)">
        <g transform="translate(537.000000, 562.000000)">
          <g transform="translate(392.000000, 1.000000)">
            <g transform="translate(4.000000, 0.000000)">
              <rect
                fill="#0078E6"
                x="3.62376795e-13"
                y="2.13162821e-14"
                width="32.2"
                height="32.2"></rect>
              <g
                transform="translate(10.000000, 10.000000)"
                fill="#FFFFFF"
                fillRule="nonzero">
                <path d="M11.7,2.52631579 L9.1,2.52631579 L9.1,1.26315789 C9.1,0.565793684 8.517704,0 7.8,0 L5.2,0 C4.482296,0 3.9,0.565793684 3.9,1.26315789 L3.9,2.52631579 L1.3,2.52631579 C0.582296,2.52631579 0,3.09210947 0,3.78947368 L0,10.7368421 C0,11.4342063 0.582296,12 1.3,12 L11.7,12 C12.417704,12 13,11.4342063 13,10.7368421 L13,3.78947368 C13,3.09210947 12.417704,2.52631579 11.7,2.52631579 Z M5.2,1.26315789 L7.8,1.26315789 L7.8,2.52631579 L5.2,2.52631579 L5.2,1.26315789 Z"></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export default Bag;
