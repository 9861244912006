import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import request from "superagent";
import ReactGA from "react-ga";
import { withNamespaces } from "react-i18next";

// components
import Input from "components/form-text-input";
import Button from "components/button";
import Intro from "components/cv-overlay-intro";
import Message from "components/cv-overlay-success";
import Filter from "components/filter";

// icons
import ArrowRight from "icons/arrow-right";

// styles
import styles from "./styles.scss";

@inject("vacancyUploadStore")
@inject("vacancyStore")
@inject("popupStore")
@observer
class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled: false,
      errors: [],
    };
  }
  componentDidMount() {
    this.props.vacancyStore.getFilterCategories();
  }

  renderErrors() {
    let errors = [];
    let values = Object.values(this.props.form.errors()).filter(
      (value) => value !== null
    );

    for (let index = 0; index < values.length; index++) {
      errors.push(values[index]);
    }
    console.log(Object.values(this.props.form.errors()));

    return errors;
  }

  submit = (e) => {
    const { t } = this.props;
    this.setState({
      errors: [],
      disabled: true,
    });

    e.preventDefault();
    const form = this.props.form;
    ReactGA.event({
      category: "Alert",
      action: "Stel Alert in (submit)",
    });
    this.setState({
      disabled: true,
    });
    //vaidating
    this.props.form.validate({ showErrors: true }).then(({ isValid }) => {
      if (isValid) {
        this.props.vacancyUploadStore.validate(
          this.props.form.values(),
          this.props.form
        );
      }
    });
    request
      .post(`${process.env.REACT_APP_API_URL}/vacancies/v1/alert/signup`)
      // .send(form.values())
      .field("language", localStorage.getItem("i18nextLng"))
      .field("firstName", form.values().firstName)
      .field("lastName", form.values().lastName)
      .field("emailaddress", form.values().emailaddress)
      .field("phonenumber", form.values().phonenumber)
      .field("filter", JSON.stringify(this.props.vacancyStore.filter))
      .then((response) => {
        this.setState({
          disabled: true,
        });
        form.$("step").set(form.$("step").get("value") + 1);
        this.props.popupStore.setCloseColor("#143264");
        ReactGA.event({
          category: "Alert",
          action: "Stel Alert in (submit, gelukt)",
        });
      })
      .catch((error) => {
        this.setState({
          disabled: false,
          loading: false,
          errors: [error.response.body.message],
        });

        //this.error = error.response.body.message;
        // this.response = null;
      });
  };

  render() {
    const { form, t } = this.props;
    const { disabled } = this.state;
    return (
      <form
        onSubmit={form.onSubmit}
        className={[
          styles.form,
          form.$("step").get("value") === 1 ? styles["form--blue"] : null,
        ].join(" ")}
      >
        {form.$("step").get("value") === 0 ? (
          <React.Fragment>
            <Intro
              title={t("resume-alert title")}
              subtitle={t("resume-alert subtitle")}
              description={t("resume-alert description")}
            />

            <div className={styles["input-wrap"]}>
              <Filter type="alert" />

              <span className={styles.names}>
                <Input
                  label={t("firstName")}
                  field={form.$("firstName")}
                  autoComplete="fname"
                />
                <Input
                  label={t("lastName")}
                  field={form.$("lastName")}
                  autoComplete="lname"
                />
              </span>

              <Input
                label={t("emailaddress")}
                field={form.$("emailaddress")}
                autoComplete="email"
              />
              <Input
                label={t("phonenumber")}
                field={form.$("phonenumber")}
                autoComplete="tel"
              />
            </div>
            {this.renderErrors().length > 0 && (
              <p className={styles.error}>
                {this.renderErrors().map((item, i) => {
                  return <span key={i}>{item}</span>;
                })}
              </p>
            )}
            <Button
              disabled={disabled}
              type="dummy"
              className={["large", "blue", disabled ? "disabled" : null]}
              onClick={this.submit}
            >
              {t("resume-alert set-alert")} <ArrowRight />
            </Button>
          </React.Fragment>
        ) : form.$("step").get("value") === 1 ? (
          <React.Fragment>
            <Message
              title={t("resume-alert-success title")}
              button={{
                to: "/vacatures",
                text: t("resume-alert-success button-text"),
              }}
            />
          </React.Fragment>
        ) : null}
      </form>
    );
  }
}

export default withNamespaces()(Form);
