import React, { Component } from 'react';

//components
import Button from 'components/button';

//styles
import styles from './styles.scss';

//Icons
import ArrowRight from 'icons/arrow-right';
import CheckmarkBox from 'icons/checkmark-box';

class CvOverlaySuccess extends Component {
  render() {
    const { title, button } = this.props;
    return (
      <div className={styles.success}>
        <CheckmarkBox />
        <h1 className={styles.title}>{title}</h1>
        <Button to={button.to} className={['large', 'white']}>
          {button.text} <ArrowRight />
        </Button>
      </div>
    );
  }
}

export default CvOverlaySuccess;
