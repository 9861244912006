import React from 'react';

const ChevronDown = props => (
  <svg {...props} viewBox="0 0 17 10">
    <path
      fill="currentColor"
      fillRule="nonzero"
      d="M16.636 1.763L14.816 0 8.319 6.295 1.819 0 0 1.763l6.499 6.295 1.819 1.763 1.82-1.763z"
    />
  </svg>
);
export default ChevronDown;
