import { observable, action } from 'mobx';
import request from 'superagent';
export class goStore {
  @observable isLoading = false;
  @observable response = null;

  @observable isLoading = false;
  @observable response = null;
  @observable step = 1;
  @observable user = null;
  @observable error = null;
  @observable currentCategories = null;
  @observable title = null;

  @action getPageDetails() {
    this.isLoading = true;
    this.response = null;
    return request
      .get(
        `${process.env.REACT_APP_API_URL}/pages/v1/go${
          localStorage.getItem('i18nextLng') === 'nl'
            ? ''
            : `?lang=${localStorage.getItem('i18nextLng')}`
        }`
      )
      .then((response) => {
        this.response = response.body;
        this.isLoading = false;
      })
      .catch((error) => {
        this.response = error;
      });
  }

  @action setStep(step) {
    this.step = step;
  }
  @action setDetails(title, categories) {
    this.title = title;
    this.setCurrentCategories(categories);
  }

  @action setCurrentCategories(categories) {
    this.currentCategories = categories;
  }

  @action setError(error) {
    this.error = error;
  }
}

export default new goStore();
