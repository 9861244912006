import React from 'react';

const Linkedin = props => (
  <svg {...props} viewBox="0 0 22 22">
    <path
      fill="currentColor"
      fillRule="nonzero"
      d="M20.334 0H1.622C.726 0 0 .704 0 1.573v18.808c0 .87.727 1.574 1.622 1.574h18.712c.895 0 1.62-.705 1.62-1.574V1.573C21.955.704 21.23 0 20.335 0zM6.656 18.378H3.34V8.465h3.317v9.913zM4.998 7.11h-.022c-1.113 0-1.834-.76-1.834-1.713 0-.972.742-1.712 1.877-1.712 1.134 0 1.832.74 1.854 1.712 0 .953-.72 1.713-1.875 1.713zm13.616 11.267h-3.316v-5.304c0-1.332-.48-2.242-1.68-2.242-.918 0-1.462.614-1.701 1.206-.089.212-.11.508-.11.803v5.537H8.49s.043-8.983 0-9.913h3.316v1.406c.44-.676 1.226-1.639 2.987-1.639 2.183 0 3.82 1.416 3.82 4.462v5.684zm-6.829-8.44c.012-.02.03-.044.046-.067v.067h-.046z"
    />
  </svg>
);
export default Linkedin;
