import React from 'react';

const CheckmarkBox = props => (
  <svg {...props} width="80px" height="80px" viewBox="0 0 80 80">
    <g
      id="Ontwerpen"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd">
      <g
        id="00---Vacature-uploaden-pop-up-04"
        transform="translate(-936.000000, -525.000000)">
        <g transform="translate(708.000000, 525.000000)">
          <g id="Group-14">
            <g id="Group-17">
              <g id="Group-12" transform="translate(228.000000, 0.000000)">
                <g id="Group-10">
                  <g id="Group-9">
                    <g id="Group-13">
                      <rect
                        id="Rectangle-5"
                        fill="#FFFFFF"
                        x="0"
                        y="0"
                        width="80"
                        height="80"></rect>
                      <polygon
                        fill="#0078E6"
                        fillRule="nonzero"
                        points="54.5831705 24 52.0296066 26.769783 34.2319561 46.079132 26.2618004 37.9280876 21 43.3093085 34.3868664 57 37.0178062 54.151085 60 29.2230784"></polygon>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default CheckmarkBox;
