import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { withNamespaces } from 'react-i18next';

// icons
import Hamburger from 'icons/hamburger';
import Logo from 'icons/logo';
import ArrowRight from 'icons/arrow-right';
import Phone from 'icons/phone';
import Year10 from 'icons/year10';

import styles from './styles.scss';
import LanguageNavigation from '../language-navigation';

// decorators
@inject('navigationStore')
@observer
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keys: [32, 33, 34, 35, 36, 37, 38, 39, 40],
      width: null,
    };
  }
  getWidth = () => {
    this.setState({
      width: window.outerWidth,
    });
  };

  preventDefault = (e) => {
    e = e || window.event;
    if (e.preventDefault) e.preventDefault();
    e.returnValue = false;
  };

  keydown = (e) => {
    for (let i = this.state.keys.length; i--; ) {
      if (e.keyCode === this.state.keys[i]) {
        e.preventDefault();
        return;
      }
    }
  };

  wheel = (e) => {
    e.preventDefault();
  };

  componentDidMount() {
    window.addEventListener('scroll', this.checkScrollPosition);
    this.getWidth();
    window.addEventListener('resize', this.getWidth);
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', this.checkScrollPosition);
  }

  toggleMenu = (event) => {
    event.preventDefault();
    if (this.props.navigationStore.menuOpen === false) {
      this.props.navigationStore.menuOpen = true;
      if (window.addEventListener) {
        window.addEventListener('DOMMouseScroll', this.wheel, false);
      }
      window.onmousewheel = document.onmousewheel = this.wheel;
      document.onkeydown = this.keydown;
    } else {
      this.props.navigationStore.menuOpen = false;
      if (window.removeEventListener) {
        window.removeEventListener('DOMMouseScroll', this.wheel, false);
      }
      window.onmousewheel = document.onmousewheel = document.onkeydown = null;
    }
  };

  checkScrollPosition = (event) => {
    event.preventDefault();
    let scrollTop =
      window.pageYOffset ||
      (document.documentElement || document.body.parentNode || document.body)
        .scrollTop;
    if (scrollTop > 0) {
      document
        .getElementsByTagName('header')[0]
        .classList.add(styles['header-color']);

      if (this.state.width > 700) {
        document
          .getElementById('rightbtns')
          .childNodes[0].classList.add(styles['vacaturebtn-color']);
        document
          .getElementById('uspcontainer')
          .classList.add(styles['uspcontainer-color']);
      } else {
        document
          .getElementById('uspcontainer')
          .classList.add(styles['uspcontainer-color']);
      }
    } else {
      document
        .getElementsByTagName('header')[0]
        .classList.remove(styles['header-color']);

      if (this.state.width > 700) {
        document
          .getElementById('rightbtns')
          .childNodes[0].classList.remove(styles['vacaturebtn-color']);
        document
          .getElementById('uspcontainer')
          .classList.remove(styles['uspcontainer-color']);
      } else {
        document
          .getElementById('uspcontainer')
          .classList.remove(styles['uspcontainer-color']);
      }
    }
  };
  render() {
    const { t } = this.props;

    return (
      <header
        className={[
          this.props.variant
            ? styles['header-background-A']
            : styles['header-background-B'],
          styles.header,
        ].join(' ')}
      >
        <div className={styles.container}>
          <a
            onClick={this.toggleMenu}
            className={[
              this.props.variant
                ? styles['menubtn-background-A']
                : styles['menubtn-background-B'],
              styles.menubtn,
            ].join(' ')}
            href='!#'
          >
            <Hamburger
              className={[
                this.props.variant
                  ? styles['hamburger-fill-A']
                  : styles['hamburger-fill-B'],
                styles.hamburger,
              ].join(' ')}
            />
            {t('Menu')}
          </a>
          <Link className={styles.logo} to='/'>
            <Logo fill='#0078e6' />
          </Link>
          {this.state.width <= 700 && (
            <div id='rightbtns' className={styles.rightbtns}>
              <LanguageNavigation />
            </div>
          )}
          <div id='uspcontainer' className={styles.uspcontainer}>
            <Year10 fill='#0078e6' />
            <div>
              <p>NEN 4400-1 & NBBU CAO audit</p>
              <p className={styles.subtext}>10/10</p>
            </div>
          </div>

          {this.state.width <= 700 ? null : (
            <div id='rightbtns' className={styles.rightbtns}>
              <Link to='/vacatures' className={styles.vacaturebtn}>
                {t('All job offers')}
              </Link>
              <a
                href='https://concreetoonline.nocore.nl/Account/Login'
                rel='noopener noreferrer'
                target='_blank'
                className={[
                  this.props.variant
                    ? styles['hourbtn-background-A']
                    : styles['hourbtn-background-B'],
                  styles.hourbtn,
                ].join(' ')}
              >
                {t('Time registration')}{' '}
                <ArrowRight
                  className={[
                    this.props.variant
                      ? styles['arrow-fill-A']
                      : styles['arrow-fill-B'],
                    styles.arrow,
                  ].join(' ')}
                />
              </a>
              <a
                className={[
                  this.props.variant
                    ? styles['phonebtn-background-A']
                    : styles['phonebtn-background-B'],
                  styles.phonebtn,
                ].join(' ')}
                href='tel:0107200720'
              >
                <Phone
                  className={[
                    this.props.variant
                      ? styles['phone-fill-A']
                      : styles['phone-fill-B'],
                    styles.phone,
                  ].join(' ')}
                />{' '}
                010-7200720
              </a>
              <LanguageNavigation />
            </div>
          )}
        </div>
      </header>
    );
  }
}

export default withNamespaces()(Header);
