import React, { Component } from "react";
import YouTube from "react-youtube";
// styles
import styles from "./styles.scss";

class Video extends Component {
  render() {
    const { video } = this.props;
    const options = {
      height: "390",
      width: "640",
      playerVars: {
        autoplay: true,
        modestbranding: true,
        showinfo: false,
        controls: false,
        enablejsapi: true
      }
    };
    return (
      <YouTube
        videoId={video}
        containerClassName={styles.video}
        opts={options}
      />
    );
  }
}

export default Video;
