import i18n from 'i18next';
import { reactI18nextModule } from 'react-i18next';
// import LanguageDetector from 'i18next-browser-languagedetector';

import translationNL from './helpers/locales/nl/translation.json';
import translationEN from './helpers/locales/en/translation.json';
import translationHU from './helpers/locales/hu/translation.json';
import translationRO from './helpers/locales/ro/translation.json';

// the translations
const resources = {
  nl: {
    translation: translationNL,
  },
  en: {
    translation: translationEN,
  },
  hu: {
    translation: translationHU,
  },
  ro: {
    translation: translationRO,
  },
};

if( !window.localStorage.getItem("i18nextLng") ) {
  window.localStorage.setItem("i18nextLng", 'nl');
}

i18n
  // .use(LanguageDetector)
  .use(reactI18nextModule) // passes i18n down to react-i18next
  .init({
    resources,
    lng: window.localStorage.getItem("i18nextLng") || 'nl',
    fallbackLng: 'nl',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    keySeparator: false, // we do not use keys in form messages.welcome
  });

export default i18n;
