import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import ReactGA from 'react-ga';
import CvOverlay from 'components/cv-overlay';
import GoSignup from 'components/go-signup';
import Button from 'components/button';
import styles from './styles.scss';
// icons
import ArrowRight from 'icons/arrow-right';
class SimpleContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }
  // i18n
  openPopup = (text, link) => {
    this.setState({
      modal: true,
    });
    ReactGA.event({
      category: `Button naar ${text}(${link})`,
      action: 'Button aangeklikt',
    });
  };

  closePopup = () => {
    this.setState({
      modal: false,
    });
  };
  render() {
    const { details, type, buttons, t } = this.props;
    const { modal } = this.state;
    return (
      <div
        className={[
          styles['simple-content'],
          type ? styles[`simple-content--${type}`] : null,
        ].join(' ')}
      >
        <h3 dangerouslySetInnerHTML={{ __html: details.subtitle }} />
        <h1 dangerouslySetInnerHTML={{ __html: details.title }} />
        <div
          className={styles.content}
          dangerouslySetInnerHTML={{ __html: details.content }}
        />
        {buttons && (
          <div className={styles['button-container']}>
            {buttons.map((item, i) => {
              return (
                <Button
                  key={i}
                  type='dummy'
                  to={item.link}
                  className={['large', 'blue']}
                  onClick={(e) => this.openPopup(item.text, item.link)}
                >
                  {item.text}
                  <ArrowRight fill='white' />
                </Button>
              );
            })}
          </div>
        )}
        {modal && (
          <CvOverlay close={this.closePopup}>
            <GoSignup
              details={{
                id: 0,
                title: t('go-signup title'),
                description: t('go-signup description'),
              }}
            />
          </CvOverlay>
        )}
      </div>
    );
  }
}

export default withNamespaces()(SimpleContent);
