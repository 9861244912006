import React, { Component } from "react";
import { observer } from "mobx-react";
import ReactDOM from "react-dom";
// styles
import styles from "./styles.scss";
// components
import InlineVideo from "components/inline-video";
// icons
import Checkmarkvacancy from "icons/checkmarkvacancy";

// decorators
@observer
class VacancyContent extends Component {
  componentDidMount() {
    this.appendArrow();
    this.appendVideo();
  }

  appendVideo = () => {
    let divs = this.refs.article.getElementsByClassName("video");
    if (divs.length > 0) {
      for (let i = 0; i < divs.length; i++) {
        let video = divs[i].getAttribute("data-video-id");

        ReactDOM.render(<InlineVideo videoId={video} key={i} />, divs[i]);
      }
    }
  };

  appendArrow = () => {
    let divs = this.refs.article.getElementsByTagName("li");
    if (divs.length > 0) {
      for (let i = 0; i < divs.length; i++) {
        const id = Math.random();
        const d = document.createElement("span");
        d.id = id;
        divs[i].prepend(d);
        ReactDOM.render(
          <Checkmarkvacancy className={styles.iconcheck} />,
          document.getElementById(id)
        );
      }
    }
  };

  render() {
    const { content } = this.props;
    return (
      <React.Fragment>
        <p
          className={styles.intro}
          dangerouslySetInnerHTML={{ __html: content.intro }}
        />
        <article
          className={styles.description}
          ref="article"
          dangerouslySetInnerHTML={{ __html: content.content }}
        />
      </React.Fragment>
    );
  }
}

export default VacancyContent;
