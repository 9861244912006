import React, { Component } from 'react';
import styles from './styles.scss';
//icons
import MailBlock from 'icons/mail-block';
class GoTogetherPanel extends Component {
  render() {
    const {
      name,
      firstName,
      lastName,
      description,
      position,
      image,
      emailaddress
    } = this.props.details;
    return (
      <div class={styles.panel}>
        {image && <img src={image} alt={name} />}
        <h2
          dangerouslySetInnerHTML={{
            __html: `${firstName}<br/>${lastName}`
          }}></h2>
        <span
          className={styles.position}
          dangerouslySetInnerHTML={{ __html: position }}
        />
        <p dangerouslySetInnerHTML={{ __html: description }} />
        {emailaddress && (
          <a className={styles.emailaddress} href={`mailto:${emailaddress}`}>
            <MailBlock />
            {emailaddress}
          </a>
        )}
      </div>
    );
  }
}

export default GoTogetherPanel;
