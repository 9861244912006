import React from 'react';

const locator = props => (
  <svg width="33px" height="33px" viewBox="0 0 33 33">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-537.000000, -563.000000)">
        <g transform="translate(537.000000, 562.000000)">
          <g transform="translate(0.000000, 1.000000)">
            <g>
              <rect
                fill="#0078E6"
                x="3.62376795e-13"
                y="2.13162821e-14"
                width="32.2"
                height="32.2"></rect>
              <path
                d="M15.9786087,8 C12.6637391,8 10,10.55175 10,13.7295 C10,16.90625 15.9786087,24.667 15.9786087,24.667 C15.9786087,24.667 21.9572174,16.90625 21.9572174,13.7295 C21.9572174,10.55175 19.2934783,8 15.9786087,8 Z M15.9786087,16.333 C14.4572174,16.333 13.2608696,15.1875 13.2608696,13.7285 C13.2608696,12.2705 14.4561739,11.124 15.9786087,11.124 C17.5,11.124 18.6963478,12.2695 18.6963478,13.7285 C18.6963478,15.187475 17.5000261,16.333 15.9786087,16.333 Z"
                fill="#FFFFFF"
                fillRule="nonzero"></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export default locator;
