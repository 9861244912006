import React from 'react';

const Chat = props => (
  <svg
    {...props}
    width="110px"
    height="104px"
    viewBox="0 0 110 104"
    version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g fill="#FFFFFF">
        <g>
          <path d="M0,0 L0,73.886 L64.29,73.886 L92.155,94.232 L92.155,73.886 L104.79,73.886 L104.79,0.001 L0,0.001 L0,0 Z M6.75,6.75 L98.04,6.75 L98.04,67.136 L85.405,67.136 L85.405,80.946 L66.49,67.135 L6.75,67.135 L6.75,6.751 L6.75,6.75 Z"></path>
          <path d="M29.262,31.682 L31.513,38.202 L26.993,38.202 L29.262,31.682 Z M25.137,43.539 L33.372,43.539 L34.836,47.744 L41.182,47.744 L32.836,25.698 L25.782,25.698 L17.436,47.744 L23.688,47.744 L25.137,43.539 Z M48.824,42.877 L48.824,39.097 L52.965,39.097 C54.698,39.097 55.563,39.727 55.563,40.987 C55.563,42.264 54.698,42.894 52.965,42.894 L48.824,42.877 Z M48.824,30.579 L52.604,30.579 C54.257,30.579 55.074,31.194 55.074,32.407 C55.074,33.635 54.24,34.248 52.587,34.248 L48.824,34.248 L48.824,30.579 L48.824,30.579 Z M61.72,41.412 C61.72,39.272 60.271,37.382 58.35,36.673 C60.067,35.791 61.279,34.17 61.279,32.029 C61.279,28.123 58.649,25.698 52.729,25.698 L42.839,25.698 L42.839,47.744 L53.294,47.744 C59.137,47.744 61.72,45.318 61.72,41.412 Z M74.935,48.154 C79.297,48.154 82.653,46.186 84.747,43.082 L79.833,39.649 C78.621,41.492 76.966,42.421 74.857,42.421 C72.133,42.421 69.519,40.484 69.519,36.721 C69.519,33.367 71.77,31.021 74.842,31.021 C76.998,31.021 78.621,31.964 79.692,33.808 L84.997,30.878 C83.061,27.352 79.266,25.288 74.935,25.288 C68.118,25.288 63.298,29.998 63.298,36.721 C63.298,43.444 68.118,48.154 74.935,48.154 L74.935,48.154 Z"></path>
        </g>
      </g>
    </g>
  </svg>
);
export default Chat;
