import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import Notch from 'components/notch';
import Button from 'components/button';

import styles from './styles.scss';

//Icons
import ArrowRight from 'icons/arrow-right';
import Checkmark from 'icons/checkmark';

@inject('homeStore')
@observer
class HomeWerkwijze extends Component {
  render() {
    const { methods } = this.props.homeStore.response;
    return (
      <section>
        <Notch variants={['left']} />
        <div className={['wrapper', styles.block].join(' ')}>
          <div className={styles.content}>
            <h3>{methods.subTitle}</h3>
            <h1 dangerouslySetInnerHTML={{ __html: methods.title }} />
            <p dangerouslySetInnerHTML={{ __html: methods.description }} />
            <Button to={methods.button.link} className={['large', 'blue']}>
              {methods.button.text}
              <ArrowRight fill="white" />
            </Button>
          </div>
          <div className={styles.flex}>
            <div className={styles.route}>
              <div
                className={[styles['number-1'], styles['numbers']].join(' ')}>
                1
              </div>
              <div className={styles.circle}>
                <Checkmark />
              </div>
              <div
                className={[styles['number-2'], styles['numbers']].join(' ')}>
                2
              </div>

              <div className={styles.border} />
            </div>
            <div className={styles.roadmap}>
              <div
                className={[styles['step-content'], styles['step']].join(' ')}>
                <h2>{methods.stepOne.title}</h2>
                <p
                  dangerouslySetInnerHTML={{
                    __html: methods.stepOne.description
                  }}
                />
              </div>
              <div className={[styles['step-image'], styles['step']].join(' ')}>
                <h2
                  dangerouslySetInnerHTML={{ __html: methods.stepTwo.title }}
                />
                <img src={methods.stepTwo.image} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default HomeWerkwijze;
