import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

//styles
import styles from './styles.scss';

//form
import Form from './form';
import UploadForm from './form.class';
const form = new UploadForm();

// decorators
@inject('vacancyUploadStore')
@inject('popupStore')
@observer
class CvUpload extends Component {
  componentWillUnmount() {
    form.clear();
  }

  render() {
    const { details } = this.props;
    return (
      <React.Fragment>
        <Form form={form} details={details} />
        <div
          className={[
            styles.progressbar,
            form.values().step === 0 ? styles['progressbar-step-1'] : null,
            form.values().step === 1 ? styles['progressbar-step-2'] : null,
          ].join(' ')}
        />
      </React.Fragment>
    );
  }
}

export default CvUpload;
