import { Form } from "mobx-react-form";
import Validator from "validatorjs";

Validator.useLang(window.localStorage.getItem("i18nextLng"));
let messages = Validator.getMessages(window.localStorage.getItem("i18nextLng"));
Validator.setMessages(window.localStorage.getItem("i18nextLng"), messages);

// Validator.setMessages(window.localStorage.getItem("i18nextLng"), messages);
// Validator.register(
//   'telephone',
//   function(value, requirement, attribute) {
//     // requirement parameter defaults to null
//     return value.match(
//       /^((\+|00(\s|\s?\s?)?)31(\s|\s?\s?)?(\(0\)[\s]?)?|0)[1-9]((\s|\s?\s?)?[0-9])((\s|\s?-\s?)?[0-9])((\s|\s?-\s?)?[0-9])\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]$/
//     );
//   },
//   'Het telefoonnummer is niet in het juiste formaat'
// );

class AlertForm extends Form {
  plugins() {
    return { dvr: Validator };
  }

  setup() {
    return {
      fields: [
        {
          name: "step",
          value: 0,
        },
        {
          name: "firstName",
          label: "Voornaam",
          rules: "required|string|between:2,25",
        },
        {
          name: "lastName",
          label: "Achternaam",
          rules: "required|string|between:2,25",
        },
        {
          name: "emailaddress",
          label: "E-mailadres",
          rules: "required|email|string",
        },
        {
          name: "phonenumber",
          label: "Telefoonnummer",
          rules: "required|string",
        },
      ],
    };
  }
}
export default AlertForm;
