import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Helmet } from 'react-helmet';

// components
import PageHero from 'components/page-hero';
import GridTest from 'components/grid-test';
import ArticleDetail from 'components/article-detail';
import VacancyTop from 'components/vacancy-top';
import PageLoading from 'components/page-loading';

// decorators
@inject('articleSingleStore')
@observer
class ArticleSingle extends Component {
  componentDidMount() {
    this.props.articleSingleStore.details(this.props.match.params.slug);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.match.params.slug !== nextProps.match.params.slug) {
      this.props.articleSingleStore.details(nextProps.match.params.slug);
    }
  }
  render() {
    const { response, isLoading } = this.props.articleSingleStore;

    return (
      <React.Fragment>
        {response ? (
          <React.Fragment>
            {response.seo && (
              <Helmet>
                <meta charSet='utf-8' />
                <title>{response.seo.siteTitle}</title>
                <meta name='description' content={response.seo.description} />
                <meta property='fb:pages' content='828024637343069' />
                <meta property='og:title' content={response.seo.title} />
                <meta
                  property='og:description'
                  content={response.seo.description}
                />
                <meta
                  property='og:url'
                  content={`${process.env.REACT_APP_SITE_URL}${window.location.pathname}`}
                />
                <meta property='og:image' content={response.seo.image} />
                <meta property='og:site_name' content={response.seo.siteName} />
              </Helmet>
            )}
            <PageHero
              loading={isLoading}
              title={`<b>${response.title}</b>`}
              image={response.image}
              share
              variants={['blog']}
            />
            <VacancyTop detail={response} />
            <div className='wrapper'>
              <ArticleDetail detail={response} />
            </div>
          </React.Fragment>
        ) : (
          <PageLoading color='#0078e6' />
        )}
        <GridTest variants={['default']} />
      </React.Fragment>
    );
  }
}

export default ArticleSingle;
