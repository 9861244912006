import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import Button from 'components/button';

import styles from './styles.scss';

//Icons
import ArrowRight from 'icons/arrow-right';

@inject('homeStore')
@observer
class HomeQuestions extends Component {
  render() {
    const { questions } = this.props.homeStore.response;
    return (
      <section
        className={styles.background}
        style={{
          backgroundImage: `url(
          ${questions.image}
        )`
        }}>
        <div className={['wrapper', styles.block].join(' ')}>
          <h1
            className={styles.title}
            dangerouslySetInnerHTML={{ __html: questions.title }}
          />
          <div className={styles.description}>{questions.description}</div>
          <div className={styles.headers}>
            {questions.items.map((item, i, items) => {
              return (
                <div className={styles.answerblock} key={i}>
                  <h2>{item.title}</h2>
                  <p
                    className={styles.answer}
                    dangerouslySetInnerHTML={{ __html: item.description }}
                  />
                  {items.length - 1 === i && (
                    <Button
                      to={questions.button.link}
                      className={['large', 'white']}>
                      {questions.button.text} <ArrowRight fill="#0078e6" />
                    </Button>
                  )}
                </div>
              );
            })}
          </div>
        </div>
        {/* <Grid />
        <Notch variants={['left']} /> */}
      </section>
    );
  }
}

export default HomeQuestions;
