import React from 'react';

const MailBlockVacancy = props => (
  <svg {...props} width="32px" height="32px" viewBox="0 0 42 42">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-536.000000, -209.000000)">
        <g transform="translate(-45.000000, 0.000000)">
          <g transform="translate(581.000000, 208.000000)">
            <g transform="translate(0.000000, 1.000000)">
              <rect
                fill="#0078e6"
                x="0"
                y="0"
                width="41.9575758"
                height="41.9575758"
              />
              <path
                d="M12.5789474,14 C11.7041579,14 11,14.7209056 11,15.6154885 L11,27.3845115 C11,28.2790944 11.7041579,29 12.5789474,29 L29.4210526,29 C30.2958421,29 31,28.2790944 31,27.3845115 L31,15.6154885 C31,14.7209056 30.2958421,14 29.4210526,14 L12.5789474,14 Z M12.8174342,16.2014486 L21.0248026,20.3698116 L29.1826974,16.2014486 C29.5044605,16.0350864 29.9526447,16.3196825 29.9474868,16.6869362 L29.9474868,17.7583571 C29.9474868,17.9550646 29.8313263,18.1465329 29.6596447,18.2354608 L21.24675,22.5211445 C21.0997526,22.5975261 20.9167763,22.5975261 20.7697763,22.5211445 L12.3405658,18.2354608 C12.1688974,18.1465248 12.0527237,17.9550432 12.0527237,17.7583571 L12.0527237,16.6869362 C12.0527237,16.1480918 12.4073816,16.092619 12.8175132,16.2014486 L12.8174342,16.2014486 Z"
                fill="#FFFFFF"
                fillRule="nonzero"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export default MailBlockVacancy;
