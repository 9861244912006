import ReactGA from "react-ga";
import ReactPixel from "react-facebook-pixel";

export const trackPage = (page, options = {}) => {
  ReactGA.set({
    page,
    ...options,
  });
  ReactGA.pageview(page);
  ReactPixel.pageView();
};

export const transitionGroupClasses = (styles, className) => {

  return {
    appear: styles[`${className}--appear`],
    appearActive: styles[`${className}--appear-active`],
    appearDone: styles[`${className}--appear-done`],
    enter: styles[`${className}--enter`],
    enterActive: styles[`${className}--enter-active`],
    enterDone: styles[`${className}--enter-done`],
    exit: styles[`${className}--exit`],
    exitActive: styles[`${className}--exit-active`],
    exitDone: styles[`${className}--exit-done`],
  };
};
