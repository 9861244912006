import React, { Component } from "react";

import styles from "./styles.scss";

class Member extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: null
    };
  }
  getWidth = () => {
    this.setState({
      width: window.outerWidth
    });
  };

  preventDefault = e => {
    e = e || window.event;
    if (e.preventDefault) e.preventDefault();
    e.returnValue = false;
  };

  componentDidMount() {
    this.getWidth();
    window.addEventListener("resize", this.getWidth);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.getWidth);
  }

  render() {
    const { img, position, firstName, lastName, description } = this.props;
    return (
      <div className={styles.member}>
        <div className={styles.imagewrapper}>
          <img src={img} alt="" />
          <h3>{position}</h3>
        </div>
        <div className={styles.content}>
          <h3>
            {firstName} {this.state.width <= 486 ? null : <br />}
            {lastName}
            {this.state.width <= 486 ? null : <span>.</span>}
          </h3>
          <p dangerouslySetInnerHTML={{ __html: description }} />
        </div>
      </div>
    );
  }
}

export default Member;
