import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Helmet } from 'react-helmet';

// components
import PageHero from 'components/page-hero';
import HomeQuestions from 'components/home-questions';
import DnaCta from 'components/dna-cta';
import FeaturedImage from 'components/featured-image';

import FeaturedFilter from 'components/featured-filter';
import MarqueeBlock from 'components/marquee-block';
import HomeWerkwijze from 'components/home-werkwijze';
import PageLoading from 'components/page-loading';
import GridTest from 'components/grid-test';

// decorators
@inject('homeStore')
@observer
class Home extends Component {
  componentDidMount() {
    this.props.homeStore.getPageDetails();
  }

  render() {
    const { response, isLoading } = this.props.homeStore;
    return response ? (
      <React.Fragment>
        <Helmet>
          <meta charSet='utf-8' />
          <title>{response.seo.siteTitle}</title>
          <meta name='description' content={response.seo.description} />
          <meta property='fb:pages' content='828024637343069' />
          <meta property='og:title' content={response.seo.title} />
          <meta property='og:description' content={response.seo.description} />
          <meta
            property='og:url'
            content={`${process.env.REACT_APP_SITE_URL}${window.location.pathname}`}
          />
          <meta property='og:image' content={response.seo.image} />
          <meta property='og:site_name' content={response.seo.siteName} />
        </Helmet>
        <PageHero
          loading={isLoading}
          title={response.pageHero.title}
          video={response.pageHero}
          subTitle={
            response.pageHero.firstName
              ? `${response.pageHero.firstName}${response.pageHero.age &&
                  `${', '}${response.pageHero.age}${' '}`}`
              : null
          }
          slogan
          image={response.pageHero.image}
          share='home'
          variants={['story']}
        />
        <FeaturedFilter />
        <HomeQuestions />
        <HomeWerkwijze />
        <MarqueeBlock
          pretext={response.partners.title}
          partners={response.partners.items}
        />
        <DnaCta response={response.about} />
        <FeaturedImage response={response.cta} align='right' />
        {/* <HomeStable
          pretitle={response.cta.subtitle}
          title={response.cta.title}
          description={response.cta.description}
          img={response.cta.image}
          buttoncontent={response.cta.button.text}
          buttonurl={response.cta.button.link}
          variants={['right']}
        /> */}

        <GridTest variants={['default']} />
      </React.Fragment>
    ) : (
      <PageLoading color='#0078e6' />
    );
  }
}

export default Home;