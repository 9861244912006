import React, { Component } from 'react';
import { withNamespaces } from "react-i18next";
//styles
import styles from './styles.scss';

//icons
import Cross from 'icons/cross';

class CookieNotice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: true
    };
  }

  setCookie() {
    if (window.localStorage.getItem('acceptedCookie') === null) {
      window.localStorage.setItem('acceptedCookie', false);
    }
  }

  hide = () => {
    window.localStorage.setItem('acceptedCookie', true);
    this.setState({ show: false });
  };

  componentDidMount() {
    this.setCookie();
    if (window.localStorage.getItem('acceptedCookie') === true) {
      this.setState({ show: false });
    }
  }

  render() {
    const { t } = this.props;

    return window.localStorage.getItem('acceptedCookie') !== 'true' ? (
      <div className={styles['cookie-notice']}>
        <div className={styles.close} onClick={this.hide}>
          <Cross />
        </div>
        <div className={styles.wrapper}>
          <h4 className={styles.title}>{t('Cookie Header')}</h4>
          <p className={styles.description}>
            {t('Cookie Description')}
          </p>
        </div>
      </div>
    ) : null;
  }
}
export default withNamespaces()(CookieNotice);
