import { observable, action } from 'mobx';
import request from 'superagent';

export class popupStore {
  @observable popupOpen = false;

  @observable closeColor = '#0078e6';

  @observable story = null;
  @observable storyLoading = true;
  @observable storyLoaded = false;

  @action switchPopup() {
    this.popupOpen = true;
  }
  @action setCloseColor(color) {
    this.closeColor = color;
  }
  @action getStory() {
    this.storyLoading = true;
    this.storyLoaded = false;
    this.story = null;
    return request
      .get(
        `${process.env.REACT_APP_API_URL}/stories/v1/random${
          localStorage.getItem('i18nextLng') === 'nl'
            ? ''
            : `?lang=${localStorage.getItem('i18nextLng')}`
        }`
      )
      .then((response) => {
        this.story = response.body;
      })
      .then(() => {
        this.storyLoading = false;
        this.storyLoaded = true;
      })
      .catch((error) => {
        this.story = error;
      });
  }
}
export default new popupStore();
