import { observable, action } from 'mobx';
import request from 'superagent';
export class methodStore {
  @observable isLoading = false;
  @observable response = null;

  @action getPageDetails() {
    this.isLoading = true;
    return request
      .get(
        `${process.env.REACT_APP_API_URL}/pages/v1/method${
          localStorage.getItem('i18nextLng') === 'nl'
            ? ''
            : `?lang=${localStorage.getItem('i18nextLng')}`
        }`
      )
      .then((response) => {
        this.response = response.body;
      })
      .then(() => {
        this.isLoading = false;
      })
      .catch((error) => {
        this.response = error;
      });
  }
}

export default new methodStore();
