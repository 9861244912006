import React, { Component } from "react";
import { inject, observer } from "mobx-react";

import VideoGridItem from "components/video-grid-item";

import styles from "./styles.scss";

// decorators
@inject("videosStore")
@observer
class VideoGrid extends Component {
  render() {
    const { videos } = this.props.videosStore.response;
    return (
      <div className={["wrapper", styles.list].join(" ")}>
        {videos.map((item, i) => {
          return <VideoGridItem item={item} key={i} />;
        })}
      </div>
    );
  }
}
export default VideoGrid;
