import React, { Component, useState } from 'react';
import { inject, observer } from 'mobx-react';
import styles from './styles.scss';
import { CSSTransition } from 'react-transition-group';
import { transitionGroupClasses } from 'helpers';

// icons
import FlagDutch from 'icons/flag-dutch';
import FlagEnglish from 'icons/flag-english';
import FlagHungarian from 'icons/flag-hungarian';
import FlagRomanian from '../../icons/flag-romanian';
import Arrow from '../../icons/arrow';
import { withNamespaces } from 'react-i18next';

// decorators
@inject('goStore')
@inject('defaultPageStore')
@observer
class LanguageNavigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSelected: localStorage.getItem('i18nextLng'),
      open: false,
    };

    this.changeLanguage = this.changeLanguage.bind(this);
  }

  changeLanguage = (lng) => {
    this.props.i18n.changeLanguage(lng);
    localStorage.setItem('i18nextLng', lng);

    this.setState((state) => ({
      isSelected: lng,
      open: false,
    }));

    window.location.reload();
  };

  toggle = (isOpen, setOpen) => {
    this.setState((state) => ({
      open: !this.state.open,
    }));
  };

  render() {
    const { t, i18n } = this.props;

    return (
      <div className={styles.navigation}>
        <span
          className={[
            styles.current,
            this.open ? styles['current--is-open'] : null,
          ].join(' ')}
          onClick={() => this.toggle()}
        >
          <div className={styles.languageSelector}>
            {(() => {
              switch (localStorage.getItem('i18nextLng')) {
                case 'nl':
                  return (
                    <>
                      <FlagDutch />
                    </>
                  );
                case 'en':
                  return (
                    <>
                      <FlagEnglish />
                    </>
                  );
                case 'hu':
                  return (
                    <>
                      <FlagHungarian />
                    </>
                  );
                case 'ro':
                  return (
                    <>
                      <FlagRomanian />
                    </>
                  );
                default:
                  return (
                    <>
                      <FlagDutch />
                    </>
                  );
              }
            })()}
            <div className={styles.arrow}>
              <Arrow />
            </div>
          </div>
        </span>
        <CSSTransition
          in={this.state.open}
          timeout={300}
          classNames={transitionGroupClasses(styles, 'list')}
          unmountOnExit
          onClick={() => this.toggle()}
        >
          <ul className={styles.list}>
            <li
              onClick={() => {
                this.changeLanguage('nl');
              }}
            >
              <FlagDutch />
            </li>
            <li
              onClick={() => {
                this.changeLanguage('en');
              }}
            >
              <FlagEnglish />
            </li>
            {/* <li
              onClick={() => {
                i18n.changeLanguage('hu') && window.location.reload();
              }}
            >
              <FlagHungarian />
            </li>
            <li
              onClick={() => {
                i18n.changeLanguage('ro') && window.location.reload();
              }}
            >
              <FlagRomanian />
            </li> */}
            <li className={styles.arrow} onClick={() => this.toggle()}>
              <Arrow fill='#0078e6' className={styles.ignore} />
            </li>
          </ul>
        </CSSTransition>
      </div>
    );
  }
}

export default withNamespaces()(LanguageNavigation);
