import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Helmet } from 'react-helmet';

//Components
import PageLoading from 'components/page-loading';
import PageHero from 'components/page-hero';
import GoAbout from 'components/go-about';
import GoIcons from 'components/go-icons';
import GoTogether from 'components/go-together';
import GoDeelnemen from 'components/go-deelnemen';
import GoPartner from 'components/go-partner';
import GridTest from 'components/grid-test';

// decorators
@inject('goStore')
@observer
class Go extends Component {
  componentDidMount() {
    this.props.goStore.getPageDetails();
  }
  render() {
    const { response, isLoading } = this.props.goStore;
    return response ? (
      <React.Fragment>
        {response.seo && (
          <Helmet>
            <meta charSet="utf-8" />
            <title>{response.seo.siteTitle}</title>
            <meta name="description" content={response.seo.description} />
            <meta property="fb:pages" content="828024637343069" />
            <meta property="og:title" content={response.seo.title} />
            <meta
              property="og:description"
              content={response.seo.description}
            />
            <meta
              property="og:url"
              content={`${process.env.REACT_APP_SITE_URL}${window.location.pathname}`}
            />
            <meta property="og:image" content={response.seo.image} />
            <meta property="og:site_name" content={response.seo.siteName} />
          </Helmet>
        )}
        <PageHero
          title={response.pageHero.title}
          loading={isLoading}
          image={response.pageHero.image}
        />
        {response.contentOne && <GoAbout details={response.contentOne} />}
        {response.contentTwo && <GoIcons details={response.contentTwo} />}
        {response.contentThree && (
          <GoTogether details={response.contentThree} />
        )}
        {response.contentFour && <GoDeelnemen details={response.contentFour} />}
        {response.contentFive && <GoPartner details={response.contentFive} />}
        <GridTest variants={['default']} />
      </React.Fragment>
    ) : (
      <PageLoading color="#0078e6" />
    );
  }
}

export default Go;
