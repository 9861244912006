import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

//styles
import styles from './styles.scss';

//components
import PageLoading from 'components/page-loading';
import GridTest from 'components/grid-test';

//form
import Form from './form';
import UnsubcribeForm from './form.class';
const form = new UnsubcribeForm();

// decorators
@inject('contactStore')
@observer
class Unsubscribe extends Component {
  componentDidMount() {
    this.props.contactStore.getPageDetails();
  }
  render() {
    const { response } = this.props.contactStore;
    return (
      <React.Fragment>
        {response ? (
          <React.Fragment>
            <div className={styles.content}>
              <div className="wrapper">
                <h3 className={styles.subtitle}>E-mail voorkeuren</h3>
                <h1 className={styles.title}>Uitschrijven</h1>
                <p>Pas hier je emailvoorkeuren aan.</p>
                <Form form={form} />
              </div>
            </div>
          </React.Fragment>
        ) : (
          <PageLoading color="#0078e6" />
        )}
        <GridTest variants={['default']} />
      </React.Fragment>
    );
  }
}

export default Unsubscribe;
