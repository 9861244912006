import React, { Component } from 'react';

import styles from './styles.scss';

class MethodContent extends Component {
  render() {
    const { content } = this.props;
    return (
      content && (
        <div className={['wrapper', styles.content].join(' ')}>
          <div className={styles.width}>
            <h1>{content.title}</h1>
            <p dangerouslySetInnerHTML={{ __html: content.description }} />
          </div>
          <div className={styles.threeblock}>
            {content.steps.map((item, i) => {
              return (
                <div className={styles.block} key={i}>
                  <h3 dangerouslySetInnerHTML={{ __html: item.title }} />
                  <p dangerouslySetInnerHTML={{ __html: item.description }} />
                  {/* <p>
              Solliciteer op een vacature <br />
              <b>Bekijk de vacatures</b> <ArrowRight />{" "}
            </p> */}
                </div>
              );
            })}

            {/* <div className={styles.block}>
            <h3>KOM LANGS BIJ ONS IN ROTTERDAM.</h3>
            <p>
              Je bent welkom op werkdagen van <b>8:30 tot 18:00</b>
            </p>
          </div>
          <div className={styles.block}>
            <h3>BEL ONS MET AL JE VRAGEN OP.</h3>
            <p>
              Bel ons op werkdagen op<br/>
              <b>010 7200 720</b>
            </p>
          </div> */}
          </div>
        </div>
      )
    );
  }
}

export default MethodContent;
