import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { Switch } from 'react-router';
import ReactGA from 'react-ga';
import { hotjar } from 'react-hotjar';
import ReactPixel from 'react-facebook-pixel';

// includes
import Header from 'components/header';
import CookieNotice from 'components/cookie-notice';
import UpdateBrowserNotice from 'components/update-browser-notice';

import Footer from 'components/footer';
import Overlay from 'components/overlay';
import Menu from 'components/menu';
import 'i18n';

// views
import Home from 'pages/home';
import RadioLanding from "pages/radio";
import Go from 'pages/go';
import Contact from 'pages/contact';
import About from 'pages/about';
import Salesbox from 'pages/salesbox';
import Partners from 'pages/partners';
import ArticleOverview from 'pages/article-overview';
import ArticleSingle from 'pages/article-single';
import VacancyOverview from 'pages/vacancy-overview';
import VacancySingle from 'pages/vacancy-single';
import Method from 'pages/method';
import Videos from 'pages/videos';
import Default from 'pages/default';
import NotFound from 'pages/not-found';
import Unsubscribe from 'pages/unsubscribe';

import withTracker from 'components/tracker';

import { withRouter } from 'react-router-dom';

// styles
import 'sanitize.css/sanitize.css';
import 'styles/global.scss';
import 'fonts/fonts.css';

if (process.env.NODE_ENV === 'production') {
  ReactGA.initialize('UA-70917039-1');
  hotjar.initialize(1151281, 6);
  ReactPixel.init('2235325906525233');
}

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headerA: true,
      cv: false,
    };
  }
  componentWillMount() {
    let url = this.props.location.pathname;
    let showNen = !url.includes("/radio");
    if (
      url.includes('/contact') ||
      url.includes('/vacatures') ||
      url.includes('/werkwijze')
    ) {
      this.setState({
        headerA: false,
        showNen: showNen
      });
      return;
    }
    this.setState({
      headerA: true,
      showNen: showNen
    });
    return;
  }
  leadInfo(id) {
    // Include the LeadInfo code here, without the <script></script> tags
    (function(l, e, a, d, i, n, f, o) {
      if (!l[i]) {
        l.GlobalLeadinfoNamespace = l.GlobalLeadinfoNamespace || [];
        l.GlobalLeadinfoNamespace.push(i);
        l[i] = function() {
          (l[i].q = l[i].q || []).push(arguments);
        };
        l[i].t = l[i].t || n;
        l[i].q = l[i].q || [];
        o = e.createElement(a);
        f = e.getElementsByTagName(a)[0];
        o.async = 1;
        o.src = d;
        f.parentNode.insertBefore(o, f);
      }
    })(
      window,
      document,
      'script',
      'https://cdn.leadinfo.net/ping.js',
      'leadinfo',
      id
    );
  }
  crispChat(id) {
    // Include the Crisp code here, without the <script></script> tags
    window.CRISP_RUNTIME_CONFIG = {
      locale: window.localStorage.getItem('i18nextLng'),
    };

    window.$crisp = [];
    window.CRISP_WEBSITE_ID = id;

    (function() {
      var d = document;
      var s = d.createElement('script');

      s.src = 'https://client.crisp.chat/l.js';
      s.async = 1;
      d.getElementsByTagName('head')[0].appendChild(s);
    })();
  }
  componentDidMount() {
    if (process.env.NODE_ENV === 'production') {
      this.crispChat('fb365650-c2aa-4f32-8466-3d9820a99916');
      this.leadInfo('LI-6062E9E8A67C3');
    } else {
      this.crispChat('4a038e44-cd0a-4e6d-be5f-9430e44fd12a');
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      let url = this.props.location.pathname;
      let showNen = !url.includes("/radio");
      if (
        url.includes('/contact') ||
        url.includes('/vacatures') ||
        url.includes('/werkwijze')
      ) {
        this.setState({
          headerA: false,
          showNen: showNen
        });
        return;
      }
      this.setState({
        headerA: true,
        showNen: showNen
      });
      return;
    }
  }

  render() {
    const url = this.props.location.pathname;
    return (
      <React.Fragment>
        <Menu variant={this.state.headerA} />
        <Overlay />
        <CookieNotice />
        <UpdateBrowserNotice />
        <Header variant={this.state.headerA} showNen={this.state.showNen} />
        <main
          className={
            url.includes('/werkwijze') || url.includes('/contact')
              ? 'blue'
              : null
          }
        >
          <Switch>
            <Route exact path='/' component={withTracker(Home)} />
            <Route exact path='/concreeto-go' component={withTracker(Go)} />
            <Route exact path='/contact' component={withTracker(Contact)} />
            <Route exact path="/banen" component={withTracker(RadioLanding)} />
            <Route
              exact
              path='/salesbox/:slug'
              component={withTracker(Salesbox)}
            />
            <Route
              exact
              path='/over-concreeto'
              component={withTracker(About)}
            />
            <Route exact path='/partners' component={withTracker(Partners)} />
            <Route
              exact
              path='/blog'
              component={withTracker(ArticleOverview)}
            />

            <Route
              exact
              path='/blog/:slug'
              component={withTracker(ArticleSingle)}
            />

            <Route
              exact
              path='/vacature/:id/:slug'
              component={withTracker(VacancySingle)}
            />
            <Route
              exact
              path='/vacatures/pagina/:page'
              component={withTracker(VacancyOverview)}
            />

            {/* <Route
              exact
              path="/vacatures"
              component={withTracker(VacancyOverview)}
            /> */}
            {/* <Route
              exact
              path="/vacatures/(category)?/:category?"
              component={withTracker(VacancyOverview)}
            /> */}
            {/* <Route
              exact
              path="/vacatures/:category/pagina/:page"
              component={withTracker(VacancyOverview)}
            /> */}

            <Route
              strict
              path='/vacatures/:category?/(pagina)?/:page?'
              component={VacancyOverview}
            />
            <Route exact path='/werkwijze' component={withTracker(Method)} />
            <Route exact path='/videos' component={withTracker(Videos)} />
            <Route exact path='/:page' component={withTracker(Default)} />
            <Route
              exact
              path='/email/uitschrijven'
              component={withTracker(Unsubscribe)}
            />
            <Route component={withTracker(NotFound)} />
          </Switch>
        </main>
        <Footer />
      </React.Fragment>
    );
  }
}

export default withRouter(App);
