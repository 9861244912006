import React, { Component } from 'react';
import { CSSTransition } from 'react-transition-group';
import { Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

// styles
import styles from './styles.scss';

// components
import PageHeroShare from 'components/page-hero-share';
import VideoModal from 'components/video-modal';

// icons
import ArrowRight from 'icons/arrow-right';
import { withNamespaces } from 'react-i18next';

// decorators
@inject('vacancyStore')
@observer
class PageHero extends Component {
  classNames(variants) {
    let classes = [];
    classes.push(styles['page-hero']);
    if (variants) {
      variants.forEach(function(variant) {
        classes.push(
          styles[`page-hero--${variant === 'blog' ? 'vacancy' : variant}`]
        );
      });
    }
    return classes.join(' ');
  }

  render() {
    const {
      t,
      title,
      subTitle,
      video,
      share,
      variants,
      image,
      loading,
      nen,
    } = this.props;

    const { currentOverviewPage } = this.props.vacancyStore;
    return (
      <section
        className={this.classNames(variants)}
        style={{
          backgroundImage: `url(${image})`,
        }}
      >
        {video && video.videoUrl !== false && (
          <video
            className={styles.background}
            autoPlay='autoplay'
            loop='true'
            muted='true'
          >
            <source src={video.videoUrl} type='video/mp4' />
          </video>
        )}
        <div className={styles.grid}>
          <div className={styles.horizontal}>
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
          </div>
          <div className={styles.vertical}>
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
          </div>
        </div>
        <div className={['wrapper', styles.wrapper].join(' ')}>
          <div className={styles.heading}>
            {subTitle && (
              <CSSTransition
                in={!loading}
                timeout={600}
                classNames={{
                  enterDone: styles['subtitle-enter-done'],
                }}
                unmountOnExit
              >
                {(state) => <span className={styles.subtitle}>{subTitle}</span>}
              </CSSTransition>
            )}
            <CSSTransition
              in={!loading}
              timeout={300}
              classNames={{
                enterDone: styles['title-enter-done'],
              }}
              unmountOnExit
            >
              {(state) => (
                <h1
                  className={styles.title}
                  dangerouslySetInnerHTML={{ __html: title }}
                />
              )}
            </CSSTransition>
            {video && video.videoId && (
              <CSSTransition
                in={!loading}
                timeout={800}
                classNames={{
                  enterDone: styles['slogan-enter-done'],
                }}
                unmountOnExit
              >
                {(state) => <VideoModal video={video} />}
              </CSSTransition>
            )}
          </div>

          {nen && (
            <CSSTransition
              in={!loading}
              timeout={800}
              classNames={{
                enterDone: styles["social-enter-done"],
              }}
              unmountOnExit
            >
              <div class="nen">
                <span>NEN 4400-1 & NBBU CAO audit</span>
                10/10
              </div>
            </CSSTransition>
          )}

          {share && (
            <CSSTransition
              in={!loading}
              timeout={800}
              classNames={{
                enterDone: styles['social-enter-done'],
              }}
              unmountOnExit
            >
              {(state) => (
                <PageHeroShare
                  type={share}
                  currentUrl={
                    (variants && variants.includes('vacancy')) ||
                    variants.includes('blog')
                      ? true
                      : false
                  }
                  text={
                    variants && variants.includes('vacancy')
                      ? 'Vacature delen'
                      : variants.includes('blog')
                      ? 'Blog delen'
                      : t('Follow us')
                  }
                />
              )}
            </CSSTransition>
          )}
          {variants && variants.includes('vacancy') && (
            <Link
              to={currentOverviewPage ? currentOverviewPage : '/vacatures'}
              className={styles.goback}
            >
              <ArrowRight className={styles.arrowleft} /> {t('Go back')}
            </Link>
          )}
          {variants && variants.includes('blog') && (
            <Link
              to={currentOverviewPage ? currentOverviewPage : '/blog'}
              className={styles.goback}
            >
              <ArrowRight className={styles.arrowleft} /> {t('All blogs')}
            </Link>
          )}
        </div>
      </section>
    );
  }
}

export default withNamespaces()(PageHero);
