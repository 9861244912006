import React, { Component } from 'react';

import PageHero from 'components/page-hero';
import Button from 'components/button';
import GridTest from 'components/grid-test';

//styles
import styles from './styles.scss';

//icons
import ArrowRight from 'icons/arrow-right';

class NotFound extends Component {
  render() {
    const { loading } = this.props;
    return (
      <React.Fragment>
        <PageHero
          loading={loading}
          title="<b>Oooops..<br/></b>Hier zijn geen toffe<br/> vacatures te vinden!"
          image={`${
            process.env.REACT_APP_API_SITE_URL
          }/resources/uploads/2019/01/not-found.jpg`}
        />
        <div className={[styles.wrapper, 'wrapper'].join(' ')}>
          <GridTest variants={['default']} />
          <p>
            Vanaf de homepage vind je de weg naar onze vacatures, onze werkwijze
            en contactgegevens.
          </p>
          <Button to="/" className={['large', 'blue']}>
            Terug naar home <ArrowRight />
          </Button>
        </div>
      </React.Fragment>
    );
  }
}

export default NotFound;
