import React, { Component } from "react";
import { compose, withProps } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";
// components
import Loading from "components/loading";
// styles
import styles from "./styles.scss";

const MyMapComponent = compose(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyBV5Vt_Vder5gXIXDwpRQ5lbTSjFeXf1qs&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: (
      <div style={{ height: `100%` }}>
        <Loading color="#0078e6" />
      </div>
    ),
    containerElement: <div style={{ height: `100%` }} />,
    mapElement: <div style={{ height: `100%` }} />
  }),
  withScriptjs,
  withGoogleMap
)(props => (
  <GoogleMap defaultZoom={20} defaultCenter={{ lat: 51.922952, lng: 4.49432 }}>
    <Marker position={{ lat: 51.922952, lng: 4.49432 }} />
  </GoogleMap>
));

class Map extends Component {
  render() {
    return (
      <div className={styles.maplayer}>
        <MyMapComponent />

        {/* <iframe
          title="unique"
          src="https://snazzymaps.com/embed/117118"
          width="100%"
          height="600px"
          className={styles.maplayer}
        /> */}
      </div>
    );
  }
}

export default Map;
