import React from 'react';

const Helmet = props => (
  <svg
    {...props}
    width="110px"
    height="104px"
    viewBox="0 0 110 104"
    version="1.1">
    <defs>
      <polygon
        id="path-1"
        points="0 0.717 109.839 0.717 109.839 52.769 0 52.769"></polygon>
    </defs>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g>
        <g>
          <path
            d="M86.103,58.428 C84.015,82.142 72.692,96.033 54.914,96.033 C37.134,96.033 25.811,82.142 23.724,58.428 L16.961,58.428 C19.271,86.004 33.366,102.783 54.914,102.783 C76.461,102.783 90.557,86.004 92.865,58.428 L86.103,58.428 Z"
            fill="#FFFFFF"
            fillRule="nonzero"></path>
          <g transform="translate(0.000000, 0.784000)">
            <g>
              <mask id="mask-2" fill="white">
                <use xlinkHref="#path-1"></use>
              </mask>
              <g id="a"></g>
              <path
                d="M18.032,44.35 C18.032,43.268 18.088,42.202 18.18,41.144 C19.467,26.29 29.592,13.934 43.257,9.367 L43.257,26.907 L50.007,26.907 L50.007,7.803 C51.6353063,7.58099171 53.2766316,7.46807334 54.92,7.465 C56.586,7.465 58.222,7.587 59.83,7.803 L59.83,26.905 L66.58,26.905 L66.58,9.366 C80.247,13.934 90.372,26.291 91.658,41.144 C91.751,42.204 91.808,43.271 91.808,44.354 L91.808,46.019 L18.032,46.019 L18.03,44.349 L18.032,44.35 Z M98.558,44.354 C98.558,43.273 98.503,42.204 98.427,41.144 C96.777,18.577 77.901,0.716 54.919,0.716 C31.938,0.716 13.062,18.576 11.412,41.144 C11.3293192,42.2120439 11.2859565,43.2827679 11.282,44.354 L11.283,46.019 L0,46.019 L0,52.769 L109.839,52.769 L109.839,46.019 L98.558,46.019 L98.558,44.354 Z"
                fill="#FFFFFF"
                fillRule="nonzero"
                mask="url(#mask-2)"></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export default Helmet;
