import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { Helmet } from "react-helmet";
import PageHero from "components/page-hero";
import PageDescription from "components/page-description";
import PageLoading from "components/page-loading";
import ImageCta from "components/image-cta";
import GridTest from "components/grid-test";
import VideoGrid from "components/video-grid";

// decorators
@inject("videosStore")
@observer
class Videos extends Component {
  componentDidMount() {
    this.props.videosStore.getPageDetails();
  }
  render() {
    const { response, isLoading } = this.props.videosStore;
    return (
      <React.Fragment>
        {response ? (
          <React.Fragment>
            <Helmet>
              <meta charSet="utf-8" />
              <title>{response.seo.siteTitle}</title>
              <meta name="description" content={response.seo.description} />
              <meta property="fb:pages" content="828024637343069" />
              <meta property="og:title" content={response.seo.title} />
              <meta
                property="og:description"
                content={response.seo.description}
              />
              <meta
                property="og:url"
                content={`${process.env.REACT_APP_SITE_URL}${window.location.pathname}`}
              />
              <meta property="og:image" content={response.seo.image} />
              <meta property="og:site_name" content={response.seo.siteName} />
            </Helmet>
            <PageHero
              title={response.pageHero.title}
              loading={isLoading}
              image={response.pageHero.image}
            />
            <PageDescription content={response.content} />
            {response.videos && <VideoGrid />}
            {/* <GridTest variants={['default']} /> */}
            <ImageCta
              title={response.cta.title}
              description={response.cta.description}
              image={response.cta.image}
              link={response.cta.button.link}
              buttontext={response.cta.button.text}
              popup={false}
              notch={["longer", "back", "top", "none"]}
            />
          </React.Fragment>
        ) : (
          <PageLoading color="#0078e6" />
        )}
        <GridTest variants={["default"]} />
      </React.Fragment>
    );
  }
}

export default Videos;
