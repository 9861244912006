import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { CSSTransition } from 'react-transition-group';

// styles
import styles from './styles.scss';

//icons
import Checkmark from 'icons/checkmark';
import Appointment from 'icons/appointment';
import WellDone from 'icons/well-done';
import Clock from 'icons/clock';
import Money from 'icons/money';
import Work from 'icons/work';

@inject('homeStore')
@observer
class Timeline extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mount: false
    };
  }
  componentDidMount() {
    this.setState({
      mount: true
    });
  }
  render() {
    const { data, title } = this.props;
    const { mount } = this.state;
    const total = data.length;
    return (
      <div className={styles.grid}>
        <div className="wrapper">
          {title && (
            <h2
              className={styles.heading}
              dangerouslySetInnerHTML={{ __html: title }}
            />
          )}
          {data.map((item, i) => {
            let last = total === i + 1;
            let isText =
              item.left.isIcon === false && item.right.isIcon === false;

            return (
              <CSSTransition
                in={mount}
                timeout={i + 300}
                classNames={{
                  enterDone: styles['grid-item-enter-done']
                }}
                unmountOnExit>
                {state => (
                  <div
                    className={[
                      styles['grid-item'],
                      last ? styles['grid-item--last'] : null,
                      isText ? styles['grid-item--text'] : null
                    ].join(' ')}
                    key={i}>
                    <div
                      className={[
                        styles.column,
                        styles['column--left'],
                        item.left.isIcon ? styles['column--icon'] : null
                      ].join(' ')}>
                      {item.left.isIcon ? (
                        <React.Fragment>
                          {item.left.icon === 'appointment' && <Appointment />}
                          {item.left.icon === 'well-done' && <WellDone />}
                          {item.left.icon === 'clock' && <Clock />}
                          {item.left.icon === 'money' && <Money />}
                          {item.left.icon === 'work' && <Work />}
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <h3 className={styles.title}>{item.left.title}</h3>
                          <p
                            className={styles.description}
                            dangerouslySetInnerHTML={{
                              __html: item.left.description
                            }}
                          />
                        </React.Fragment>
                      )}
                    </div>
                    <div
                      className={[
                        styles.column,
                        styles['column--right'],
                        item.right.isIcon ? styles['column--icon'] : null
                      ].join(' ')}>
                      {item.right.isIcon ? (
                        <Appointment />
                      ) : (
                        <React.Fragment>
                          <h3 className={styles.title}>{item.right.title}</h3>
                          <p
                            className={styles.description}
                            dangerouslySetInnerHTML={{
                              __html: item.right.description
                            }}
                          />
                        </React.Fragment>
                      )}
                    </div>
                    {last ? (
                      <span
                        className={[styles.index, styles['index--last']].join(
                          ' '
                        )}>
                        <Checkmark />
                      </span>
                    ) : (
                      <span className={styles.index}>{i + 1}</span>
                    )}
                  </div>
                )}
              </CSSTransition>
            );
          })}
        </div>
      </div>
    );
  }
}

export default Timeline;
