import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import Notch from "components/notch";
import styles from "./styles.scss";
import SimpleContent from "components/simple-content";
import { withNamespaces } from "react-i18next";

// decorators
@inject("goStore")
@observer
class GoDeelnemen extends Component {
  render() {
    const { details, t } = this.props;
    return (
      <div className={styles.textblock}>
        <Notch variants={["white", "right", "small"]} />
        <div className="wrapper">
          <SimpleContent
            details={details.default}
            buttons={[{ text: t("Apply"), link: "/" }]}
          />
        </div>
      </div>
    );
  }
}

export default withNamespaces()(GoDeelnemen);
