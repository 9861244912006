import React, { Component } from 'react';
import { CSSTransition } from 'react-transition-group';

// styles
import styles from './styles.scss';

// icons
import Linkedin from 'icons/linkedin';
import Facebook from 'icons/facebook';

class PageHeroShare extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mount: false
    };
  }
  componentDidMount() {
    this.setState({
      mount: true
    });
  }

  render() {
    const { text, currentUrl, type } = this.props;
    const { mount } = this.state;
    return (
      <CSSTransition
        in={mount}
        timeout={800}
        classNames={{
          enterDone: styles['social-enter-done']
        }}
        unmountOnExit>
        {state => (
          <div className={[styles.social, styles[`social--${type}`]].join(' ')}>
            
            <CSSTransition
              in={state === 'entered'}
              timeout={500}
              classNames={{
                enterDone: styles['social-title-enter-done']
              }}
              unmountOnExit>
              <span className={styles['social-title']}>{text}</span>
            </CSSTransition>
            <CSSTransition
              in={state === 'entered'}
              timeout={300}
              classNames={{
                enterDone: styles['social-item-enter-done']
              }}
              unmountOnExit>
              <a
                href={
                  currentUrl
                    ? `https://www.linkedin.com/shareArticle?mini=true&url=${
                        window.location.href
                      }&source=LinkedIn`
                    : 'https://www.linkedin.com/company/concreeto-talent-in-techniek/'
                }
                target="_blank"
                rel="noopener noreferrer"
                className={styles['social-item']}>
                <Linkedin />
              </a>
            </CSSTransition>
            <CSSTransition
              in={state === 'entered'}
              timeout={100}
              classNames={{
                enterDone: styles['social-item-enter-done']
              }}
              unmountOnExit>
              <a
                href={
                  currentUrl
                    ? `https://www.facebook.com/sharer/sharer.php?u=${
                        window.location.href
                      }`
                    : 'https://www.facebook.com/concreeto/'
                }
                target="_blank"
                rel="noopener noreferrer"
                className={styles['social-item']}>
                <Facebook />
              </a>
            </CSSTransition>
            
          </div>
        )}
      </CSSTransition>
    );
  }
}

export default PageHeroShare;
