import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import ReactGA from 'react-ga';
// styles
import styles from './styles.scss';

// components
import Notch from 'components/notch';
import Button from 'components/button';
import CvOverlay from 'components/cv-overlay';
import CvAlert from 'components/cv-alert';

// icons
import ArrowRight from 'icons/arrow-right';

// decorators
@inject('popupStore')
@observer
class ImageCta extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };
  }

  openPopup = () => {
    this.setState({
      modal: true,
    });
    ReactGA.event({
      category: 'Alert',
      action: 'Stel Alert in (eerste knop)',
    });
  };

  closePopup = () => {
    this.setState({
      modal: false,
    });
  };

  classNames(variants) {
    let classes = [];
    classes.push(styles['page-hero']);
    if (variants) {
      variants.forEach(function(variant) {
        classes.push(styles[`page-hero--${variant}`]);
      });
    }
    return classes.join(' ');
  }

  render() {
    const {
      title,
      description,
      image,
      link,
      buttontext,
      popup,
      vacancyPopup,
      notch,
    } = this.props;
    const { modal } = this.state;
    return (
      <React.Fragment>
        <section
          className={styles['image-cta']}
          style={{
            backgroundImage: `url(
      ${image}
    )`,
          }}
        >
          {/* <GridTest variants={['default']} /> */}
          <Notch variants={notch} />
          <div className={['wrapper', styles.wrapper].join(' ')}>
            <h1
              className={styles.title}
              dangerouslySetInnerHTML={{ __html: title }}
            />
            <p
              className={styles.description}
              dangerouslySetInnerHTML={{ __html: description }}
            />
            {popup ? (
              <Button
                onClick={this.openPopup}
                type='dummy'
                className={['large', 'white']}
              >
                {buttontext}
                <ArrowRight />
              </Button>
            ) : link ? (
              <Button to={link} className={['large', 'white']}>
                {buttontext}
                <ArrowRight />
              </Button>
            ) : null}
          </div>
        </section>
        {modal && (
          <CvOverlay close={this.closePopup}>
            <CvAlert details={vacancyPopup} />
          </CvOverlay>
        )}
      </React.Fragment>
    );
  }
}

export default ImageCta;
