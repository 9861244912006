import { observable, action } from 'mobx';
import request from 'superagent';
export class navigationStore {
  @observable menuOpen = false;
  @observable amount = 0;
  @observable amountLoading = false;

  @action switchMenu() {
    this.menuOpen = true;
  }
  @action getAmount() {
    this.amountLoading = true;
    this.amount = null;
    return request
      .get(
        `${process.env.REACT_APP_API_URL}/navigation/v1/amount${
          localStorage.getItem('i18nextLng') === 'nl'
            ? ''
            : `?lang=${localStorage.getItem('i18nextLng')}`
        }`
      )
      .then((response) => {
        this.amount = response.body;
      })
      .then(() => {
        this.amountLoading = false;
      })
      .catch((error) => {
        this.amount = 0;
      });
  }
}
export default new navigationStore();
