import { RouterStore, syncHistoryWithStore } from 'mobx-react-router';
import createHistory from 'history/createBrowserHistory';

// stores
import homeStore from 'stores/home';
import goStore from 'stores/go';
import vacancyStore from 'stores/vacancy';
import vacancySingleStore from 'stores/vacancy-single';
import articleStore from 'stores/article';
import articleSingleStore from 'stores/article-single';
import navigationStore from 'stores/navigation';
import popupStore from 'stores/popup';
import contactStore from 'stores/contact';
import aboutStore from 'stores/about';
import videosStore from 'stores/videos';
import methodStore from 'stores/method';
import partnerStore from 'stores/partner';
import defaultPageStore from 'stores/default-page';
import vacancyAlertStore from 'stores/vacancy-alert';
import vacancyUploadStore from 'stores/vacancy-upload';
import SalesboxStore from 'stores/salesbox';

const browserHistory = createHistory();
const routingStore = new RouterStore();

const stores = {
  routing: routingStore,
  homeStore,
  goStore,
  SalesboxStore,
  vacancyStore,
  vacancySingleStore,
  articleStore,
  articleSingleStore,
  navigationStore,
  popupStore,
  contactStore,
  aboutStore,
  videosStore,
  methodStore,
  partnerStore,
  defaultPageStore,
  vacancyAlertStore,
  vacancyUploadStore,
};

export const history = syncHistoryWithStore(browserHistory, routingStore);

export default stores;
