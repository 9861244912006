import React from 'react';

const CheckFilter = props => (
  <svg {...props} width="16px" height="11px" viewBox="0 0 16 11" version="1.1">
    <g
      id="03_Elementen-(Buttons)-Mobile"
      transform="translate(-1009.000000, -1492.000000)"
      fill="#0078E6">
      <g
        id="np_check_1822333_000000"
        transform="translate(1009.000000, 1492.000000)">
        <path
          d="M13.9683526,0 L12.9524811,0.924022611 C10.6704991,3.00392141 8.25509131,5.35302605 5.9757147,7.46697237 L2.9281002,5.18409298 L1.82259298,4.35519034 L0,6.37308179 L1.10550722,7.20198443 L5.16899321,10.2458236 L6.17740702,11 L7.0961851,10.1575088 C9.67506454,7.80679527 12.4640501,5.06255682 14.9841285,2.7653279 L16,1.84130529 L13.9683526,0 Z"
          id="Path"
        />
      </g>
    </g>
  </svg>
);

export default CheckFilter;
