import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
// styles
import styles from "./styles.scss";
// components
import VideoOverlay from "components/video-overlay";
// icons
import Play from "icons/play";

class InlineVideo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }

  openModal = () => {
    this.setState({
      open: true
    });
  };

  closeModal = () => {
    this.setState({
      open: false
    });
  };

  render() {
    const { videoId, type, preview, color, t } = this.props;
    const { open } = this.state;
    return (
      <React.Fragment>
        <div
          onClick={this.openModal}
          style={{ color: color }}
          className={[styles.video, type ? styles[`video--${type}`] : ""].join(
            " "
          )}
        >
          <figure
            className={styles.image}
            style={{
              backgroundImage: `url(${
                preview
                  ? preview
                  : `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`
              })`
            }}
          />
          <span className={styles.play}>
            <span className={styles.icon}>
              <Play />
            </span>
            <span className={styles.caption}>{t('Watch video')}</span>
          </span>
        </div>
        {open && (
          <VideoOverlay close={this.closeModal} key="modal" video={videoId} />
        )}
      </React.Fragment>
    );
  }
}

export default withNamespaces()(InlineVideo);
