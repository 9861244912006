import React from 'react';

const FlagHungarian = props => (
  <svg
    {...props}
    width="30"
    height="23"
    viewBox="0 0 30 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <rect x="1" y="1" width="28" height="7" fill="#FD5858"/>
    <rect x="1" y="8" width="28" height="7" fill="white"/>
    <rect x="1" y="15" width="28" height="7" fill="#257236"/>
    <rect x="0.5" y="0.5" width="29" height="22" stroke="white"/>
  </svg>
);

export default FlagHungarian;
