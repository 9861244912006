import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

// styles
import styles from './styles.scss';

// components
import Item from 'components/featured-filter-categories-item';

// decorators
@inject('vacancyStore')
@inject('homeStore')
@observer
class FeaturedFilterCategories extends Component {
  render() {
    const { response } = this.props.homeStore;

    return (
      <div className={styles.categories}>
        {response.filterCategories.map((category, i) => {
          return <Item category={category} key={i} />;
        })}
      </div>
    );
  }
}

export default FeaturedFilterCategories;
