import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Helmet } from 'react-helmet';
import { withNamespaces } from 'react-i18next';

// components
import PageHero from 'components/page-hero';
import GridTest from 'components/grid-test';
import VacancyDetail from 'components/vacancy-detail';
import VacancyTop from 'components/vacancy-top';
import PageLoading from 'components/page-loading';
import ImageCta from 'components/image-cta';

// decorators
@inject('vacancySingleStore')
@inject('vacancyAlertStore')
@observer
class VacancySingle extends Component {
  componentDidMount() {
    this.props.vacancySingleStore.details(this.props.match.params.id);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.match.params.id !== nextProps.match.params.id) {
      this.props.vacancySingleStore.details(nextProps.match.params.id);
    }
  }
  render() {
    const { response, isLoading } = this.props.vacancySingleStore;
    const { t } = this.props;

    return (
      <React.Fragment>
        {response ? (
          <React.Fragment>
            {response.seo && (
              <Helmet>
                <meta charSet='utf-8' />
                <title>{response.seo.siteTitle}</title>
                <meta name='description' content={response.seo.description} />
                <meta property='fb:pages' content='828024637343069' />
                <meta property='og:title' content={response.seo.title} />
                <meta
                  property='og:description'
                  content={response.seo.description}
                />
                <meta
                  property='og:url'
                  content={`${process.env.REACT_APP_SITE_URL}${window.location.pathname}`}
                />
                <meta property='og:image' content={response.seo.image} />
                <meta property='og:site_name' content={response.seo.siteName} />
              </Helmet>
            )}
            <PageHero
              loading={isLoading}
              title={`<b>${response.title}</b>`}
              image={response.image}
              share
              variants={['vacancy']}
            />
            <VacancyTop detail={response} />
            <div className='wrapper'>
              <VacancyDetail detail={response} />
            </div>
            <ImageCta
              title={t('Vacancy footer title')}
              description={t('Vacancy footer paragraph')}
              image={`${process.env.REACT_APP_API_SITE_URL}/resources/uploads/2019/01/DSC03107-kopie-2-1600x1200.jpg`}
              link='/'
              buttontext={t('Vacancy footer alert')}
              popup={true}
              vacancyPopup={{
                id: response.id,
                title: `${response.employment} ${response.title} in regio ${response.area}`,
                categories: response.categories,
              }}
              notch={['longer', 'back', 'top']}
            />
          </React.Fragment>
        ) : (
          <PageLoading color='#0078e6' />
        )}
        <GridTest variants={['default']} />
      </React.Fragment>
    );
  }
}

export default withNamespaces()(VacancySingle);
