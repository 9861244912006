import { observable, action } from 'mobx';
import request from 'superagent';
export class vacancyAlertStore {
  @observable isLoading = false;
  @observable response = null;
  @observable step = 1;
  @observable error = null;
  @observable title = null;
  @observable currentCategories = null;

  @action signup(values) {
    this.isLoading = true;
    return request
      .post(`${process.env.REACT_APP_API_URL}/vacancies/v1/alert/signup`)
      .send(values)
      .then(response => {
        this.response = response.body;
        this.error = null;
        this.step = this.step + 1;
      })
      .then(() => {
        this.isLoading = false;
      })
      .catch(error => {
        this.error = error.response.body.message;
        this.response = null;
      });
  }
  @action setStep(step) {
    this.step = step;
  }

  @action setDetails(title, categories) {
    this.title = title;
    this.setCurrentCategories(categories);
  }

  @action setCurrentCategories(categories) {
    this.currentCategories = categories;
  }

  @action setError(error) {
    this.error = error;
  }
}

export default new vacancyAlertStore();
