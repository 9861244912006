import React from "react";

const Play = props => (
  <svg {...props} width="18px" height="20px" viewBox="0 0 18 20">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <polygon fill="#0078E6" points="0 0 17.9627075 9.56542969 0 20"></polygon>
    </g>
  </svg>
);
export default Play;
