import { observable, action } from "mobx";
import request from "superagent";
export class videosStore {
  @observable isLoading = false;
  @observable response = null;

  @action getPageDetails() {
    this.isLoading = true;
    return request
      .get(`${process.env.REACT_APP_API_URL}/pages/v1/videos`)
      .then(response => {
        this.response = response.body;
      })
      .then(() => {
        this.isLoading = false;
      })
      .catch(error => {
        this.response = error;
      });
  }
}

export default new videosStore();
