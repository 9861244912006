import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Helmet } from 'react-helmet';

// styles
import styles from './styles.scss';

import ContactBox from 'components/contact-box';
import Map from 'components/map';
import PageLoading from 'components/page-loading';
import GridTest from 'components/grid-test';

// decorators
@inject('contactStore')
@observer
class Contact extends Component {
  componentDidMount() {
    this.props.contactStore.getPageDetails();
  }
  render() {
    const { response, isLoading } = this.props.contactStore;
    return (
      <React.Fragment>
        {response ? (
          <React.Fragment>
            <Helmet>
              <meta charSet="utf-8" />
              <title>{response.seo.siteTitle}</title>
              <meta name="description" content={response.seo.description} />
              <meta property="fb:pages" content="828024637343069" />
              <meta property="og:title" content={response.seo.title} />
              <meta
                property="og:description"
                content={response.seo.description}
              />
              <meta
                property="og:url"
                content={`${process.env.REACT_APP_SITE_URL}${
                  window.location.pathname
                }`}
              />
              <meta property="og:image" content={response.seo.image} />
              <meta property="og:site_name" content={response.seo.siteName} />
            </Helmet>
            <div className={styles.contact}>
              <ContactBox response={response.content} isLoading={isLoading} />
              <Map />
              <GridTest variants={['default']} />
            </div>
          </React.Fragment>
        ) : (
          <PageLoading color="#0078e6" />
        )}
      </React.Fragment>
    );
  }
}

export default Contact;
