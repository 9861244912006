import { observable, action } from 'mobx';
import request from 'superagent';

export class SalesboxStore {
  @observable isLoading = false;
  @observable response = null;

  @action details(slug) {
    this.isLoading = true;
    this.response = null;
    return request
      .get(`${process.env.REACT_APP_API_URL}/salesbox/v2/detail/${slug}`)
      .then((response) => {
        this.response = response.body;
      })
      .then(() => {
        this.isLoading = false;
      })
      .catch((error) => {
        this.response = null;
      });
  }
}
export default new SalesboxStore();
