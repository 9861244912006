import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { Helmet } from "react-helmet";

// styles
import styles from "./styles.scss";

// components
import Pagination from "components/pagination";
import ArticleGrid from "components/article-grid";
import ArticleGridItem from "components/article-grid-item";
import Loading from "components/loading";

// decorators
@inject("articleStore")
@observer
class ArticleOverview extends Component {
  componentDidMount() {
    this.props.articleStore.getArticleItems(
      this.props.match.params.page,
      false
    );
    if (this.props.match.params.page > 0) {
      this.props.articleStore.setCurrentOverviewPage(
        `/blog/pagina/${this.props.match.params.page}`
      );
    } else {
      this.props.articleStore.setCurrentOverviewPage("/blog");
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const currentParams = this.props.match.params;
    const nextParams = nextProps.match.params;
    if (currentParams.page !== nextParams.page) {
      this.props.articleStore.getArticleItems(nextParams.page, false);
    }
  }

  render() {
    const {
      overviewResponse,
      overviewItemsResponse,
      overviewItemsLoading,
    } = this.props.articleStore;

    return (
      <React.Fragment>
        <div className={styles.overview}>
          <div className={[styles.wrapper, "wrapper"].join(" ")}>
            {overviewItemsLoading ? (
              <Loading />
            ) : (
              <React.Fragment>
                {overviewItemsResponse && overviewItemsResponse.items ? (
                  <React.Fragment>
                    <h1 className={styles.title}>
                      Concreeto
                      <br />
                      blogs.
                    </h1>
                    <ArticleGrid>
                      {overviewItemsResponse.items.map((item, i) => {
                        return (
                          <ArticleGridItem
                            delay={i * 100}
                            item={item}
                            key={i}
                          />
                        );
                      })}
                    </ArticleGrid>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <h1
                      className={[
                        styles.title,
                        styles["title--no-result"],
                      ].join(" ")}
                    >
                      geen blogs
                      <br />
                      gevonden.
                    </h1>
                  </React.Fragment>
                )}
                {overviewItemsResponse && overviewItemsResponse.total > 9 && (
                  <Pagination
                    total={overviewItemsResponse.total}
                    perPage={9}
                    currentPage={
                      this.props.match.params.page === undefined ||
                      window.location.pathname === "/blog/" ||
                      window.location.pathname === "/blog"
                        ? 1
                        : parseInt(this.props.match.params.page)
                    }
                    base="/blog/pagina"
                  />
                )}
              </React.Fragment>
            )}
          </div>
        </div>
        {overviewResponse && (
          <React.Fragment>
            <Helmet>
              <meta charSet="utf-8" />
              <title>{overviewResponse.seo.siteTitle}</title>
              <meta
                name="description"
                content={overviewResponse.seo.description}
              />
              <meta property="fb:pages" content="828024637343069" />
              <meta property="og:title" content={overviewResponse.seo.title} />
              <meta
                property="og:description"
                content={overviewResponse.seo.description}
              />
              <meta
                property="og:url"
                content={`${process.env.REACT_APP_SITE_URL}${window.location.pathname}`}
              />
              <meta property="og:image" content={overviewResponse.seo.image} />
              <meta
                property="og:site_name"
                content={overviewResponse.seo.siteName}
              />
            </Helmet>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

export default ArticleOverview;
