import React from 'react';

const School = props => (
<svg {...props} width="33px" height="33px" viewBox="0 0 33 33">
    <g id="Ontwerpen" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="02---Vacature-page" transform="translate(-1132.000000, -563.000000)">
            <g id="Group-5" transform="translate(537.000000, 562.000000)">
                <g id="Group-2-Copy-3" transform="translate(566.000000, 1.000000)">
                    <g id="Group-17" transform="translate(29.000000, 0.000000)">
                        <rect id="Rectangle-7" fill="#0078E6" x="3.62376795e-13" y="2.13162821e-14" width="32.2" height="32.2"></rect>
                        <g id="np_graduate_1679752_000000" transform="translate(6.000000, 10.000000)" fill="#FFFFFF" fillRule="nonzero">
                            <polygon  points="10.1857 0.0702352941 0.10535 3.88235294 0.664545 4.09466824 10.219895 7.69431529 19.075245 4.34137412 20.300245 3.88226824"></polygon>
                            <path d="M4.13035,6.52941176 L4.13035,9.70588235 C4.13035,10.9411765 6.82507,11.9296941 10.1857,11.9296941 C12.4607,11.9296941 14.4214,11.4705882 15.4714,10.8005647 C15.96084,10.4834682 16.24112,10.0946824 16.24112,9.70588235 L16.24112,6.49355294 L10.18577,8.78767059 L4.13035,6.52941176 Z" ></path>
                            <polygon  points="20.2643 4.44776471 19.84458 4.58839059 19.705126 5.71752 19.249846 8.96429647 20.894566 8.96429647"></polygon>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
);
export default School;




