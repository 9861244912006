import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { Helmet } from "react-helmet";

import PageHero from "components/page-hero";
import DnaCta from "components/dna-cta";
import PartnersTeamwork from "components/partners-teamwork";
import MarqueeBlock from "components/marquee-block";
import PartnersSectors from "components/partners-sectors";
import PartnerStats from "components/partners-stats";
import PageLoading from "components/page-loading";
import GridTest from "components/grid-test";

// decorators
@inject("partnerStore")
@observer
class Partners extends Component {
  componentDidMount() {
    this.props.partnerStore.getPageDetails();
  }

  render() {
    const { response, isLoading } = this.props.partnerStore;
    return (
      <React.Fragment>
        {response ? (
          <React.Fragment>
            <Helmet>
              <meta charSet="utf-8" />
              <title>{response.seo.siteTitle}</title>
              <meta name="description" content={response.seo.description} />
              <meta property="fb:pages" content="828024637343069" />
              <meta property="og:title" content={response.seo.title} />
              <meta
                property="og:description"
                content={response.seo.description}
              />
              <meta
                property="og:url"
                content={`${process.env.REACT_APP_SITE_URL}${
                  window.location.pathname
                }`}
              />
              <meta property="og:image" content={response.seo.image} />
              <meta property="og:site_name" content={response.seo.siteName} />
            </Helmet>
            <PageHero
              title={response.pageHero.title}
              loading={isLoading}
              image={response.pageHero.image}
            />
            <PartnersTeamwork content={response.content} />
            <PartnerStats statistics={response.statistics} />
            <PartnersSectors vacancies={response.vacancies} />
            <DnaCta response={response.about} />
            <MarqueeBlock
              notch={true}
              pretext={response.partners.title}
              partners={response.partners.items}
            />
            <GridTest variants={["default"]} />
          </React.Fragment>
        ) : (
          <PageLoading color="#0078e6" />
        )}
      </React.Fragment>
    );
  }
}

export default Partners;
