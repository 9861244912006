import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import ReactGA from 'react-ga';

// styles
import styles from './styles.scss';

// components
import Notch from 'components/notch';
import Button from 'components/button';
import CvOverlay from 'components/cv-overlay';
import CvUpload from 'components/cv-upload';

// icons
import Locator from 'icons/locator';
import Share from 'icons/share';
import Bag from 'icons/bag';
import School from 'icons/school';
import ArrowRight from 'icons/arrow-right';
// decorators
@inject('vacancyStore')
@observer
class Top extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: null,
      modal: false,
    };
  }
  getWidth = () => {
    this.setState({
      width: window.outerWidth,
    });
  };

  openPopup = () => {
    this.setState({
      modal: true,
    });
    ReactGA.event({
      category: 'Sollicitatie',
      action: 'Upload CV (eerste knop)',
    });
  };

  closePopup = () => {
    this.setState({
      modal: false,
    });
  };

  componentDidMount() {
    this.getWidth();
    window.addEventListener('resize', this.getWidth);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.getWidth);
  }

  render() {
    const { detail } = this.props;
    const { modal } = this.state;
    return (
      <section className={styles.top}>
        <Notch variants={['vacancy']} />
        <div className={['wrapper', styles.wrapper].join(' ')}>
          <div className={styles.dummy} />
          <div className={styles.amount}>
            {detail.area && (
              <div className={styles['amount-item']}>
                <Locator />
                <b className={styles.iconname}>{detail.area}</b>
              </div>
            )}
            {detail.sector && (
              <div className={styles['amount-item']}>
                <Share />
                <b className={styles.iconname}>
                  {detail.sector && detail.sector.replace(/\./g, '')}
                </b>
              </div>
            )}
            {detail.employment && (
              <div className={styles['amount-item']}>
                <Bag />
                <b className={styles.iconname}>{detail.employment}</b>
              </div>
            )}
            {detail.education && (
              <div className={styles['amount-item']}>
                <School />
                <b className={styles.iconname}>{detail.education}</b>
              </div>
            )}
            {this.props.variant !== 'salesbox' && this.state.width <= 767 ? (
              <React.Fragment>
                <Button
                  type='dummy'
                  onClick={this.openPopup}
                  className={['large', 'green']}
                >
                  Upload je CV
                  <ArrowRight fill='white' />
                </Button>
                {modal && (
                  <CvOverlay close={this.closePopup}>
                    <CvUpload
                      details={{
                        id: detail.id,
                        title: detail.title,
                        categories: detail.categories,
                      }}
                    />
                  </CvOverlay>
                )}
              </React.Fragment>
            ) : null}
          </div>
        </div>
      </section>
    );
  }
}

export default Top;
