import React from "react";

const Ship = props => (
  <svg {...props} viewBox="0 0 130.1 88.67" width="130" height="83">
    <title>Concreeto_bouw_illustratie_01</title>
    <polygon
      points="109.08 44.13 104.58 44.14 104.58 48.64 109.08 48.64 109.08 44.13"
      fill="#0078e6"
    />
    <polygon
      points="104.58 57.98 109.08 57.98 109.08 53.46 104.58 53.48 104.58 57.98"
      fill="#0078e6"
    />
    <path
      d="M104.84,76.56a2.36,2.36,0,0,1,0-4.71h4.24v-9h-4.5v4.54a6.85,6.85,0,1,0,7.12,6.85h-4.5A2.36,2.36,0,0,1,104.84,76.56Z"
      fill="#0078e6"
    />
    <path
      d="M130,35.67l-.67-.88h0l-3-3.91h0l-5.44-7.08L120,22.7h0l-.42-.55L52.58,0h-8.4L8,21.81H0V44.48H22.1V39.29H38.57V88.67h4.5V78.79H54v9.88h4.5V39.29h71.6Zm-76-.88H43.07V26.31H54Zm-15.4,0L29,26.31h9.66v8.48Zm19.9-13h-.38l-3-16.24,49.2,16.24Zm53.89,4.5L103.54,34l-8.8-7.73ZM46.68,4.5h3.66L53.5,21.81h-10ZM82.8,26.31,74,34l-8.8-7.73Zm6,.75,8.8,7.73H80Zm29.22.28,5.71,7.45H109.51ZM41.7,6.75,39,21.81H16.71ZM17.6,40H4.5V26.31H17.6Zm4.5-13.66,9.63,8.47H22.1Zm29.48,13L43.07,50.16V39.29ZM43.07,59H51.6L43.07,70Zm2.35,15.25,8.53-11v11ZM45.36,54.5l8.59-11v11ZM58.45,26.37,68,34.79H58.45Z"
      fill="#0078e6"
    />
  </svg>
);
export default Ship;
