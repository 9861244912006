import React, { Component } from 'react';
import styles from './styles.scss';
// import { propTypes } from "mobx-react";
import { inject, observer } from 'mobx-react';

// decorators
@inject('navigationStore')
@observer
class Overlay extends Component {
  closeNavigation = () => {
    this.props.navigationStore.menuOpen = false;
    if (window.removeEventListener) {
      window.removeEventListener('DOMMouseScroll', this.wheel, false);
    }
    window.onmousewheel = document.onmousewheel = document.onkeydown = null;
  };
  render() {
    return (
      <div
        onClick={this.closeNavigation}
        className={[
          this.props.navigationStore.menuOpen ? styles.blur : styles.passive,
          styles.transition
        ].join(' ')}>
        {this.props.children}
      </div>
    );
  }
}

export default Overlay;
