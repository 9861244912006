import React, { Component } from 'react';
import Notch from 'components/notch';
import styles from './styles.scss';
import Button from 'components/button';
import GridTest from 'components/grid-test';

//Icons
import ArrowRight from 'icons/arrow-right';

class PartnersTeamwork extends Component {
  classNames(variants) {
    let classes = [];
    classes.push(styles['page-hero']);
    if (variants) {
      variants.forEach(function(variant) {
        classes.push(styles[`page-hero--${variant}`]);
      });
    }
    return classes.join(' ');
  }

  render() {
    const { content } = this.props;
    return (
      content && (
        <section className={styles.background}>
          <Notch variants={['right', 'half']} />
          <div className={['wrapper', styles.block].join(' ')}>
            <div className={styles.content}>
              <h3>{content.subtitle}</h3>
              <h1>{content.title}</h1>
              <p dangerouslySetInnerHTML={{ __html: content.content }} />
              <Button to={content.button.link} className={['large', 'blue']}>
                {content.button.text}
                <ArrowRight fill="white" />
              </Button>
            </div>
            <div className={styles.image}>
              <img src={content.image} alt={content.title} />
            </div>
          </div>

          <GridTest variants={['default']} />
        </section>
      )
    );
  }
}

export default PartnersTeamwork;
