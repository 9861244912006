import React from 'react';

const Logo = props => (
  <svg {...props} width="144px" height="22px" viewBox="0 0 144 22">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g fill={props.fill}>
        <g>
          <path d="M15.3396798,7.36160526 L12.157599,10.0938509 C11.1417137,8.98034211 10.0920344,8.40602632 8.65316089,8.40602632 C6.36812308,8.40602632 4.53967979,10.2501667 4.53967979,13.051886 C4.53967979,15.8703947 6.35103833,17.6977456 8.63663937,17.6977456 C10.0582404,17.6977456 11.2603682,17.0363947 12.2077267,15.9921667 L15.3567645,18.7591491 C13.7992365,20.7947281 11.5312834,21.9259912 8.77200313,21.9259912 C3.54069153,21.9259912 0.0370044329,18.1499035 0.0370044329,13.051886 C0.0370044329,7.95367544 3.54069153,4.17758772 8.77200313,4.17758772 C11.5312834,4.17758772 13.8330305,5.32583333 15.3396798,7.36160526" />
          <path d="M19.6988621,13.0518474 C19.6988621,15.783514 21.5610993,17.697707 24.0496522,17.697707 C26.5552897,17.697707 28.416776,15.783514 28.416776,13.0518474 C28.416776,10.3199877 26.5552897,8.40598772 24.0496522,8.40598772 C21.5610993,8.40598772 19.6988621,10.3199877 19.6988621,13.0518474 M32.9365361,13.0518474 C32.9365361,18.1323035 29.161746,21.9259526 24.0496522,21.9259526 C18.9542675,21.9259526 15.1963744,18.1323035 15.1963744,13.0518474 C15.1963744,7.9706193 18.9542675,4.17774211 24.0496522,4.17774211 C29.161746,4.17774211 32.9365361,7.9706193 32.9365361,13.0518474" />
          <path d="M49.8092996,10.7547193 L49.8092996,21.4907193 L45.3911093,21.4907193 L45.3911093,12.2165614 C45.3911093,9.76317544 44.223151,8.44066667 42.1919437,8.44066667 C40.2959124,8.44066667 38.7216751,9.69331579 38.7216751,12.2512982 L38.7216751,21.4907193 L34.3036725,21.4907193 L34.3036725,4.62984211 L38.6030206,4.62984211 L38.6030206,6.61389474 C39.7707911,4.8212807 41.5485434,4.17768421 43.4278654,4.17768421 C47.1517768,4.17768421 49.8092996,6.84007018 49.8092996,10.7547193" />
          <path d="M66.359781,7.36160526 L63.1775124,10.0938509 C62.1616271,8.98034211 61.1119478,8.40602632 59.6732621,8.40602632 C57.3880365,8.40602632 55.559781,10.2501667 55.559781,13.051886 C55.559781,15.8703947 57.3709518,17.6977456 59.6565528,17.6977456 C61.0781538,17.6977456 62.2799061,17.0363947 63.2283911,15.9921667 L66.3768657,18.7591491 C64.8191499,20.7947281 62.5508214,21.9259912 59.7921043,21.9259912 C54.5613559,21.9259912 51.0569179,18.1499035 51.0569179,13.051886 C51.0569179,7.95367544 54.5613559,4.17758772 59.7921043,4.17758772 C62.5508214,4.17758772 64.8535072,5.32583333 66.359781,7.36160526" />
          <path d="M78.6783145,4.543 L78.018205,9.0497193 C77.2903197,8.71894737 76.2408282,8.51052632 75.3435974,8.51052632 C73.3122023,8.51052632 71.8906013,9.79791228 71.8906013,12.3035965 L71.8906013,21.4907193 L67.472411,21.4907193 L67.472411,4.62984211 L71.7552375,4.62984211 L71.7552375,6.47475439 C72.7876443,4.87357895 74.3447969,4.17768421 76.3760042,4.17768421 C77.3241137,4.17768421 78.0692714,4.31682456 78.6783145,4.543" />
          <path d="M83.0653768,11.4683298 L90.3273325,11.4683298 C89.9210535,9.29341754 88.6686102,8.09287368 86.7554941,8.09287368 C84.6568866,8.09287368 83.4543833,9.36269825 83.0653768,11.4683298 M94.7626076,13.0341895 C94.7626076,13.5739614 94.7288136,14.1654526 94.6781226,14.5653123 L83.048292,14.5653123 C83.4881773,17.0017158 85.1814446,18.0629263 87.2633429,18.0629263 C88.6851317,18.0629263 90.2090535,17.4886105 91.410618,16.4449614 L94.0009283,19.3680667 C92.1214185,21.1255579 89.7856897,21.9260491 87.0093246,21.9260491 C81.9989883,21.9260491 78.4947379,18.3936982 78.4947379,13.1040491 C78.4947379,7.8144 81.8803338,4.17764561 86.7892881,4.17764561 C91.5123755,4.17764561 94.7626076,7.77985614 94.7626076,13.0341895" />
          <path d="M100.191746,11.4683298 L107.453701,11.4683298 C107.04761,9.29341754 105.794979,8.09287368 103.881863,8.09287368 C101.783255,8.09287368 100.58094,9.36269825 100.191746,11.4683298 M111.888976,13.0341895 C111.888976,13.5739614 111.855182,14.1654526 111.804491,14.5653123 L100.174661,14.5653123 C100.614546,17.0017158 102.307813,18.0629263 104.389712,18.0629263 C105.811688,18.0629263 107.335422,17.4886105 108.536987,16.4449614 L111.127297,19.3680667 C109.247975,21.1255579 106.912058,21.9260491 104.135693,21.9260491 C99.1255447,21.9260491 95.6211066,18.3936982 95.6211066,13.1040491 C95.6211066,7.8144 99.0067025,4.17764561 103.915657,4.17764561 C108.638744,4.17764561 111.888976,7.77985614 111.888976,13.0341895" />
          <path d="M119.583326,0.182059649 L119.583326,4.6299193 L125.524687,4.6299193 L125.524687,8.66672632 L119.583326,8.66672632 L119.583326,14.8267263 C119.583326,16.9147965 120.683321,17.6977263 122.037522,17.6977263 C123.104474,17.6977263 124.221553,17.1579544 125.118596,16.6012 L126.743712,20.1511123 C125.305026,21.1777789 123.629032,21.9259719 121.309637,21.9259719 C117.230325,21.9259719 115.165324,19.5766035 115.165324,15.1920421 L115.165324,8.66672632 L111.999577,8.66672632 L111.999577,4.6299193 L115.165324,4.6299193 L115.165324,0.182059649" />
          <path d="M130.737243,13.0518474 C130.737243,15.783514 132.599481,17.697707 135.088033,17.697707 C137.593108,17.697707 139.455157,15.783514 139.455157,13.0518474 C139.455157,10.3199877 137.593108,8.40598772 135.088033,8.40598772 C132.599481,8.40598772 130.737243,10.3199877 130.737243,13.0518474 M143.974917,13.0518474 C143.974917,18.1323035 140.200127,21.9259526 135.088033,21.9259526 C129.992649,21.9259526 126.234756,18.1323035 126.234756,13.0518474 C126.234756,7.9706193 129.992649,4.17774211 135.088033,4.17774211 C140.200127,4.17774211 143.974917,7.9706193 143.974917,13.0518474" />
        </g>
      </g>
    </g>
  </svg>
);
export default Logo;
