import React, { Component } from 'react';
import styles from './styles.scss';
import { Link } from 'react-router-dom';
class Button extends Component {
  classNames(classes) {
    let result = [];
    result.push('button');
    result.push(styles['button']);
    if (classes) {
      classes.forEach(function(variant) {
        result.push(styles[`button--${variant}`]);
      });
    }
    return result.join(' ');
  }

  render() {
    const { type, className, children, to } = this.props;
    const contentClasses = ['content', styles.content].join(' ');
    return type === 'dummy' ? (
      <span {...this.props} className={this.classNames(className)}>
        <span className={contentClasses}>{children}</span>
      </span>
    ) : type === 'submit' ? (
      <button {...this.props} className={this.classNames(className)}>
        <span className={contentClasses}>{children}</span>
      </button>
    ) : type === 'external' ? (
      <a
        {...this.props}
        href={to}
        rel='noopener noreferrer'
        target='_blank'
        className={this.classNames(className)}
      >
        <span className={contentClasses}>{children}</span>
      </a>
    ) : (
      <Link {...this.props} className={this.classNames(className)}>
        <span className={contentClasses}>{children}</span>
      </Link>
    );
  }
}

export default Button;
