import React, { Component } from "react";
import ReactDOM from "react-dom";

// styles
import styles from "./styles.scss";

// components
import RelatedVacancies from "components/related-vacancies";
import VacancyContent from "components/vacancy-content";
import VacancyAuthor from "components/vacancy-author";

class VacancyDetail extends Component {
  componentDidMount() {
    setTimeout(() => {
      this.moveElements();
    }, 100);
    window.addEventListener("resize", this.moveElements);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.moveElements, false);
  }

  moveElements = () => {
    if (window.innerWidth < 900) {
      if (this.refs.wrapper) {
        this.refs.wrapper.prepend(ReactDOM.findDOMNode(this.refs.related));
      }
    } else {
      if (this.refs.related) {
        this.refs.content.append(ReactDOM.findDOMNode(this.refs.related));
      }
    }
  };

  render() {
    const { detail } = this.props;
    return (
      <div className={styles.wrapper} ref="wrapper">
        <aside className={styles.sidebar}>
          <VacancyAuthor
            detail={{
              id: detail.id,
              author: detail.author,
              categories: detail.categories,
              title: detail.title,
            }}
          />
        </aside>
        <div className={styles.content} ref="content">
          <VacancyContent
            content={{ intro: detail.intro, content: detail.content }}
          />
          {detail.related && detail.related.length > 0 && (
            <RelatedVacancies ref="related" items={detail.related} />
          )}
        </div>
      </div>
    );
  }
}

export default VacancyDetail;
