import React from 'react';

const Question = props => (
  <svg
    {...props}
    width="110px"
    height="104px"
    viewBox="0 0 110 104"
    version="1.1">
    <defs>
      <polygon
        id="path-1"
        points="0 0.132 101.868 0.132 101.868 102 0 102"></polygon>
    </defs>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g>
        <g>
          <g transform="translate(0.000000, 0.868000)">
            <g>
              <mask id="mask-2" fill="white">
                <use xlinkHref="#path-1"></use>
              </mask>
              <g id="a"></g>
              <path
                d="M50.934,95.25 C26.571,95.25 6.75,75.429 6.75,51.066 C6.75,26.703 26.571,6.882 50.934,6.882 C75.297,6.882 95.118,26.703 95.118,51.066 C95.118,75.429 75.298,95.25 50.934,95.25 M50.934,0.132 C22.848,0.132 0,22.98 0,51.066 C0,79.152 22.848,102 50.934,102 C79.02,102 101.868,79.152 101.868,51.066 C101.868,22.98 79.02,0.132 50.934,0.132"
                fill="#FFFFFF"
                fillRule="nonzero"
                mask="url(#mask-2)"></path>
            </g>
          </g>
          <path
            d="M48.385,66.133 C44.992,66.133 41.932,69.119 41.932,72.548 C41.932,75.978 44.992,79.038 48.385,79.038 C51.814,79.038 54.8,75.978 54.8,72.548 C54.8,69.119 51.814,66.133 48.385,66.133 M48.717,25.315 C46.54,25.315 43.665,25.535 41.784,25.831 L42.448,33.758 C44.034,33.611 46.135,33.427 48.348,33.427 C56.164,33.427 60.442,36.118 60.442,40.95 C60.442,46.295 56.091,49.208 48.163,49.208 L44.881,49.208 L44.881,61.083 L51.888,61.083 L52.183,56.362 C63.208,54.923 69.07,49.504 69.07,40.617 C69.07,31.326 61.659,25.315 48.717,25.315"
            fill="#FFFFFF"
            fillRule="nonzero"></path>
        </g>
      </g>
    </g>
  </svg>
);
export default Question;
