import React from 'react';

const Cog = props => (
  <svg {...props} width="110" height="91" viewBox="0 0 110 91">
    <defs>
      <path id="a" d="M0 .969h5.63v5.637H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        fill="#0078E6"
        d="M63.327 71.4a2.425 2.425 0 0 1-4.458-.41 2.407 2.407 0 0 1 .17-1.846 2.426 2.426 0 0 1 3.271-1.015 2.424 2.424 0 0 1 1.017 3.271m1.08-7.254c-3.376-1.775-7.57-.475-9.35 2.902a6.881 6.881 0 0 0-.486 5.277A6.888 6.888 0 0 0 57.96 76.4a6.93 6.93 0 0 0 9.35-2.904c1.778-3.378.475-7.572-2.903-9.349"
      />
      <path
        fill="#0078E6"
        d="M74.139 78.518l-1.73 2.234-4.118-1.603-5.958 2.436-1.813 4.03-2.8-.381-.67-4.368-5.088-3.94-4.397.444-1.07-2.615 3.448-2.765.868-6.377-2.583-3.586 1.729-2.234 4.119 1.603 5.956-2.436 1.814-4.03 2.8.381.67 4.368 5.09 3.941 4.396-.444 1.069 2.614-3.447 2.766-.868 6.377 2.583 3.585zm7.17-11.32l-3.614-8.837-5.955.601-2.248-1.741-.91-5.915-9.46-1.288-2.456 5.457-2.631 1.077-5.578-2.172-5.844 7.549 3.497 4.857-.384 2.817-4.668 3.745 3.614 8.836 5.954-.601 2.248 1.741.91 5.916 9.46 1.287 2.457-5.456 2.63-1.077 5.579 2.171 5.844-7.549-3.497-4.856.384-2.818 4.668-3.744zM37.592 44.376l-1.876 4.626-2.4-.186-1.145-4.859-6.163-2.799-4.412 2.338-1.72-1.685 2.247-4.458-2.923-6.107-4.881-1.045-.235-2.397 4.587-1.97 1.688-6.556-3.07-3.939 1.362-1.986 4.78 1.44 5.506-3.939.18-4.99 2.32-.647 2.738 4.176 6.749.522L44.27 6.21l2.193.996-.588 4.958 4.835 4.738 4.945-.688 1.04 2.172-3.636 3.421.658 6.737 4.229 2.651-.6 2.333-4.983.282-3.828 5.585 1.536 4.75-1.958 1.401-4-2.988-6.521 1.82zm10.465 6.743l7.309-5.229-1.868-5.777 1.327-1.936 6.06-.343 2.239-8.705-5.143-3.225-.23-2.336 4.423-4.159-3.88-8.106-6.014.836-1.676-1.642.715-6.03L43.135.752l-4.068 4.506-2.341-.18L33.399 0 24.74 2.413l-.22 6.067-1.91 1.366-5.814-1.75-5.08 7.414 3.73 4.79-.583 2.272-5.58 2.396.876 8.945 5.937 1.271 1.013 2.117-2.734 5.421 6.419 6.29 5.365-2.842 2.138.97 1.39 5.91 8.962.692 2.282-5.626 2.26-.63 4.865 3.633zM93.597 39.152l-2.493 5.29 1.469 3.58-2.175 1.13-2.202-3.111-5.802-.734-2.783 2.36-1.825-1.7 2.189-2.933-1.094-5.744-3.335-2.06.976-2.223 3.773 1.279 5.125-2.816 1.024-4.198 2.324.264-.056 4.35 4.264 4.006 4.275-.322.395 2.323-4.05 1.259zm9.146 1.869l-1.744-10.245-6.386.48-1.375-1.292.083-6.437-10.31-1.176-1.493 6.129-1.654.909-5.803-1.967-4.208 9.58 4.977 3.075.354 1.854-3.417 4.578 7.703 7.177 4.324-3.666 1.872.237 3.324 4.699 9.307-4.836-2.277-5.552.804-1.707 5.919-1.84z"
      />
      <path
        fill="#0078E6"
        d="M86.991 36.432a2.251 2.251 0 0 0-1.293 4.311l.005.001a2.25 2.25 0 0 0 1.288-4.312M27.713 58.23l-.696 4.445 2.043.32 1.959.653 1.423-4.269-2.134-.712zM8.573 43.865l-3.457 2.881 1.368 1.642 1.19 1.783 3.744-2.495-1.248-1.873zM16.791 52.866l-2.233 3.907 1.893 1.082 1.819 1.212 2.497-3.743-1.872-1.249z"
      />
      <g transform="translate(0 31.527)">
        <mask id="b" fill="#fff">
          <path id="a" d="M0 .969h5.63v5.637H0z" />
        </mask>
        <path
          fill="#0078E6"
          d="M5.012 3.207L4.269.969l-4.27 1.424.698 2.091.607 2.122L5.63 5.37z"
          mask="url(#b)"
        />
      </g>
      <path
        fill="#0078E6"
        d="M86.345 10.86c-4.15 0-8.143.895-11.865 2.66l1.927 4.067a23.01 23.01 0 0 1 9.938-2.227c7.729 0 14.93 3.829 19.263 10.243l3.728-2.52A27.715 27.715 0 0 0 86.345 10.86M38.254 28.12a2.408 2.408 0 0 1-1.426 1.184 2.408 2.408 0 0 1-1.845-.169 2.425 2.425 0 0 1-1.017-3.272 2.426 2.426 0 0 1 3.272-1.016c.573.301.994.807 1.186 1.425a2.41 2.41 0 0 1-.17 1.847m1.08-7.255c-3.376-1.777-7.572-.474-9.351 2.903a6.931 6.931 0 0 0 6.119 10.15c.689 0 1.383-.105 2.06-.315a6.874 6.874 0 0 0 4.074-3.388 6.874 6.874 0 0 0 .486-5.276 6.882 6.882 0 0 0-3.388-4.074"
      />
    </g>
  </svg>
);
export default Cog;
