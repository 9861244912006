import { observable, action } from 'mobx';
import request from 'superagent';
import localStorage from 'mobx-localstorage';

import { history } from 'store';

export class articleStore {
  @observable isLoading = false;
  @observable overviewLoading = false;
  @observable overviewResponse = null;

  @observable overviewItemsResponse = null;
  @observable overviewItemsTotal = 0;
  @observable overviewItemsLoading = false;

  @observable overviewTotalsAmount = null;
  @observable overviewTotalsLoading = false;

  @observable response = null;

  @observable currentOverviewPage = null;

  @action openCategory(index) {
    this.activeIndex = index;
    return true;
  }

  @action getArticleItems(page, redirect = false) {
    if (page === 0 && redirect === false) {
      window.history.pushState('', '', '/blog');
    } else if (redirect === true) {
      history.push('/blog');
    }
    this.overviewItemsLoading = true;

    return request
      .get(
        `${process.env.REACT_APP_API_URL}/article/v1/overview${
          localStorage.getItem('i18nextLng') === 'nl'
            ? ''
            : `?lang=${localStorage.getItem('i18nextLng')}`
        }`
      )
      .query({ page: page })
      .then((response) => {
        this.overviewItemsResponse = response.body;
        this.overviewItemsTotal = response.body.total;
      })
      .then(() => {
        this.overviewItemsLoading = false;
      })
      .catch((error) => {
        this.overviewItemsResponse = error;
        this.overviewItemsTotal = 0;
      });
  }

  @action getArticleOverviewTotals(page, filter = null) {
    this.overviewTotalsLoading = true;

    return request
      .get(`${process.env.REACT_APP_API_URL}/article/v1/overview`)
      .then((response) => {
        this.overviewTotalsAmount = response.body;
      })
      .then(() => {
        this.overviewTotalsLoading = false;
      })
      .catch((error) => {
        this.overviewTotalsAmount = 0;
      });
  }
  @action setCurrentOverviewPage(page) {
    this.currentOverviewPage = page;
  }

  @action uniqueValues(index, item) {
    let result = item.filter(function(a) {
      var key = a.value + '|' + a.label;
      if (!this[key]) {
        this[key] = true;
        return true;
      }
      return false;
    }, Object.create(null));

    // Set filter item placeholder
    let placeholder = '';
    result.map((item, i) => {
      let formattedLabel = item.label.replace(/\./g, '');
      if (result.length === i + 1) {
        placeholder += `${formattedLabel}`;
      } else {
        placeholder += `${formattedLabel}, `;
      }
      return false;
    });

    // update
    this.filter[index] = result;
    this.filterPlaceholders[index] = placeholder;

    localStorage.setItem('filter', this.filter);
    localStorage.setItem('filterPlaceholders', this.filterPlaceholders);

    if (index === 0) {
      this.filterSubcategoryResponse = null;
      this.isDisabledSubcategory = true;
      this.filter[1] = [];
      this.filterPlaceholders[1] = '';
    }

    //Update totals
    this.getArticleOverviewTotals();
  }
}
export default new articleStore();
