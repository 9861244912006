import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import styles from './styles.scss';
import Partner from 'components/partner';
import SimpleContent from 'components/simple-content';

// decorators
@inject('goStore')
@observer
class GoPartner extends Component {
  render() {
    const { details } = this.props;
    return (
      <div className={styles.textblock}>
        <div className="wrapper">
          <SimpleContent details={details.default} />
          {details.partners && (
            <div class={styles.namelist}>
              {details.partners.map((item, i) => {
                return (
                  <Partner key={i} name={item.name} company={item.company} />
                );
              })}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default GoPartner;
