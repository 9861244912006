import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import localStorage from 'mobx-localstorage';
import { withNamespaces } from 'react-i18next';

// styles
import styles from './styles.scss';

// components
import FilterItem from 'components/filter-item';
import Loading from 'components/loading';

// icons
import Search from 'icons/search';

window.preselector = true;

// decorators
@inject('vacancyStore')
@observer
class Filter extends Component {
  componentDidMount() {
    this.props.vacancyStore.getFilterCategories();
  }

  handleClick = (e) => {
    e.preventDefault();
    this.props.vacancyStore.getVacancyItems(0, true);
    this.props.vacancyStore.openCategory(-1);
  };

  render() {
    const { type, t, preselect } = this.props;
    const {
      response,
      filterSubcategoryResponse,
      overviewTotalsAmount,
      isLoading,
    } = this.props.vacancyStore;

    const subcategories = localStorage.getItem('filterSubcategories')
      ? localStorage.getItem('filterSubcategories')
      : filterSubcategoryResponse;
    return (
      <div
        className={[
          styles.filter,
          type === 'alert' ? styles['filter--alert'] : null,
        ].join(' ')}
      >
        {isLoading === false && response ? (
          <React.Fragment>
            <FilterItem
              alert={type ? true : false}
              index={0}
              item={response.sector}
              preselect={preselect}
              placeholder={t('Industry')}
              sector={true}
            />
            <FilterItem
              alert={type ? true : false}
              index={1}
              item={subcategories}
              placeholder={t('Subindustry')}
            />
            <FilterItem
              alert={type ? true : false}
              index={2}
              item={response.area}
              placeholder={t('Region')}
            />
            <FilterItem
              alert={type ? true : false}
              index={3}
              item={response.workweek}
              placeholder={t('Workweek')}
            />
            {type !== 'alert' && (
              <span className={styles.search} onClick={this.handleClick}>
                <Search />
                <span>
                  {overviewTotalsAmount === null
                    ? t('Search')
                    : // i18n
                      `${
                        overviewTotalsAmount === 0 || overviewTotalsAmount > 1
                          ? `${overviewTotalsAmount + ' ' + t('Vacancies')}`
                          : `${overviewTotalsAmount + ' ' + t('Vacancy')}`
                      }` +
                      ' ' +
                      t('Found')}
                </span>
              </span>
            )}
          </React.Fragment>
        ) : (
          <div className={styles['filter-loading']}>
            <Loading />
          </div>
        )}
      </div>
    );
  }
}

export default withNamespaces()(Filter);
