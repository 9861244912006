import React from 'react';

const Logistics = props => (
  <svg {...props} width="125" height="66" viewBox="0 0 125 66">
    <g fill="#0078E6" fillRule="evenodd">
      <path d="M43.713 47.706a4.75 4.75 0 0 1-4.744 4.745 4.75 4.75 0 0 1-4.744-4.745 4.75 4.75 0 0 1 4.744-4.744 4.75 4.75 0 0 1 4.744 4.744m-13.988 0c0 5.098 4.146 9.245 9.244 9.245s9.244-4.147 9.244-9.245c0-5.097-4.146-9.244-9.244-9.244s-9.244 4.147-9.244 9.244M101.74 47.706a4.75 4.75 0 0 1-4.745 4.745 4.75 4.75 0 0 1-4.744-4.745 4.75 4.75 0 0 1 4.744-4.744 4.75 4.75 0 0 1 4.744 4.744m-13.988 0c0 5.098 4.146 9.245 9.244 9.245s9.244-4.147 9.244-9.245c0-5.097-4.146-9.244-9.244-9.244s-9.244 4.147-9.244 9.244" />
      <path d="M20.939 4.5H79.3v24.802H20.94V4.5zm88.174 16.993c.303 1.186.481 2.421.481 3.699v3.493H98.2v-7.192h10.915zM20.94 33.802H79.3V44.013H52.17v4.5H83.8v-38.31h10.806c5.238 0 9.852 2.707 12.532 6.79h-13.44v16.192h15.897v15.328h4.5V25.192c0-10.746-8.743-19.489-19.49-19.489H83.8V0H16.44v48.513h9.328v-4.5h-4.828V33.802zM9.18 60.954v4.5h4.52l-.02-4.5zM18.37 60.954v4.5h4.52l-.02-4.5zM0 60.954v4.5h4.51l-.01-4.5zM32.06 60.954h-4.5v4.5H124.886v-4.5z" />
    </g>
  </svg>
);
export default Logistics;
