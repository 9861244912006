import React from 'react';
import { observer } from 'mobx-react';
import Dropzone from 'react-dropzone';

// icons
import Upload from 'icons/upload';

// styles
import styles from './styles.scss';
const setFile = (form) => {
  form.$('noFile').set(false);
  form.$('newFile').set(true);
};
// i18n
export default observer(({ field, user, form, translations }) => (
  <React.Fragment>
    {field.files && field.files[0].length ? (
      <div className={styles.filledCV}>
        <Upload fill1='white' fill2='#0078E6' />
        <span className={styles.text}>
          <span className={styles['file-name']} key={field.files[0][0].name}>
            {field.files[0][0].name}
          </span>
          {translations.alert}
        </span>
        <span onClick={(e) => field.reset()} className={styles.linktext}>
          {translations.change}
        </span>
      </div>
    ) : form.values().newFile === true ? (
      <Dropzone
        accept='image/jpeg, image/png, .pdf, .doc, .docx'
        multiple={false}
        className={styles.dropzone}
        activeClassName={styles.dropzonecomplete}
        onDrop={field.onDrop}
        ref={(dropzone) => field.state.extra({ dropzone })}
      >
        <Upload fill1='#0078E6' fill2='white' />
        <span className={styles.bluetext}>{translations.click}</span>{' '}
        {translations['or']}{' '}
        <span className={styles.bluetext}>{translations.drag}</span>{' '}
        {translations.here}.
      </Dropzone>
    ) : user.hasFile && form.values().noFile === true ? (
      <div className={styles.filledCV}>
        <Upload fill1='white' fill2='#0078E6' />
        <span className={styles.text}>
          <span>
            {user.firstName}, {translations['known']}
          </span>
        </span>
        <span onClick={(e) => setFile(form)} className={styles.linktext}>
          {translations.change}
        </span>
      </div>
    ) : (
      <Dropzone
        accept='image/jpeg, image/png, .pdf, .doc, .docx'
        multiple={false}
        className={styles.dropzone}
        activeClassName={styles.dropzonecomplete}
        onDrop={field.onDrop}
        ref={(dropzone) => field.state.extra({ dropzone })}
      >
        <Upload fill1='#0078E6' fill2='white' />
        <span className={styles.text}>
          <span className={styles.bluetext}>{translations.click}</span>{' '}
          {translations.or}{' '}
          <span className={styles.bluetext}>{translations.drag}</span>{' '}
          {translations.here}.
        </span>
      </Dropzone>
    )}
  </React.Fragment>
));
