import React from 'react';
import { hydrate, render } from 'react-dom';
// import registerServiceWorker from './registerServiceWorker';
import { Provider } from 'mobx-react';
import { Router } from 'react-router';
import App from 'containers/app';
import ReactGA from 'react-ga';
import './i18n';
// import { hotjar } from 'react-hotjar';
import 'promise-polyfill/src/polyfill';

// styles
import 'sanitize.css/sanitize.css';
import 'styles/global.scss';
import 'fonts/fonts.css';

import stores, { history } from 'store';

// includes polyfill
if (!Array.prototype.includes) {
  // eslint-disable-next-line
  Object.defineProperty(Array.prototype, 'includes', {
    value: function(searchElement, fromIndex) {
      // 1. Let O be ? ToObject(this value).
      if (this == null) {
        throw new TypeError('"this" is null or not defined');
      }

      var o = Object(this);

      // 2. Let len be ? ToLength(? Get(O, "length")).
      var len = o.length >>> 0;

      // 3. If len is 0, return false.
      if (len === 0) {
        return false;
      }

      // 4. Let n be ? ToInteger(fromIndex).
      //    (If fromIndex is undefined, this step produces the value 0.)
      var n = fromIndex | 0;

      // 5. If n ≥ 0, then
      //  a. Let k be n.
      // 6. Else n < 0,
      //  a. Let k be len + n.
      //  b. If k < 0, let k be 0.
      var k = Math.max(n >= 0 ? n : len - Math.abs(n), 0);

      // 7. Repeat, while k < len
      while (k < len) {
        // a. Let elementK be the result of ? Get(O, ! ToString(k)).
        // b. If SameValueZero(searchElement, elementK) is true, return true.
        // c. Increase k by 1.
        // NOTE: === provides the correct "SameValueZero" comparison needed here.
        if (o[k] === searchElement) {
          return true;
        }
        k++;
      }

      // 8. Return false
      return false;
    },
  });
}

if (process.env.NODE_ENV === 'production') {
  ReactGA.initialize('UA-70917039-1');
  // hotjar.initialize(1111111, 0);
}

history.listen((location, action) => {
  // let productOverview = ['/pagina/'].some(string =>
  //   location.pathname.includes(string)
  // );
  // if (!productOverview) {
  window.scrollTo(0, 0);
  document.getElementsByTagName('body')[0].scrollTop = 0;
  // }
});

// render(
//   <Provider {...stores}>
//     <Router history={history}>
//       <App />
//     </Router>
//   </Provider>,
//   target
// );

const target = document.querySelector('#concreeto');

if (target.hasChildNodes()) {
  hydrate(
    <Provider {...stores}>
      <Router history={history}>
        <App />
      </Router>
    </Provider>,
    target
  );
} else {
  render(
    <Provider {...stores}>
      <Router history={history}>
        <App />
      </Router>
    </Provider>,
    target
  );
}
// registerServiceWorker();
