import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import CountUp from 'react-countup';

// styles
import styles from './styles.scss';

// components
import Button from 'components/button';
import Filter from 'components/filter';
import Loading from "components/loading";
import Notch from 'components/notch';
import Pagination from "components/pagination";
import RadioCvOverlay from 'components/cv-overlay';
import RadioCvUpload from 'components/cv-upload';
import VacancyGrid from "components/vacancy-grid";
import VacancyGridItem from "components/vacancy-grid-item";

// icons
import ArrowRight from 'icons/arrow-right';

// decorators
@inject('vacancyStore')
@inject('homeStore')

@observer
class RadioFeaturedFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false
    };
  }

  openPopup = () => {
    this.setState({
      modal: true
    });
  };

  closePopup = () => {
    this.setState({
      modal: false
    });
  };

  render() {
    const { response } = this.props.homeStore;
    const { preselect } = this.props;
    const { modal } = this.state;

    const {
      // overviewResponse,
      overviewItemsResponse,
      // overviewItemsTotal,
      overviewItemsLoading,
    } = this.props.vacancyStore;

    return (
      <section className={styles['featured-filter']}>
        <Notch variants={['blue', 'longer']} />
        <div className={['wrapper', styles['wrapper-top']].join(' ')}>
          <div className={styles.amount}>
            <div className={styles['amount-item']}>
              <span>
                <CountUp
                  start={0}
                  end={response.totals.work}
                  duration={3}
                  useEasing={false}
                />
              </span>
              <b>
                Concreeto krachten
                <br /> zijn deze week
                <br /> aan het werk
              </b>
            </div>
            <div className={styles['amount-item']}>
              <span>
                <CountUp
                  start={0}
                  end={response.totals.vacancies}
                  duration={3}
                  useEasing={false}
                />
              </span>
              <b>
                Vacatures
                <br />
                beschikbaar
              </b>
            </div>
          </div>
        </div>
        <div className={['wrapper', styles.wrapper].join(' ')}>
          <p>Ga jij aan de slag onder de Concreeto vlag? In de grote regio Rotterdam hebben we veel mooie uitdagingen. Jouw inzet wordt beloond met een goed salaris, wat elke week wordt uitbetaald. Check hier onze openstaande vacatures en solliciteer! </p>

          <Button
            type="dummy"
            onClick={this.openPopup}
            to="#"
            className={['large', 'green']}>
            CV uploaden <ArrowRight fill="#fff" />
          </Button>

          <p className={styles['amount-inline']}>
            <span>
              <CountUp
                start={0}
                end={response.totals.vacancies}
                duration={3}
                useEasing={false}
              />
            </span>
            vacatures
            <span className={styles.dik}>Gers van de pers.</span>
          </p>
          <div className={styles['radio-filter']}>
            <Filter preselect={preselect} />
          </div>

          {overviewItemsLoading ? (
            <Loading />
          ) : (
            <React.Fragment>
              {overviewItemsResponse && overviewItemsResponse.items ? (
                <React.Fragment>
                  <VacancyGrid>
                    {overviewItemsResponse.items.map((item, i) => {
                      return (
                        <VacancyGridItem
                          delay={i * 100}
                          item={item}
                          itemFeatured={item.featured}
                          itemFeaturedText={item.featuredText}
                          key={i}
                        />
                      );
                    })}
                  </VacancyGrid>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <h1
                    className={[
                      styles.title,
                      styles["title--no-result"],
                    ].join(" ")}
                  >
                    geen vacatures
                    <br />
                    gevonden.
                  </h1>
                </React.Fragment>
              )}
              {overviewItemsResponse && overviewItemsResponse.total > 9 && (
                <Pagination
                  total={overviewItemsResponse.total}
                  perPage={9}
                  currentPage={0}
                  base="/vacatures/pagina"
                />
              )}
            </React.Fragment>
          )}

        </div>

        {modal && (
          <RadioCvOverlay close={this.closePopup}>
            <RadioCvUpload
              details={{
                id: 0,
                title: "Algemeen",
                categories: []
              }}
            />
          </RadioCvOverlay>
        )}

      </section>
    );
  }
}

export default RadioFeaturedFilter;
