import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import Notch from 'components/notch';
import styles from './styles.scss';
import GoTogetherPanel from 'components/go-together-panel';
import SimpleContent from 'components/simple-content';

// decorators
@inject('goStore')
@observer
class GoTogether extends Component {
  render() {
    const { details } = this.props;
    return (
      <div className={styles.textblock}>
        <Notch variants={['blue', 'left', 'half']} />
        <div className="wrapper">
          <SimpleContent details={details.default} type="dark-blue" />
          {details.team && (
            <div class={styles.panellist}>
              {details.team.map((item, i) => {
                return <GoTogetherPanel key={i} details={item} />;
              })}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default GoTogether;
