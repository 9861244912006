import React, { Component } from 'react';
// import { CSSTransition } from 'react-transition-group';

// styles
import styles from './styles.scss';

// components
import GridTest from 'components/grid-test';

// icons

class Notch extends Component {
  classNames(variants) {
    let classes = [];
    classes.push('notch');
    classes.push(styles['notch']);
    if (variants) {
      variants.forEach(function(variant) {
        classes.push(styles[`notch--${variant}`]);
      });
    }
    return classes.join(' ');
  }

  render() {
    const { variants } = this.props;
    // const { mount } = this.state;
    return (
      // <CSSTransition
      //   in={mount}
      //   timeout={400}
      //   classNames={{
      //     enterDone: styles['notch-enter-done']
      //   }}
      //   unmountOnExit>
      // {state => (
      <div className={this.classNames(variants)}>
        {/* <div className={styles.test}>
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
        </div> */}
        <GridTest variants={variants} />
        <div className={['wrapper', styles.wrapper].join(' ')}>
          <div className={styles.path} />
        </div>
      </div>
      //   )}
      // </CSSTransition>
    );
  }
}

export default Notch;
