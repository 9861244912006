import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { CSSTransition } from 'react-transition-group';

//components
import Loading from 'components/loading';

//styles
import styles from './styles.scss';

//icons
import Slogan from 'icons/slogan';

//decorators
@inject('popupStore')
@observer
class PopupStory extends Component {
  componentDidMount() {
    this.props.popupStore.getStory();
  }

  render() {
    const { storyLoading, story, storyLoaded } = this.props.popupStore;
    return (
      <div
        className={[
          styles.story,
          storyLoading === false ? styles['story--loaded'] : null
        ].join(' ')}
        style={{ backgroundImage: `url(${story && story.image}` }}>
        {storyLoading ? (
          <div className={styles.loading}>
            <Loading />
          </div>
        ) : (
          <React.Fragment>
            <div className={styles.content}>
              {story.firstName && (
                <CSSTransition
                  in={storyLoaded}
                  timeout={600}
                  classNames={{
                    enterDone: styles['subtitle-enter-done']
                  }}
                  unmountOnExit>
                  {state => (
                    <h3 className={styles.subtitle}>{`${
                      story.firstName
                    }${story.age && `${', '}${story.age}${' '}`}`}</h3>
                  )}
                </CSSTransition>
              )}
              <CSSTransition
                in={storyLoaded}
                timeout={200}
                classNames={{
                  enterDone: styles['title-enter-done']
                }}
                unmountOnExit>
                {state => (
                  <h2
                    className={styles.title}
                    dangerouslySetInnerHTML={{ __html: story.title }}
                  />
                )}
              </CSSTransition>
              <CSSTransition
                in={storyLoaded}
                timeout={800}
                classNames={{
                  enterDone: styles['slogan-enter-done']
                }}
                unmountOnExit>
                {state => <Slogan className={styles.slogan} />}
              </CSSTransition>
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
}
export default PopupStory;
