import { observable, action } from 'mobx';
import request from 'superagent';
export class vacancyUploadStore {
  @observable isLoading = false;
  @observable response = null;
  @observable step = 1;
  @observable user = null;
  @observable error = null;
  @observable currentCategories = null;
  @observable title = null;

  @action validate(values, form) {
    this.isLoading = true;
    return request
      .post(`${process.env.REACT_APP_API_URL}/vacancies/v1/vacancy/check`)
      .send(values)
      .then(response => {
        this.response = response.body;
        this.error = null;
        this.user = response.body.data.user;
        this.step = this.step + 1;
        form.$('step').set(form.$('step').get('value') + 1);
        if (this.user === false) {
          form.$('noFile').set(false);
          form.$('newFile').set(true);
        } else {
          form.$('noFile').set(true);
          form.$('newFile').set(false);
        }
      })
      .then(() => {
        this.isLoading = false;
      })
      .catch(error => {
        this.error = error.response.body.message;
        this.response = null;
        this.user = null;
      });
  }
  @action setStep(step) {
    this.step = step;
  }
  @action setDetails(title, categories) {
    this.title = title;
    this.setCurrentCategories(categories);
  }

  @action setCurrentCategories(categories) {
    this.currentCategories = categories;
  }

  @action setError(error) {
    this.error = error;
  }
}

export default new vacancyUploadStore();
