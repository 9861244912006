import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { withNamespaces } from "react-i18next";

// styles
import styles from './styles.scss';

// components
import Button from 'components/button';
import CvOverlay from 'components/cv-overlay';
import CvUpload from 'components/cv-upload';

// icons
import ArrowRight from 'icons/arrow-right';
import PhoneBlock from 'icons/phone-block-vacancy';
import MailBlock from 'icons/mail-block-vacancy';

// decorators
@observer
class VacancyAuthor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false
    };
  }

  openPopup = () => {
    this.setState({
      modal: true
    });
  };

  closePopup = () => {
    this.setState({
      modal: false
    });
  };
  render() {
    const { detail, t } = this.props;
    const { modal } = this.state;
    // detail.categories gebruiken om door te geven aan de modal
    // De titel moet er ook nog bij, iets generieks voor verzinnen zodat dat altijd hetzelfde is
    // iets van {ids: {area: 1, sector:2}, 'label': {area: 'Rotterdam', sector: 'Logistiek'}, title: 'Titel', employment: 'fulltime'}
    return (
      <React.Fragment>
        <React.Fragment>
          <figure className={styles.image}>
            {detail.author ? (
              <img src={detail.author.avatar} alt={detail.author.name} />
            ) : (
              <img src="" alt="" />
            )}
          </figure>
          {detail.author ? (
            <h4 className={styles.title}>{detail.author.name}</h4>
          ) : (
            <h4 className={styles.title}>Concreeto</h4>
          )}
          {detail.author && (
            <span className={styles.subtitle}>
              {t('Contact person')} {detail.author.sector}
            </span>
          )}
          <ul className={styles.contact}>
            {detail.author ? (
              <React.Fragment>
                <li>
                  <a href={`tel:${detail.author.phonenumber}`}>
                    <PhoneBlock />
                    <span>{detail.author.phonenumber}</span>
                  </a>
                </li>
                <li>
                  <a
                    className={styles.mail}
                    href={`mailto:${detail.author.emailaddress}`}>
                    <MailBlock />
                    <span>{detail.author.emailaddress}</span>
                  </a>
                </li>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <li>
                  <a href={`tel:0107200720`}>
                    <PhoneBlock />
                    <span>010 – 7200720</span>
                  </a>
                </li>
                <li>
                  <a className={styles.mail} href={`mailto:info@concreeto.nl`}>
                    <MailBlock />
                    <span>info@concreeto.nl</span>
                  </a>
                </li>
              </React.Fragment>
            )}

            <li>
              <Button
                type="dummy"
                onClick={this.openPopup}
                to="/partners"
                className={['large', 'green']}>
                {t('resume-upload description 3')}
                <ArrowRight fill="white" />
              </Button>
            </li>
          </ul>
        </React.Fragment>
        {modal && (
          <CvOverlay close={this.closePopup}>
            <CvUpload
              details={{
                id: detail.id,
                title: detail.title,
                categories: detail.categories
              }}
            />
          </CvOverlay>
        )}
      </React.Fragment>
    );
  }
}

export default withNamespaces()(VacancyAuthor);
