import React from 'react';

const checkmarkvacancy = props => (
  <svg {...props} width="28px" height="28px" viewBox="0 0 28 28">
    <desc>Created with sketchtool.</desc>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-562.000000, -1364.000000)">
        <g transform="translate(538.000000, 1264.000000)">
          <g>
            <g transform="translate(24.000000, 100.000000)">
              <rect fill="#0078E6" x="0" y="0" width="28" height="28"></rect>
              <polygon
                fill="#FFFFFF"
                fillRule="nonzero"
                points="18.7169043 9.85234783 13.199687 15.3914783 10.500487 12.6818087 8.52222609 14.6685913 13.1982261 19.3650435 20.6951652 11.8381565"></polygon>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export default checkmarkvacancy;
