import React from 'react';

const Share = props => (
    <svg {...props} width="33px" height="33px" viewBox="0 0 33 33">
    <g id="Ontwerpen" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="02---Vacature-page" transform="translate(-735.000000, -563.000000)">
            <g id="Group-5" transform="translate(537.000000, 562.000000)">
                <g id="Group-2-Copy" transform="translate(198.000000, 0.000000)">
                    <g id="Group-10" transform="translate(0.000000, 1.000000)">
                        <rect id="Rectangle-7" fill="#0078E6" x="3.62376795e-13" y="2.13162821e-14" width="32.2" height="32.2"></rect>
                        <g id="np_branch_300284_000000" transform="translate(12.000000, 9.000000)" fill="#FFFFFF" fillRule="nonzero">
                            <path d="M0.976973684,3.45594059 L0.976973684,8.44336634 C1.46715395,7.79535149 2.10773026,7.27207426 2.78618421,6.83123762 L2.78618421,3.45594059 C3.32443158,3.1339604 3.69078947,2.54165347 3.69078947,1.85643564 C3.69078947,0.831920792 2.88116053,0 1.88157895,0 C0.881997368,0 0.0723684211,0.831920792 0.0723684211,1.85643564 C0.0723684211,2.54157921 0.438726316,3.13390099 0.976973684,3.45594059 Z M1.88157895,0.928217822 C2.38024079,0.928217822 2.78618421,1.34475743 2.78618421,1.85643564 C2.78618421,2.36811386 2.38024079,2.78465347 1.88157895,2.78465347 C1.38291711,2.78465347 0.976973684,2.36811386 0.976973684,1.85643564 C0.976973684,1.34475743 1.38291711,0.928217822 1.88157895,0.928217822 Z" ></path>
                            <path d="M9.11842105,-1.26279625e-14 C8.11883947,-1.26279625e-14 7.30921053,0.831920792 7.30921053,1.85643564 C7.30921053,2.54157921 7.67443947,3.13390099 8.21381579,3.45594059 L8.21381579,3.71294554 C8.21381579,4.90918812 7.03500658,5.60012376 5.09575,6.59502475 C3.16496053,7.58532178 0.976973684,8.70839109 0.976973684,11.1386881 L0.976973684,11.3956931 C0.438726316,11.7176733 0.0723684211,12.3099802 0.0723684211,12.995198 C0.0723684211,14.0197129 0.881997368,14.8516337 1.88157895,14.8516337 C2.88116053,14.8516337 3.69078947,14.0197129 3.69078947,12.995198 C3.69078947,12.3100545 3.32443158,11.7177327 2.78618421,11.3956931 L2.78618421,11.1386881 C2.78618421,9.94244554 3.96386447,9.2515099 5.90425,8.25660891 C7.83503947,7.26631188 10.0230263,6.14324257 10.0230263,3.71294554 L10.0230263,3.45594059 C10.5624026,3.1339604 10.9276316,2.54165347 10.9276316,1.85643564 C10.9276316,0.831920792 10.1180026,-1.42108547e-14 9.11842105,-1.26279625e-14 Z M1.88157895,13.9232673 C1.38291711,13.9232673 0.976973684,13.5067277 0.976973684,12.9950495 C0.976973684,12.4833713 1.38291711,12.0668317 1.88157895,12.0668317 C2.38024079,12.0668317 2.78618421,12.4833713 2.78618421,12.9950495 C2.78618421,13.5067277 2.38024079,13.9232673 1.88157895,13.9232673 Z M9.11842105,2.78465347 C8.61975921,2.78465347 8.21381579,2.36811386 8.21381579,1.85643564 C8.21381579,1.34475743 8.61975921,0.928217822 9.11842105,0.928217822 C9.61708289,0.928217822 10.0230263,1.34475743 10.0230263,1.85643564 C10.0230263,2.36811386 9.61708289,2.78465347 9.11842105,2.78465347 Z" ></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>
);
export default Share;





