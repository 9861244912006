import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import CountUp from 'react-countup';
import { withNamespaces } from "react-i18next";

// styles
import styles from './styles.scss';

// components
import Notch from 'components/notch';
import Filter from 'components/filter';
import Categories from 'components/featured-filter-categories';

// decorators
@inject('vacancyStore')
@inject('homeStore')

@observer
class FeaturedFilter extends Component {
  render() {
    const { response } = this.props.homeStore;
    const { t } = this.props;

    return (
      <section className={styles['featured-filter']}>
        <Notch variants={['blue', 'longer']} />
        <div className={['wrapper', styles['wrapper-top']].join(' ')}>
          <div className={styles.amount}>
            <div className={styles['amount-item']}>
              <span>
                <CountUp
                  start={0}
                  end={response.totals.work}
                  duration={3}
                  useEasing={false}
                />
              </span>
              <b>
                {t('Working forces')}
              </b>
            </div>
            <div className={styles['amount-item']}>
              <span>
                <CountUp
                  start={0}
                  end={response.totals.vacancies}
                  duration={3}
                  useEasing={false}
                />
              </span>
              <b>
                {t('Available job offers')}
              </b>
            </div>
          </div>
        </div>
        <div className={['wrapper', styles.wrapper].join(' ')}>
          <Filter />
          <Categories />
        </div>
      </section>
    );
  }
}

export default withNamespaces()(FeaturedFilter);
