import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Helmet } from 'react-helmet';
import Fade from 'react-reveal/Fade';

import MethodContent from 'components/method-content';
import PageLoading from 'components/page-loading';
import GridTest from 'components/grid-test';
import Timeline from 'components/timeline';
import FeaturedImage from 'components/featured-image';

// decorators
@inject('methodStore')
@observer
class Method extends Component {
  componentDidMount() {
    this.props.methodStore.getPageDetails();
  }
  render() {
    const { response } = this.props.methodStore;
    return (
      <React.Fragment>
        {response ? (
          <React.Fragment>
            <Helmet>
              <meta charSet="utf-8" />
              <title>{response.seo.siteTitle}</title>
              <meta name="description" content={response.seo.description} />
              <meta property="fb:pages" content="828024637343069" />
              <meta property="og:title" content={response.seo.title} />
              <meta
                property="og:description"
                content={response.seo.description}
              />
              <meta
                property="og:url"
                content={`${process.env.REACT_APP_SITE_URL}${
                  window.location.pathname
                }`}
              />
              <meta property="og:image" content={response.seo.image} />
              <meta property="og:site_name" content={response.seo.siteName} />
            </Helmet>

            <Fade bottom>
              {/* <BlueBackground> */}
              <MethodContent content={response.content} />
              {/* <MethodRouteApply process={response.process} />
              <MethodRouteWorkday workweek={response.workweek} /> */}
              {/* </BlueBackground> */}
            </Fade>
            <Fade bottom>
              <Timeline data={response.process} />
            </Fade>

            <Fade bottom>
              <Timeline
                data={response.workweek}
                title={`Jouw eerste<br/>werkweek!`}
                check={false}
              />
            </Fade>
            <Fade bottom>
              <FeaturedImage response={response.cta} />
            </Fade>
          </React.Fragment>
        ) : (
          <PageLoading color="#0078e6" />
        )}
        <GridTest variants={['default']} />
      </React.Fragment>
    );
  }
}

export default Method;
