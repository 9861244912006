import React, { Component } from 'react';
import CountUp from 'react-countup';

import styles from './styles.scss';

class PartnerStats extends Component {
  render() {
    const { statistics } = this.props;
    return (
      <section className={styles.background}>
        <div className={['wrapper', styles.block].join(' ')}>
          {statistics &&
            statistics.map((stat, i) => {
              return (
                <div className={styles.item}>
                  <span>
                    <CountUp
                      className={styles.countup}
                      start={0}
                      end={stat.value}
                      duration={5}
                      useEasing={false}
                    />
                    {stat.additional.includes('%') ? (
                      stat.additional
                    ) : (
                      // Kijken of dit kan zonder de rotate2 className, zie page-hero-share
                      <div className={styles.rotate}>{stat.additional}</div>
                    )}
                  </span>
                  <h2 dangerouslySetInnerHTML={{ __html: stat.description }} />
                </div>
              );
            })}
        </div>
      </section>
    );
  }
}

export default PartnerStats;
