import React from 'react';

const Hamburger = props => (
  <svg {...props} width="22px" height="15px" viewBox="0 0 22 15">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        transform="translate(-72.000000, -30.000000)"
        // fill="#143264"
        fillRule="nonzero">
        <g>
          <g>
            <g transform="translate(72.000000, 26.000000)">
              <g transform="translate(0.000000, 4.000000)">
                <path d="M0,0 L0,3.10344828 L22,3.10344828 L22,0 L0,0 Z M0,5.94827586 L0,9.05172414 L22,9.05172414 L22,5.94827586 L0,5.94827586 Z M0,11.8965517 L0,15 L22,15 L22,11.8965517 L0,11.8965517 Z" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export default Hamburger;
