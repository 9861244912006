import React, { Component } from "react";
import { CSSTransition } from "react-transition-group";
import { inject, observer } from "mobx-react";
import localStorage from "mobx-localstorage";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import Loading from "components/loading";
// styles
import styles from "./styles.scss";

// icons
import ChevronDown from "icons/chevron-down";
import Cross from "icons/cross";
import CheckFilter from "icons/check-filter";

// decorators
@inject("vacancyStore")
@observer
class FilterItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      height: 400,
    };
  }
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    this.setHeight();
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
    window.removeEventListener("resize", this.setHeight, false);
  }

  setHeight = () => {
    if (this.props.item) {
      let height = this.props.item.length * 61;
      if (height > 400) {
        this.setState({
          height: 400,
        });
      } else {
        this.setState({
          height: this.props.item.length * 61,
        });
      }
    }

    // if (this.refs.content) {
    //   let contentHeight = ReactDOM.findDOMNode(this.refs.content).clientHeight;
    //   if (
    //     window.location.pathname.includes('cases/') ||
    //     window.location.pathname.includes('vacatures/')
    //   ) {
    //     if (document.body.clientHeight < contentHeight) {
    //       document.getElementById('opinity').style.height = `${contentHeight +
    //         paddingTop}px`;
    //     }
    //   }

    //   let height = contentHeight + 34;
    //   if (window.innerHeight < height) {
    //     if (window.innerWidth < 768) {
    //       height = window.innerHeight;
    //     } else {
    //       height = window.innerHeight - 32;
    //     }
    //   } else {
    //     height = contentHeight + 34;
    //   }
    //   this.setState({
    //     height: height
    //   });
    // }
  };

  openFilterItem = (index) => {
    if (this.props.vacancyStore.activeIndex !== index) {
      this.props.vacancyStore.openCategory(index);
    } else if (this.props.vacancyStore.activeIndex === index) {
      this.props.vacancyStore.openCategory(-1);
    }
  };

  handleClickOutside = (event) => {
    if (this.props.vacancyStore.activeIndex === this.props.index) {
      if (event.target.parentNode.querySelectorAll(".dropdown").length > 1) {
        event.preventDefault();
        this.props.vacancyStore.openCategory(-1);
      }
    }
  };

  addValue = (id, value, label) => {
    this.props.vacancyStore.addItemValue(this.props.index, id, value, label);
    if (this.props.sector) {
      this.props.vacancyStore.getFilterSectorSubcategories(id);
    }
  };

  removeValue = (id, value) => {
    this.props.vacancyStore.removeItemValue(this.props.index, id, value);
    if (this.props.sector) {
      this.props.vacancyStore.getFilterSectorSubcategories(id);
    }
  };

  render() {
    const {
      activeIndex,
      filter,
      filterPlaceholders,
      isDisabledSubcategory,
      filterSubcategoryLoading,
    } = this.props.vacancyStore;
    const { index, item, placeholder, alert, preselect } = this.props;

    const { height } = this.state;

    const values = localStorage.getItem("filter")
      ? localStorage.getItem("filter")
      : filter;

    const placeholders = localStorage.getItem("filterPlaceholders")
      ? localStorage.getItem("filterPlaceholders")
      : filterPlaceholders;

    if(window.preselector && index == 0 && preselect) {
      item && item.map((term, i) => {
        preselect == term.name && this.addValue(term.id, term.slug, term.name);
      });
      window.preselector = false;
    }

    return (
      <div
        className={[
          styles["filter-item"],
          values && values[index] && index === 1 && isDisabledSubcategory
            ? styles["filter-item--disabled"]
            : null,
          activeIndex === index ? styles["filter-item--is-active"] : null,
          alert ? styles["filter-item--alert"] : null,
        ].join(" ")}
        ref="filter-item"
      >
        {alert && <div className={styles["field-name"]}>{placeholder}</div>}

        <div
          className={[
            styles.label,
            activeIndex === index ? styles["label--is-active"] : null,
            "dropdown",
          ].join(" ")}
          onClick={(e) => this.openFilterItem(index)}
        >
          {index !== 1 && (
            <React.Fragment>
              {values && values[index] && (
                <span
                  className={[
                    styles.value,
                    placeholders[index] ? styles["value--is-active"] : null,
                  ].join(" ")}
                >
                  {placeholders[index]
                    ? placeholders[index]
                    : alert
                    ? null
                    : placeholder}
                </span>
              )}
              <ChevronDown className={styles.icon} />
            </React.Fragment>
          )}
          {index === 1 && filterSubcategoryLoading === false ? (
            <React.Fragment>
              {values && values[index] && (
                <span
                  className={[
                    styles.value,
                    placeholders[index] ? styles["value--is-active"] : null,
                  ].join(" ")}
                >
                  {placeholders[index]
                    ? placeholders[index]
                    : alert
                    ? null
                    : placeholder}
                </span>
              )}
              <ChevronDown className={styles.icon} />
            </React.Fragment>
          ) : index === 1 && filterSubcategoryLoading === true ? (
            <Loading color="#0078e6" />
          ) : null}
        </div>
        <CSSTransition
          in={activeIndex === index}
          timeout={{
            enter: 0,
            exit: 200,
          }}
          classNames={{
            enterDone: styles["dropdown--is-active"],
          }}
          unmountOnExit
        >
          {(state) => (
            <ul className={styles.dropdown}>
              <SimpleBar
                className={styles.bar}
                style={{ height: `${height}px` }}
                data-simplebar-auto-hide="true"
              >
                {item &&
                  item.map((term, i) => {
                    return (
                      <li
                        key={i}
                        className={
                          values[index] &&
                          this.props.vacancyStore.isItemSelected(
                            index,
                            term.slug
                          )
                            ? styles["dropdown-item--is-active"]
                            : ""
                        }
                        onClick={(e) => {
                          if (
                            this.props.vacancyStore.isItemSelected(
                              index,
                              term.slug
                            )
                          ) {
                            this.removeValue(term.id, term.slug);
                          } else {
                            this.addValue(term.id, term.slug, term.name);
                          }
                        }}
                      >
                        <span className={styles.test}>
                          {term.name && term.name.replace(/\./g, "")}
                        </span>
                        <span className={styles.action}>
                          <Cross className={styles.remove} />
                          <CheckFilter className={styles.added} />
                        </span>
                      </li>
                    );
                  })}
              </SimpleBar>
            </ul>
          )}
        </CSSTransition>
      </div>
    );
  }
}

export default FilterItem;
