import React, { Component } from 'react';
import styles from './styles.scss';
// import Lottie from 'react-lottie';
// import * as testData from './data.json';
class Loading extends Component {
  render() {
    const { color } = this.props;
    return (
      <span className={styles.loading}>
        <svg className={styles.spinner} viewBox="0 0 50 50">
          <circle
            cx="25"
            cy="25"
            r="20"
            fill="none"
            strokeWidth="5"
            style={{ stroke: color }}
          />
        </svg>
      </span>
    );
  }
}

export default Loading;
