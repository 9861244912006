import React, { Component } from 'react';
import { CSSTransition } from 'react-transition-group';
import { withNamespaces } from "react-i18next";

import PhoneAndMail from 'components/phone-and-mail';
import Button from 'components/button';
import styles from './styles.scss';

//Icons
import ArrowRight from 'icons/arrow-right';

class ContactBox extends Component {
  render() {
    const { response, isLoading, t } = this.props;
    return (
      response && (
        <div className={styles.block}>
          <CSSTransition
            in={!isLoading}
            timeout={0}
            classNames={{
              enterDone: styles['leftblock-enter-done']
            }}
            unmountOnExit>
            {state => (
              <div className={styles.leftblock}>
                <h1>{response && response.title}</h1>
                <p dangerouslySetInnerHTML={{ __html: response.description }} />
                <h4>CONCREETO ROTTERDAM</h4>
                <p>
                  Hoogstraat 32 A <br />
                  3011 PR Rotterdam
                </p>
                <PhoneAndMail variants={['blue']} />
                <Button
                  type="external"
                  to="https://www.google.nl/maps/dir//Concreeto+Uitzendbureau,+Hoogstraat+32+%2FA,+3011+PR+Rotterdam/@51.922952,4.4921313,17z/data=!4m9!4m8!1m0!1m5!1m1!1s0x47c43345a9f1fa5d:0x74c6b5b12d1d3195!2m2!1d4.49432!2d51.922952!3e0"
                  className={['large', 'white']}>
                  {t('Directions')} <ArrowRight />
                </Button>
              </div>
            )}
          </CSSTransition>
        </div>
      )
    );
  }
}

export default withNamespaces()(ContactBox);
