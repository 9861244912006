import React, { Component } from "react";
import ModalVideo from "react-modal-video";
import Playvideo from "icons/video-play";
import ReactDOM from "react-dom";
import { withNamespaces } from "react-i18next";

// styles
import styles from "./styles.scss";

class VideoModal extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
  }
  openModal = () => {
    let body = document.body;
    body.classList.add("no-scroll");
    this.setState({ isOpen: true });
  };
  closeModal = () => {
    let body = document.body;
    body.classList.remove("no-scroll");
    this.setState({ isOpen: false });
  };
  render() {
    const { video, t } = this.props;
    const domRoot = document.getElementById("concreeto");
    return (
      <>
        <div onClick={() => this.openModal()} className={styles.wrapper}>
          <p className={styles.text}>{t('Watch video')}</p>
          <span className={styles.dash}></span>
          <Playvideo />
        </div>
        {ReactDOM.createPortal(
          <ModalVideo
            channel="youtube"
            isOpen={this.state.isOpen}
            videoId={video.videoId}
            onClose={() => this.closeModal()}
          />,
          domRoot
        )}
      </>
    );
  }
}

export default withNamespaces()(VideoModal);
