import React from 'react';

const Upload = (props) => (
    <svg {...props} width="80px" height="80px" viewBox="0 0 80 80">
        <g id="Ontwerpen" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="00---Vacature-uploaden-pop-up-02" transform="translate(-857.000000, -619.000000)">
                <g id="Group-12" transform="translate(673.000000, 541.000000)">
                    <g id="Group-10" transform="translate(107.000000, 78.000000)">
                        <g id="Group-13" transform="translate(77.000000, 0.000000)">
                            <g id="Group-9">
                                <rect id="Rectangle-5" fill={props.fill1} x="0" y="0" width="80" height="80"></rect>
                                <g id="np_upload_92013_000000" transform="translate(18.000000, 18.000000)"></g>
                            </g>
                            <path d="M40.5,18 C28.0995,18 18,28.0995 18,40.5 C18,52.9005 28.0995,63 40.5,63 C52.9005,63 63,52.9005 63,40.5 C63,28.0995 52.9005,18 40.5,18 Z M40.5,58 C30.8495,58 23,50.1505 23,40.5 C23,30.8495 30.8495,23 40.5,23 C50.1505,23 58,30.8495 58,40.5 C58,50.1505 50.1505,58 40.5,58 Z"  fill={props.fill2} fillRule="nonzero"></path>
                            <polygon  fill={props.fill2} fillRule="nonzero" points="31.1505 41.449 38 41.449 38 51.449 43 51.449 43 41.449 49.9005 41.449 40.5 29.5505"></polygon>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export default Upload;