import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import Notch from "components/notch";
import styles from "./styles.scss";
import SimpleContent from "components/simple-content";

// decorators
@inject("goStore")
@observer
class GoAbout extends Component {
  render() {
    const { details } = this.props;
    return (
      <div className={styles.textblock}>
        <Notch variants={["right", "half"]} />
        <div className="wrapper">
          <SimpleContent details={details.default} buttons={details.buttons} />
        </div>
      </div>
    );
  }
}

export default GoAbout;
