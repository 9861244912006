import React, { Component } from "react";
import { observer } from "mobx-react";

// styles
import styles from "./styles.scss";

import PhoneBlock from "icons/phone-block-vacancy";
import MailBlock from "icons/mail-block-vacancy";

// decorators
@observer
class ArticleAuthor extends Component {
  render() {
    const { detail } = this.props;

    // detail.categories gebruiken om door te geven aan de modal
    // De titel moet er ook nog bij, iets generieks voor verzinnen zodat dat altijd hetzelfde is
    // iets van {ids: {area: 1, sector:2}, 'label': {area: 'Rotterdam', sector: 'Logistiek'}, title: 'Titel', employment: 'fulltime'}
    return (
      <React.Fragment>
        <React.Fragment>
          <figure className={styles.image}>
            {detail.author ? (
              <img src={detail.author.avatar} alt={detail.author.name} />
            ) : (
              <img src="" alt="" />
            )}
          </figure>
          {detail.author ? (
            <h4 className={styles.title}>{detail.author.name}</h4>
          ) : (
            <h4 className={styles.title}>Concreeto</h4>
          )}

          <ul className={styles.contact}>
            {detail.author ? (
              <React.Fragment>
                <li>
                  <a href={`tel:${detail.author.phonenumber}`}>
                    <PhoneBlock />
                    <span>{detail.author.phonenumber}</span>
                  </a>
                </li>
                <li>
                  <a
                    className={styles.mail}
                    href={`mailto:${detail.author.email}`}
                  >
                    <MailBlock />
                    <span>{detail.author.email}</span>
                  </a>
                </li>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <li>
                  <a href={`tel:0107200720`}>
                    <PhoneBlock />
                    <span>010 – 7200720</span>
                  </a>
                </li>
                <li>
                  <a className={styles.mail} href={`mailto:info@concreeto.nl`}>
                    <MailBlock />
                    <span>info@concreeto.nl</span>
                  </a>
                </li>
              </React.Fragment>
            )}
          </ul>
        </React.Fragment>
      </React.Fragment>
    );
  }
}

export default ArticleAuthor;
