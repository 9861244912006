import React from 'react';

const Question = props => (
  <svg
    {...props}
    width="110px"
    height="104px"
    viewBox="0 0 110 104"
    version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g fill="#FFFFFF">
        <g>
          <path d="M51.876,28.524 L83.585,28.524 L83.585,7.257 L51.876,7.257 L51.876,28.524 Z M13.416,28.524 L45.124,28.524 L45.124,7.257 L13.417,7.257 L13.417,28.524 L13.416,28.524 Z M90.248,72.209 L90.248,87.734 L83.312,87.734 C81.837,82.542 77.067,78.726 71.413,78.726 C65.759,78.726 60.99,82.542 59.515,87.734 L37.485,87.734 C36.01,82.542 31.241,78.726 25.587,78.726 C19.933,78.726 15.162,82.542 13.688,87.734 L6.752,87.734 L6.752,72.21 L90.248,72.21 L90.248,72.209 Z M71.413,96.743 C68.3059511,96.7385901 65.7887538,94.2200508 65.786,91.113 C65.7887538,88.0059492 68.3059511,85.4874099 71.413,85.483 C74.5200489,85.4874099 77.0372462,88.0059492 77.04,91.113 C77.0372462,94.2200508 74.5200489,96.7385901 71.413,96.743 L71.413,96.743 Z M25.587,96.743 C22.4799511,96.7385901 19.9627538,94.2200508 19.96,91.113 C19.9627538,88.0059492 22.4799511,85.4874099 25.587,85.483 C28.6940489,85.4874099 31.2112462,88.0059492 31.214,91.113 C31.2112462,94.2200508 28.6940489,96.7385901 25.587,96.743 L25.587,96.743 Z M84.247,35.28 L97,35.28 L97,28.524 L90.337,28.524 L90.337,0.5 L6.664,0.5 L6.664,28.524 L0,28.524 L0,35.28 L12.754,35.28 L12.754,46.161 L77.494,60.035 L77.494,65.453 L19.506,65.453 L19.506,60.035 L36.856,56.316 L20.733,52.861 L12.753,54.571 L12.753,65.453 L0,65.453 L0,94.49 L13.688,94.49 C15.162,99.682 19.933,103.499 25.587,103.499 C31.241,103.499 36.01,99.682 37.485,94.49 L59.515,94.49 C60.99,99.682 65.759,103.499 71.413,103.499 C77.067,103.499 81.837,99.682 83.312,94.49 L97,94.49 L97,65.453 L84.246,65.453 L84.246,54.57 L19.506,40.697 L19.506,35.28 L77.494,35.28 L77.494,40.698 L60.144,44.415 L76.267,47.87 L84.247,46.161 L84.247,35.281 L84.247,35.28 Z"></path>
        </g>
      </g>
    </g>
  </svg>
);
export default Question;
