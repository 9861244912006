import React from 'react';

const Money = props => (
  <svg
    {...props}
    width="434px"
    height="234px"
    viewBox="0 0 434 234"
    version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g>
        <g id="money" transform="translate(0.000000, 37.000000)">
          <path d="M182.66,131.21 L389.44,131.1 C390.862182,124.002482 396.397579,118.447387 403.49,117 L403.49,43.72 C396.418204,42.3019455 390.883257,36.7866953 389.44,29.72 L182.66,29.83 C181.207754,36.8993875 175.673496,42.4179005 168.6,43.85 L168.6,117.15 C175.684879,118.587967 181.222033,124.125121 182.66,131.21 Z M286.05,42.07 C307.239863,42.0755225 324.413681,59.2568013 324.409999,80.4466649 C324.406318,101.636529 307.226531,118.811839 286.036668,118.809999 C264.846804,118.808158 247.670001,101.629864 247.67,80.44 C247.697557,59.2563557 264.866349,42.0920369 286.05,42.07 Z" />
          <path d="M286.05,111.81 C303.37387,111.804478 317.413681,97.7572057 317.409999,80.4333355 C317.406318,63.1094652 303.360538,49.0681615 286.036668,49.0700018 C268.712797,49.071842 254.670001,63.1161293 254.67,80.44 C254.692049,97.7599351 268.730059,111.793472 286.05,111.81 L286.05,111.81 Z M268.31,82 L271.83,82 C271.798067,81.7345073 271.781373,81.4674028 271.78,81.2 L271.78,79.51 C271.781705,79.2326347 271.798396,78.9555642 271.83,78.68 L268.31,78.68 L268.31,74.16 L272.56,74.16 C274.56,66.98 280.18,62.61 287.8,62.61 C291.988657,62.5227689 296.019525,64.2077443 298.9,67.25 L295.13,72.08 C293.311797,69.8654466 290.595323,68.5842987 287.73,68.59 C284.104758,68.5465347 280.837968,70.7709875 279.55,74.16 L292,74.16 L292,78.68 L278.47,78.68 C278.42,79.22 278.4,79.76 278.4,80.32 C278.4,80.88 278.4,81.44 278.47,81.95 L292,81.95 L292,86.47 L279.57,86.47 C280.873446,89.8413622 284.135741,92.0468013 287.75,92 C290.619082,92.0041067 293.341532,90.7326529 295.18,88.53 L298.8,93.27 C295.987224,96.3583397 291.976309,98.0793868 287.8,97.99 C280.18,97.99 274.59,93.62 272.56,86.43 L268.31,86.43 L268.31,82 Z" />
          <path d="M145.92,153.88 L426.17,153.88 L426.17,7 L145.92,7 L145.92,153.88 Z M161.6,37.19 L165.1,37.19 C171.119236,37.1789783 175.994498,32.2992435 176,26.28 L176,22.78 L396.08,22.67 L396.08,26.17 C396.082651,29.0635123 397.234637,31.8374606 399.282535,33.8816074 C401.330432,35.9257542 404.106488,37.0726534 407,37.07 L410.5,37.07 L410.5,123.69 L407,123.69 C400.978479,123.695519 396.099997,128.578476 396.1,134.6 L396.1,138.09 L176,138.21 L176,134.71 C175.988978,128.690764 171.109243,123.815502 165.09,123.81 L161.59,123.81 L161.6,37.19 Z" />
          <path
            d="M287.8,98 C291.976309,98.0893868 295.987224,96.3683397 298.8,93.28 L295.18,88.54 C293.339614,90.7389221 290.617444,92.0065815 287.75,92 C284.125645,92.0405279 280.860376,89.8171113 279.57,86.43 L292,86.43 L292,82 L278.47,82 C278.42,81.49 278.4,81 278.4,80.37 C278.4,79.74 278.4,79.27 278.47,78.73 L292,78.73 L292,74.16 L279.55,74.16 C280.837968,70.7709875 284.104758,68.5465347 287.73,68.59 C290.595323,68.5842987 293.311797,69.8654466 295.13,72.08 L298.9,67.25 C296.019525,64.2077443 291.988657,62.5227689 287.8,62.61 C280.18,62.61 274.59,66.98 272.56,74.16 L268.31,74.16 L268.31,78.68 L271.83,78.68 C271.798396,78.9555642 271.781705,79.2326347 271.78,79.51 L271.78,81.15 C271.781373,81.4174028 271.798067,81.6845073 271.83,81.95 L268.31,81.95 L268.31,86.47 L272.56,86.47 C274.59,93.66 280.18,98 287.8,98 Z"
            fill="#FFFFFF"
            fillRule="nonzero"
          />
          <path
            d="M138.92,0 L138.92,160.88 L433.17,160.88 L433.17,0 L138.92,0 Z M426.17,153.88 L145.92,153.88 L145.92,7 L426.17,7 L426.17,153.88 Z"
            fill="#FFFFFF"
            fillRule="nonzero"
          />
          <path
            d="M176,134.71 L176,138.21 L396.08,138.09 L396.08,134.6 C396.079999,131.704755 397.230815,128.928237 399.279,126.881927 C401.327185,124.835618 404.104756,123.687346 407,123.69 L410.5,123.69 L410.5,37.07 L407,37.07 C400.982381,37.0644868 396.105513,32.1876187 396.1,26.17 L396.1,22.67 L176,22.78 L176,26.28 C175.994498,32.2992435 171.119236,37.1789783 165.1,37.19 L161.6,37.19 L161.6,123.81 L165.1,123.81 C171.115339,123.821007 175.988993,128.694661 176,134.71 Z M168.6,43.85 C175.68773,42.4105706 181.22561,36.8687515 182.66,29.78 L389.44,29.67 C390.883257,36.7366953 396.418204,42.2519455 403.49,43.67 L403.49,117 C396.406143,118.443004 390.872926,123.984098 389.44,131.07 L182.66,131.18 C181.222033,124.095121 175.684879,118.557967 168.6,117.12 L168.6,43.85 Z"
            fill="#FFFFFF"
            fillRule="nonzero"
          />
          <path
            d="M286.05,118.81 C307.239863,118.804477 324.413681,101.623199 324.409999,80.4333351 C324.406318,59.2434714 307.226531,42.0681611 286.036668,42.0700014 C264.846804,42.0718418 247.670001,59.2501361 247.67,80.44 C247.697557,101.623644 264.866349,118.787963 286.05,118.81 Z M286.05,49.07 C303.37387,49.0755224 317.413681,63.1227943 317.409999,80.4466645 C317.406318,97.7705348 303.360538,111.811838 286.036668,111.809998 C268.712797,111.808158 254.670001,97.7638707 254.67,80.44 C254.692049,63.1200649 268.730059,49.0865284 286.05,49.07 L286.05,49.07 Z"
            fill="#FFFFFF"
            fillRule="nonzero"
          />
          <rect
            id="Rectangle-path"
            fill="#FFFFFF"
            fillRule="nonzero"
            x="24.61"
            y="130.36"
            width="91.18"
            height="7"
          />
          <rect
            id="Rectangle-path"
            fill="#FFFFFF"
            fillRule="nonzero"
            x="55.53"
            y="94.75"
            width="35.08"
            height="7"
          />
          <rect
            id="Rectangle-path"
            fill="#FFFFFF"
            fillRule="nonzero"
            x="28.92"
            y="23.52"
            width="64.31"
            height="7"
          />
          <polygon
            fill="#FFFFFF"
            fillRule="nonzero"
            points="0.01 66.13 7.01 66.13 7.01 59.13 0 59.13"
          />
          <polygon
            fill="#FFFFFF"
            fillRule="nonzero"
            points="79.54 59.13 72.52 59.13 72.54 66.13 79.54 66.13"
          />
          <polygon
            fill="#FFFFFF"
            fillRule="nonzero"
            points="90.67 66.13 97.67 66.13 97.67 59.13 90.65 59.13"
          />
          <polygon
            fill="#FFFFFF"
            fillRule="nonzero"
            points="43.27 66.13 43.27 59.13 36.25 59.13 36.27 66.13"
          />
          <polygon
            fill="#FFFFFF"
            fillRule="nonzero"
            points="25.14 59.13 18.12 59.13 18.14 66.13 25.14 66.13"
          />
          <polygon
            fill="#FFFFFF"
            fillRule="nonzero"
            points="61.41 59.13 54.38 59.13 54.41 66.13 61.41 66.13"
          />
          <polygon
            fill="#FFFFFF"
            fillRule="nonzero"
            points="108.79 66.13 115.79 66.13 115.79 59.13 108.78 59.13"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default Money;
