import React, { Component } from "react";
// components
import Button from "components/button";
import VideoOverlay from "components/video-overlay";
// styles
import styles from "./styles.scss";
//icons
import ArrowRight from "icons/arrow-right";
import Play from "icons/play";

class VideoGridItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  openModal = () => {
    this.setState({
      open: true,
    });
  };

  closeModal = () => {
    this.setState({
      open: false,
    });
  };
  render() {
    const { open } = this.state;
    const { t } = this.props;
    const {
      image,
      title,
      description,
      button,
      linkedin,
      videoId,
    } = this.props.item;
    return (
      <div className={styles.item}>
        <div className={styles.image} onClick={this.openModal}>
          <figure
            className={styles.figure}
            style={{
              backgroundImage: `url(${image})`,
            }}
          />
          <span className={styles.play}>
            <span className={styles.icon}>
              <Play />
            </span>
            <span className={styles.caption}>{t('Watch video')}</span>
          </span>
        </div>
        <div className={styles.content}>
          <h3
            dangerouslySetInnerHTML={{ __html: title }}
            className={styles.title}
          ></h3>
          <p
            dangerouslySetInnerHTML={{ __html: description }}
            className={styles.description}
          />
          <div className={styles["button-cta"]}>
            <Button to={button.link} className={["large", "blue"]}>
              {button.text} <ArrowRight />
            </Button>
            {linkedin && (
              <Button
                type="external"
                to={`https://www.linkedin.com/shareArticle?mini=true&url=https://www.youtube.com/watch?v=${videoId}`}
                className={["large", "transparent"]}
              >
                {t('LinkedIn Share')} <ArrowRight />
              </Button>
            )}
          </div>
        </div>
        {open && (
          <VideoOverlay close={this.closeModal} key="modal" video={videoId} />
        )}
      </div>
    );
  }
}

export default VideoGridItem;
