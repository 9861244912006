import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';

//components
import PopupStory from 'components/popup-story';

//styles
import styles from './styles.scss';

//icons
import Cross from 'icons/cross';

//decorators
@inject('popupStore')
@observer
class CvOverlay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keys: [32, 33, 34, 35, 36, 37, 38, 39, 40],
      mount: false,
      width: null
    };
  }

  getWidth = () => {
    this.setState({
      width: window.outerWidth
    });
    // if (this.state.width > 768) {
    //   window.onmousewheel = document.onmousewheel = this.wheel;
    // }
  };

  componentDidMount() {
    this.setState({
      mount: true
    });
    this.getWidth();
    window.addEventListener('resize', this.getWidth);
    this.props.popupStore.getStory();
  }

  componentWillUnmount() {
    window.onmousewheel = document.onmousewheel = document.onkeydown = null;
  }

  preventDefault = e => {
    e = e || window.event;
    if (e.preventDefault) e.preventDefault();
    e.returnValue = false;
  };

  keydown = e => {
    for (var i = this.state.keys.length; i--; ) {
      if (e.keyCode === this.state.keys[i]) {
        e.preventDefault();
        return;
      }
    }
  };

  wheel = e => {
    e.preventDefault();
  };

  render() {
    const { close, children } = this.props;
    const { closeColor } = this.props.popupStore;
    const { mount, width } = this.state;

    return ReactDOM.createPortal(
      <CSSTransition
        in={mount}
        timeout={600}
        classNames={{
          enterDone: styles['popup-enter-done']
        }}
        unmountOnExit>
        {state => (
          <div className={styles.popup}>
            <div className={styles.popupcontainer}>
              {width > 1024 && <PopupStory />}

              <CSSTransition
                in={state === 'entered'}
                timeout={400}
                classNames={{
                  enterDone: styles['popup-content-enter-done']
                }}
                unmountOnExit>
                {state => (
                  <div className={styles['popup-content']}>
                    <CSSTransition
                      in={state === 'entered'}
                      timeout={400}
                      classNames={{
                        enterDone: styles['closebtn-enter-done']
                      }}
                      unmountOnExit>
                      <div
                        className={styles.closebtn}
                        onClick={close}
                        style={{ backgroundColor: closeColor }}>
                        <Cross />
                      </div>
                    </CSSTransition>
                    {children}
                  </div>
                )}
              </CSSTransition>
            </div>
            <span className={styles.background} />
          </div>
        )}
      </CSSTransition>,
      document.getElementById('concreeto')
    );
  }
}
export default CvOverlay;
