import { observable, action } from 'mobx';
import request from 'superagent';

export class defaultPageStore {
  @observable isLoading = false;
  @observable response = null;

  @action getPageDetails(slug) {
    this.isLoading = true;
    this.response = null;
    return request
      .get(`${process.env.REACT_APP_API_URL}/pages/v1/detail`)
      .query({ page: slug })
      .then(response => {
        this.response = response.body;
      })
      .then(() => {
        this.isLoading = false;
      })
      .catch(error => {
        this.response = false;
        this.isLoading = false;
      });
  }
}

export default new defaultPageStore();
