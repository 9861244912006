import React from 'react';

const FlagEnglish = props => (
  <svg
    {...props}
    width="30"
    height="23"
    viewBox="0 0 30 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="29" height="22" stroke="white"/>
    <path d="M28.9998 1H1V21.8882H28.9998V1Z" fill="#104295"/>
    <path d="M15.852 12.4887L15.7303 12.6048L14.7564 13.3011L14.2694 13.6492L13.0521 14.5776L12.8086 14.6936L11.5912 15.622L2.94781 21.8884H1V21.1922L9.76514 14.6936L11.5912 13.4171L11.7129 13.3011L12.6869 12.6048L13.0521 12.3727L13.539 12.0246L14.7564 11.0962L14.9999 11.4443L15.2434 11.7925L15.852 12.4887Z" fill="#FD5858"/>
    <path d="M14.7564 11.0962L13.539 12.0245L13.0521 12.3727L12.6869 12.6048L11.7129 13.301L11.5912 13.4171L9.76514 14.6936L1 21.1921V18.8712L6.72169 14.6936L8.6695 13.301L11.226 11.4443L11.5912 11.2122L11.9564 10.8641L13.0521 10.1678L13.4173 9.81966L13.539 9.70361L13.9042 10.0518L14.1477 10.3999L14.7564 11.0962Z" fill="white"/>
    <path d="M16.3392 13.185L16.2174 13.3011L15.0001 14.2295L14.2696 14.6936L13.0523 15.622L11.5914 16.7825L4.53059 21.8885H2.948L11.5914 15.622L12.8088 14.6936L13.0523 14.5776L14.2696 13.6492L14.7566 13.3011L15.7305 12.6048L15.8522 12.4888L16.0957 12.7209V12.8369L16.3392 13.185Z" fill="white"/>
    <path d="M28.9997 1V1.81232L20.2345 8.19483L18.4085 9.47133L18.2867 9.58738L17.3128 10.2837L16.9476 10.5157L16.4607 10.8639L15.2433 11.7922L14.9998 11.4441L14.7563 11.096L14.1476 10.3997L14.2694 10.2837L15.2433 9.58738L15.7302 9.23924L16.9476 8.31088L17.1911 8.19483L18.4085 7.26647L26.9301 1H28.9997Z" fill="#FD5858"/>
    <path d="M28.9997 1.8125V4.01737L23.278 8.19501L21.3302 9.58756L18.7737 11.4443L18.4085 11.6764L18.0433 12.0245L16.9476 12.8368L16.5824 13.0689L16.3389 13.185L16.0955 12.8368V12.7208L15.852 12.4887L15.2433 11.7924L16.4607 10.8641L16.9476 10.5159L17.3128 10.2838L18.2867 9.58756L18.4085 9.47152L20.2345 8.19501L28.9997 1.8125Z" fill="white"/>
    <path d="M26.9301 1L18.4085 7.26647L17.1911 8.19483L16.9476 8.31088L15.7302 9.23924L15.2433 9.58738L14.2694 10.2837L14.1476 10.3997L13.9042 10.1676V10.0516L13.5389 9.70343L13.7824 9.58738L14.9998 8.65902L15.7302 8.19483L16.9476 7.26647L18.4085 6.10601L25.4693 1H26.9301Z" fill="white"/>
    <path d="M28.9999 20.0317V21.8884H28.5129L18.7739 14.6936L18.4087 14.5775L16.9478 13.4171L16.8261 13.301L16.5826 13.069L16.0956 12.8369L15.7304 12.6048L14.7565 11.7925L15 11.4443L15.2435 11.0962L15.8522 10.3999L16.4609 10.8641L16.9478 11.2122L18.0435 12.0245L18.4087 12.3727L19.8695 13.301L21.8173 14.6936L28.9999 20.0317Z" fill="#FD5858"/>
    <path d="M28.5128 21.888H25.4693L18.4085 16.782L16.9477 15.6215L15.7303 14.6931L14.9999 14.229L14.2694 13.6487L13.7825 13.3006L13.539 13.1845L14.1477 12.4883L14.7564 11.792L15.7303 12.6043L16.0955 12.8364L16.5825 13.0685L16.8259 13.3006L16.9477 13.4166L18.4085 14.5771L18.7737 14.6931L28.5128 21.888Z" fill="white"/>
    <path d="M29 18.8712V20.0317L21.8175 14.6936L19.8697 13.301L18.4088 12.3727L18.0436 12.0245L16.9479 11.2122L16.461 10.8641L15.8523 10.3999L16.3392 9.70361L16.9479 10.1678L17.3132 10.2838L18.4088 11.2122L18.774 11.4443L21.3305 13.301L23.2783 14.6936L29 18.8712Z" fill="white"/>
    <path d="M15.2434 11.096L14.9999 11.4441L14.7564 11.7922L14.1477 12.4885L13.539 12.0243L13.0521 11.6762L11.9564 10.8639L11.5912 10.5157L10.1304 9.58738L8.18255 8.19483L1 2.85673V1H1.48695L11.226 8.19483L11.5912 8.31088L13.0521 9.47134L13.1738 9.58738L13.4173 9.81947L13.9042 10.0516L14.2694 10.2837L15.2434 11.096Z" fill="#FD5858"/>
    <path d="M16.339 9.70343L15.852 10.3997L15.2433 11.096L14.2694 10.2837L13.9042 10.0516L13.4173 9.81947L13.1738 9.58738L13.0521 9.47134L11.5912 8.31088L11.226 8.19483L1.48694 1H4.53039L11.5912 6.10601L13.0521 7.26647L14.2694 8.19483L14.9999 8.65902L15.7303 9.23924L16.2172 9.58738L16.339 9.70343Z" fill="white"/>
    <path d="M14.1477 12.4882L13.539 13.1845L13.0521 12.8364L12.6869 12.6043L11.5912 11.6759L11.226 11.4438L8.6695 9.5871L6.72169 8.19455L1 4.0169V2.85645L8.18255 8.19455L10.1304 9.5871L11.5912 10.5155L11.9564 10.8636L13.0521 11.6759L13.539 12.0241L14.1477 12.4882Z" fill="white"/>
    <path d="M18.531 1H11.5912V21.8882H18.531V1Z" fill="white"/>
    <path d="M28.9998 8.19482H1V14.8094H28.9998V8.19482Z" fill="white"/>
    <path d="M16.9481 1H13.0521V21.8882H16.9481V1Z" fill="#FD5858"/>
    <path d="M16.9481 1H13.0521V21.8882H16.9481V1Z" fill="#FD5858"/>
    <path d="M28.9998 9.5874H1V13.3009H28.9998V9.5874Z" fill="#FD5858"/>
  </svg>
);

export default FlagEnglish;
