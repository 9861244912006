import React from 'react';

const Compass = props => (
  <svg
    {...props}
    width="110px"
    height="104px"
    viewBox="0 0 110 104"
    version="1.1">
    <defs>
      <polygon
        id="path-1"
        points="0 0.132 101.867 0.132 101.867 102 0 102"></polygon>
      <polygon
        id="path-3"
        points="0 0.132 101.867 0.132 101.867 102 0 102"></polygon>
      <polygon
        id="path-5"
        points="0 102.368 101.868 102.368 101.868 0.5 0 0.5"></polygon>
    </defs>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g>
        <g>
          <g transform="translate(0.000000, 0.368000)">
            <g>
              <mask id="mask-2" fill="white">
                <use xlinkHref="#path-1"></use>
              </mask>
              <g id="a"></g>
              <path
                d="M50.933,6.882 C26.57,6.882 6.75,26.702 6.75,51.066 C6.75,75.429 26.571,95.25 50.934,95.25 C75.297,95.25 95.118,75.429 95.118,51.066 C95.118,26.703 75.298,6.882 50.934,6.882 M50.934,102 C22.85,102 0,79.152 0,51.066 C0,22.98 22.85,0.132 50.933,0.132 C79.019,0.132 101.867,22.98 101.867,51.066 C101.867,79.152 79.02,102 50.933,102"
                fill="#FFFFFF"
                fillRule="nonzero"
                mask="url(#mask-2)"></path>
            </g>
          </g>
          <polyline
            fill="#FFFFFF"
            fillRule="nonzero"
            points="60.793 44.243 66.942 73.387 41.074 58.624"></polyline>
          <path
            d="M66.941,76.761 C66.3544969,76.7612398 65.7781161,76.6081817 65.269,76.317 L39.4,61.554 L42.745,55.692 L62.09,66.731 L57.49,44.94 L64.095,43.544 L70.243,72.688 C70.4519699,73.6834479 70.2012669,74.7200653 69.5605054,75.5100081 C68.9197439,76.2999508 67.9571451,76.7591144 66.94,76.76"
            fill="#FFFFFF"
            fillRule="nonzero"></path>
          <path
            d="M37.771,59.32 L31.624,30.18 C31.3502379,28.8795189 31.8646863,27.539388 32.9383437,26.7561532 C34.0120011,25.9729185 35.4452132,25.8922278 36.6,26.55 L62.466,41.312 L59.121,47.174 L39.78,36.137 L44.376,57.926 L37.771,59.321 L37.771,59.32 Z"
            fill="#FFFFFF"
            fillRule="nonzero"></path>
          <path
            d="M44.063,56.444 L62.09,66.729 L57.804,46.422 L39.78,36.137 L44.064,56.444 L44.063,56.444 Z M71.793,80.042 L38.084,60.802 L30.073,22.827 L63.783,42.063 L71.793,80.042 Z"
            fill="#FFFFFF"
            fillRule="nonzero"></path>
          <polyline
            fill="#FFFFFF"
            fillRule="nonzero"
            points="60.793 44.243 66.942 73.387 41.074 58.624"></polyline>
          <path
            d="M66.941,76.761 C66.3544969,76.7612398 65.7781161,76.6081817 65.269,76.317 L39.4,61.554 L42.745,55.692 L62.09,66.731 L57.49,44.94 L64.095,43.544 L70.243,72.688 C70.4519699,73.6834479 70.2012669,74.7200653 69.5605054,75.5100081 C68.9197439,76.2999508 67.9571451,76.7591144 66.94,76.76"
            fill="#FFFFFF"
            fillRule="nonzero"></path>
          <path
            d="M44.063,56.444 L62.09,66.729 L57.804,46.422 L39.78,36.137 L44.064,56.444 L44.063,56.444 Z M71.793,80.042 L38.084,60.802 L30.073,22.827 L63.783,42.063 L71.793,80.042 Z"
            fill="#FFFFFF"
            fillRule="nonzero"></path>
          <g transform="translate(0.000000, 0.368000)">
            <g>
              <mask id="mask-4" fill="white">
                <use xlinkHref="#path-3"></use>
              </mask>
              <g id="c"></g>
              <path
                d="M50.933,6.882 C26.57,6.882 6.75,26.702 6.75,51.066 C6.75,75.429 26.571,95.25 50.934,95.25 C75.297,95.25 95.118,75.429 95.118,51.066 C95.118,26.703 75.298,6.882 50.934,6.882 M50.934,102 C22.85,102 0,79.152 0,51.066 C0,22.98 22.85,0.132 50.933,0.132 C79.019,0.132 101.867,22.98 101.867,51.066 C101.867,79.152 79.02,102 50.933,102"
                fill="#FFFFFF"
                fillRule="nonzero"
                mask="url(#mask-4)"></path>
            </g>
          </g>
          <polyline
            fill="#FFFFFF"
            fillRule="nonzero"
            points="60.793 44.243 66.942 73.387 41.074 58.624"></polyline>
          <path
            d="M66.941,76.761 C66.3544969,76.7612398 65.7781161,76.6081817 65.269,76.317 L39.4,61.554 L42.745,55.692 L62.09,66.731 L57.49,44.94 L64.095,43.544 L70.243,72.688 C70.4519699,73.6834479 70.2012669,74.7200653 69.5605054,75.5100081 C68.9197439,76.2999508 67.9571451,76.7591144 66.94,76.76"
            fill="#FFFFFF"
            fillRule="nonzero"></path>
          <path
            d="M37.771,59.32 L31.624,30.18 C31.3502379,28.8795189 31.8646863,27.539388 32.9383437,26.7561532 C34.0120011,25.9729185 35.4452132,25.8922278 36.6,26.55 L62.466,41.312 L59.121,47.174 L39.78,36.137 L44.376,57.926 L37.771,59.321 L37.771,59.32 Z"
            fill="#FFFFFF"
            fillRule="nonzero"></path>
          <path
            d="M44.063,56.444 L62.09,66.729 L57.804,46.422 L39.78,36.137 L44.064,56.444 L44.063,56.444 Z M71.793,80.042 L38.084,60.802 L30.073,22.827 L63.783,42.063 L71.793,80.042 Z"
            fill="#FFFFFF"
            fillRule="nonzero"></path>
          <polyline
            fill="#FFFFFF"
            fillRule="nonzero"
            points="60.793 44.243 66.942 73.387 41.074 58.624"></polyline>
          <path
            d="M66.941,76.761 C66.3544969,76.7612398 65.7781161,76.6081817 65.269,76.317 L39.4,61.554 L42.745,55.692 L62.09,66.731 L57.49,44.94 L64.095,43.544 L70.243,72.688 C70.4519699,73.6834479 70.2012669,74.7200653 69.5605054,75.5100081 C68.9197439,76.2999508 67.9571451,76.7591144 66.94,76.76"
            fill="#FFFFFF"
            fillRule="nonzero"></path>
          <path
            d="M44.063,56.444 L62.09,66.729 L57.804,46.422 L39.78,36.137 L44.064,56.444 L44.063,56.444 Z M71.793,80.042 L38.084,60.802 L30.073,22.827 L63.783,42.063 L71.793,80.042 Z"
            fill="#FFFFFF"
            fillRule="nonzero"></path>
          <g>
            <mask id="mask-6" fill="white">
              <use xlinkHref="#path-5"></use>
            </mask>
            <g id="e"></g>
            <path
              d="M47.559,21.157 L54.309,21.157 L54.309,12.907 L47.559,12.907 L47.559,21.157 Z M81.21,54.81 L89.46,54.81 L89.46,48.06 L81.21,48.06 L81.21,54.81 Z M47.559,89.962 L54.309,89.962 L54.309,81.712 L47.559,81.712 L47.559,89.962 Z M12.406,54.81 L20.656,54.81 L20.656,48.06 L12.406,48.06 L12.406,54.81 Z"
              fill="#FFFFFF"
              fillRule="nonzero"
              mask="url(#mask-6)"></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export default Compass;
