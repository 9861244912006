import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import styles from './styles.scss';

// decorators
@inject('goStore')
@observer
class Partner extends Component {
  render() {
    const { name, company } = this.props;
    return (
      <div class={styles.partner}>
        <h4 dangerouslySetInnerHTML={{ __html: name }} />
        <span dangerouslySetInnerHTML={{ __html: company }} />
      </div>
    );
  }
}

export default Partner;
