import React, { Component } from 'react';

import styles from './styles.scss';

//Icons
import PhoneBlock from 'icons/phone-block';
import MailBlock from 'icons/mail-block';

class PhoneAndMail extends Component {
  classNames(variants) {
    let classes = [];
    if (variants) {
      variants.forEach(function(variant) {
        classes.push(styles[`svg--${variant}`]);
      });
    }
    return classes.join(' ');
  }
  render() {
    const { variants } = this.props;
    return (
      <div className={[styles.contact, 'contact'].join(' ')}>
        <a href="tel:0107200720">
          <PhoneBlock className={this.classNames(variants)} />
          010 – 7200720
        </a>
        <a href="mailto:info@concreeto.nl">
          <MailBlock className={this.classNames(variants)} />
          info@concreeto.nl
        </a>
      </div>
    );
  }
}
export default PhoneAndMail;
