import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { withNamespaces } from 'react-i18next';

//styles
import styles from './styles.scss';

//icons
import ArrowRight from 'icons/arrow-right';
import HeartFeatured from 'icons/heart-featured';

class VacancyGridItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mount: false,
        };
    }
    componentDidMount() {
        this.setState({
            mount: true,
        });
    }

    render() {
        const { delay, item, t } = this.props;
        console.log(typeof item.featured);
        const { mount } = this.state;
        return (
            item && (
                <CSSTransition
                    in={mount}
                    timeout={delay}
                    classNames={{
                        enterDone: styles['item-enter-done'],
                    }}
                    unmountOnExit>
                    {(state) => (
                        <Link to={item.slug} className={styles.item}>
                            {item.featured !== '0' && <HeartFeatured className={styles.HeartFeatured} />}
                            {item.featuredText && <span className={styles.featured}>{item.featuredText}</span>}
                            <h4 className={styles.title} dangerouslySetInnerHTML={{ __html: item.title }} />
                            <span className={styles.subtitle}>
                                {item.sector && item.sector.replace(/\./g, '')} {item.sector && item.area ? `/ ${item.area}` : item.area}
                            </span>
                            <div className={styles.description}>
                                <p dangerouslySetInnerHTML={{ __html: item.excerpt }} />
                                <span className={styles.link}>
                                    {t('View job offer')}
                                    <ArrowRight />
                                </span>
                            </div>
                        </Link>
                    )}
                </CSSTransition>
            )
        );
    }
}

export default withNamespaces()(VacancyGridItem);
