import React from 'react';

const Bag = props => (
  <svg
    {...props}
    width="434px"
    height="234px"
    viewBox="0 0 434 234"
    version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g fillRule="nonzero" fill="#FFFFFF">
        <g transform="translate(96.000000, 0.000000)">
          <polygon points="198.97 19.15 198.97 26.15 234.58 26.15 234.58 226.71 7 226.71 7 26.15 41.95 26.15 41.95 19.15 0 19.15 0 233.71 241.58 233.71 241.58 19.15" />
          <rect x="62.95" y="19.15" width="115.01" height="7" />
          <path d="M179.37,74.8 L172.37,74.8 L172.37,96.41 L124,96.41 L124,74.8 L117,74.8 L117,96.41 L68.58,96.41 L68.58,74.8 L61.58,74.8 L61.58,96.41 L19.15,96.41 L19.15,103.41 L61.58,103.41 L61.58,141.18 L19.15,141.18 L19.15,148.18 L61.58,148.18 L61.58,186 L19.15,186 L19.15,193 L61.58,193 L61.58,214.61 L68.58,214.61 L68.58,193 L117,193 L117,214.61 L124,214.61 L124,193 L172.4,193 L172.4,214.61 L179.4,214.61 L179.4,193 L222.46,193 L222.46,186 L179.37,186 L179.37,148.18 L222.43,148.18 L222.43,141.18 L179.37,141.18 L179.37,103.41 L222.43,103.41 L222.43,96.41 L179.37,96.41 L179.37,74.8 Z M68.58,103.41 L117,103.41 L117,141.18 L68.58,141.18 L68.58,103.41 Z M68.58,185.95 L68.58,148.18 L117,148.18 L117,186 L68.58,185.95 Z M172.37,185.95 L124,185.95 L124,148.18 L172.4,148.18 L172.37,185.95 Z M172.37,141.18 L124,141.18 L124,103.41 L172.4,103.41 L172.37,141.18 Z" />
          <polygon points="144.72 134.12 163.15 116.16 158.26 111.14 144.77 124.29 138.1 117.65 133.16 122.61" />
          <polygon points="48.95 43.03 49.63 43.03 55.28 43.03 55.95 43.03 55.95 0.75 55.28 0.75 49.63 0.75 48.95 0.75" />
          <polygon points="222.34 55.31 215.34 55.31 215.34 62.31 222.35 62.31" />
          <polygon points="107.9 55.31 100.9 55.31 100.9 62.31 107.92 62.31" />
          <polygon points="140.6 55.31 133.6 55.31 133.6 62.31 140.62 62.31" />
          <polygon points="124.25 55.31 117.25 55.31 117.25 62.31 124.27 62.31" />
          <polygon points="156.95 55.31 149.95 55.31 149.95 62.31 156.97 62.31" />
          <polygon points="189.64 55.31 182.64 55.31 182.64 62.31 189.66 62.31" />
          <polygon points="173.29 55.31 166.29 55.31 166.29 62.31 173.31 62.31" />
          <polygon points="91.56 55.31 84.56 55.31 84.56 62.31 91.58 62.31" />
          <polygon points="58.86 55.31 51.86 55.31 51.86 62.31 58.88 62.31" />
          <polygon points="42.51 55.31 35.51 55.31 35.51 62.31 42.53 62.31" />
          <polygon points="75.21 55.31 68.21 55.31 68.21 62.31 75.23 62.31" />
          <polygon points="205.99 55.31 198.99 55.31 198.99 62.31 206.01 62.31" />
          <polygon points="26.18 55.31 19.18 55.31 19.18 62.31 26.19 62.31" />
          <polygon points="184.97 43.03 185.64 43.03 191.3 43.03 191.97 43.03 191.97 0.75 191.3 0.75 185.64 0.75 184.97 0.75" />
        </g>
      </g>
    </g>
  </svg>
);
export default Bag;
