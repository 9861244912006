import React, { Component } from "react";
import ReactDOM from "react-dom";
import { CSSTransition } from "react-transition-group";

//components
import Video from "components/video";

//styles
import styles from "./styles.scss";

//icons
import Cross from "icons/cross";

class VideoOverlay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keys: [32, 33, 34, 35, 36, 37, 38, 39, 40],
      mount: false
    };
  }

  componentDidMount() {
    this.setState({
      mount: true
    });
  }

  componentWillUnmount() {
    window.onmousewheel = document.onmousewheel = document.onkeydown = null;
  }

  preventDefault = e => {
    e = e || window.event;
    if (e.preventDefault) e.preventDefault();
    e.returnValue = false;
  };

  keydown = e => {
    for (var i = this.state.keys.length; i--; ) {
      if (e.keyCode === this.state.keys[i]) {
        e.preventDefault();
        return;
      }
    }
  };

  wheel = e => {
    e.preventDefault();
  };

  render() {
    const { close, video } = this.props;
    const { mount } = this.state;

    return ReactDOM.createPortal(
      <CSSTransition
        in={mount}
        timeout={600}
        classNames={{
          enterDone: styles["popup-enter-done"]
        }}
        unmountOnExit
      >
        {state => (
          <div className={styles.popup}>
            <div className={styles.popupcontainer} ref="modal">
              <CSSTransition
                in={state === "entered"}
                timeout={400}
                classNames={{
                  enterDone: styles["popup-content-enter-done"]
                }}
                unmountOnExit
              >
                {state => (
                  <div className={styles["popup-content"]}>
                    <CSSTransition
                      in={state === "entered"}
                      timeout={400}
                      classNames={{
                        enterDone: styles["closebtn-enter-done"]
                      }}
                      unmountOnExit
                    >
                      <div className={styles.closebtn} onClick={close}>
                        <Cross />
                      </div>
                    </CSSTransition>
                    <Video video={video} />
                  </div>
                )}
              </CSSTransition>
            </div>
            <span className={styles.background} onClick={close} />
          </div>
        )}
      </CSSTransition>,
      document.getElementById("concreeto")
    );
  }
}
export default VideoOverlay;
