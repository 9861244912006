import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import { inject, observer } from "mobx-react";
import { withNamespaces } from 'react-i18next';

import styles from "./styles.scss";

//Icons
import Logo from "icons/logo";
import Cross from "icons/cross";
import Phone from "icons/phone";

// decorators
@inject("navigationStore")
@observer
class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keys: [32, 33, 34, 35, 36, 37, 38, 39, 40],
      width: null,
    };
  }
  getWidth = () => {
    this.setState({
      width: window.outerWidth,
    });
  };
  componentDidMount() {
    this.getWidth();
    window.addEventListener("resize", this.getWidth);
    this.props.navigationStore.getAmount();
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.getWidth);
  }
  preventDefault = (e) => {
    e = e || window.event;
    if (e.preventDefault) e.preventDefault();
    e.returnValue = false;
  };

  keydown = (e) => {
    for (var i = this.state.keys.length; i--; ) {
      if (e.keyCode === this.state.keys[i]) {
        e.preventDefault();
        return;
      }
    }
  };

  wheel = (e) => {
    e.preventDefault();
  };
  toggleMenu = (event) => {
    event.preventDefault();
    if (this.props.navigationStore.menuOpen === false) {
      this.props.navigationStore.menuOpen = true;
      if (window.addEventListener) {
        window.addEventListener("DOMMouseScroll", this.wheel, false);
      }
      window.onmousewheel = document.onmousewheel = this.wheel;
      document.onkeydown = this.keydown;
    } else {
      this.props.navigationStore.menuOpen = false;
      if (window.removeEventListener) {
        window.removeEventListener("DOMMouseScroll", this.wheel, false);
      }
      window.onmousewheel = document.onmousewheel = document.onkeydown = null;
    }
  };
  closeMenu = () => {
    if (this.props.navigationStore.menuOpen === false) {
      this.props.navigationStore.menuOpen = true;
      if (window.addEventListener) {
        window.addEventListener("DOMMouseScroll", this.wheel, false);
      }
      window.onmousewheel = document.onmousewheel = this.wheel;
      document.onkeydown = this.keydown;
    } else {
      this.props.navigationStore.menuOpen = false;
      if (window.removeEventListener) {
        window.removeEventListener("DOMMouseScroll", this.wheel, false);
      }
      window.onmousewheel = document.onmousewheel = document.onkeydown = null;
    }
  };

  render() {
    const { amount } = this.props.navigationStore;
    const { t } = this.props;
    return (
      <div className={styles.overall}>
        <div
          className={[
            this.props.navigationStore.menuOpen
              ? styles.visible
              : styles.invisible,
            styles.btncontainer,
          ].join(" ")}
        >
          <a
            onClick={this.toggleMenu}
            className={[
              this.props.variant
                ? styles["menubtn-background-A"]
                : styles["menubtn-background-B"],
              styles.menubtn,
            ].join(" ")}
            href="!#"
          >
            <Cross
              className={[
                this.props.variant
                  ? styles["hamburger-fill-A"]
                  : styles["hamburger-fill-B"],
                styles.hamburger,
              ].join(" ")}
            />
            {t('Close')}
          </a>
          <Link onClick={this.closeMenu} className={styles.logo} to="/">
            <Logo fill="#0078e6" />
          </Link>
        </div>
        <div
          className={[
            this.props.navigationStore.menuOpen ? styles.open : styles.closed,
            styles.menucontainer,
          ].join(" ")}
        >
          <h2 className={styles.uppercase}>{t('Menu')}.</h2>
          <ul>
            <li>
              <NavLink
                activeClassName={styles["link--is-active"]}
                onClick={this.closeMenu}
                to="/"
                exact
              >
                {t('Home')}
              </NavLink>
            </li>
            <li>
              <NavLink
                activeClassName={styles["link--is-active"]}
                onClick={this.closeMenu}
                to="/over-concreeto"
              >
                {t('About Concreeto')}
              </NavLink>
            </li>
            <li>
              <NavLink
                activeClassName={styles["link--is-active"]}
                onClick={this.closeMenu}
                to="/partners"
              >
                {t('For partners')}
              </NavLink>
            </li>
            <li>
              <NavLink
                activeClassName={styles["link--is-active"]}
                onClick={this.closeMenu}
                to="/vacatures"
              >
                {t('All job offers')}{" "}
                {amount > 0 && <span className={styles.amount}>{amount}</span>}
              </NavLink>
            </li>
            <li>
              <NavLink
                activeClassName={styles["link--is-active"]}
                onClick={this.closeMenu}
                to="/blog"
              >
                {t('Blog')}
              </NavLink>
            </li>
            <li>
              <NavLink
                activeClassName={styles["link--is-active"]}
                onClick={this.closeMenu}
                to="/werkwijze"
              >
                {t('Workflow')}
              </NavLink>
            </li>
            <li>
              <NavLink
                activeClassName={styles["link--is-active"]}
                onClick={this.closeMenu}
                to="/concreeto-go"
              >
                {t('Concreeto GO')}
              </NavLink>
            </li>
            <li>
              <NavLink
                activeClassName={styles["link--is-active"]}
                onClick={this.closeMenu}
                to="/contact"
              >
                {t('Contact')}
              </NavLink>
            </li>
            <li>
              <a
                href="https://concreetoonline.nocore.nl/Account/Login"
                rel="noopener noreferrer"
                target="_blank"
              >
                {t('Time registration')}
              </a>
            </li>
          </ul>
          {this.state.width && this.state.width > 700 ? null : (
            <a
              className={[
                styles["phonebtn-background-A"],
                styles.phonebtn,
              ].join(" ")}
              href="tel:0107200720"
            >
              <Phone
                className={[styles["phone-fill-A"], styles.phone].join(" ")}
              />
              010-7200720
            </a>
          )}
        </div>
      </div>
    );
  }
}

export default withNamespaces()(Menu);
