import React, { Component } from 'react';
import Marquee from 'components/marquee';
import Notch from 'components/notch';
import styles from './styles.scss';

class MarqueeBlock extends Component {
  render() {
    const { notch, pretext, partners } = this.props;
    return (
      <React.Fragment>
        {notch ? <Notch variants={['white', 'right']} /> : null}
        <div className={['wrapper', styles.wrapper].join(' ')}>
          <h2>{pretext}</h2>
        </div>
        <Marquee items={partners} text="shell pernis cofely" />
      </React.Fragment>
    );
  }
}

export default MarqueeBlock;
