import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import request from "superagent";
import ReactGA from "react-ga";
import { withNamespaces } from "react-i18next";

// components
import Input from "components/form-text-input";
import Dropzone from "components/form-dropzone";
import Button from "components/button";
import Checkbox from "components/checkbox";
import Intro from "components/cv-overlay-intro";
import Message from "components/cv-overlay-success";

// icons
import ArrowRight from "icons/arrow-right";

// styles
import styles from "./styles.scss";

@inject("vacancyUploadStore")
@inject("popupStore")
@observer
class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      disabled: false,
      errors: [],
      terms: false,
    };
  }
  renderErrors() {
    let errors = [];
    let values = Object.values(this.props.form.errors()).filter(
      (value) => value !== null
    );

    for (let index = 0; index < values.length; index++) {
      errors.push(values[index]);
    }

    return errors;
  }

  nextStep = () => {
    const { t } = this.props;
    this.props.form.$("firstName").set("label", t("firstName"));
    this.props.form.$("lastName").set("label", t("lastName"));
    this.props.form.$("emailaddress").set("label", t("emailaddress"));
    this.props.form.$("phonenumber").set("label", t("phonenumber"));
    this.props.form.validate({ showErrors: true }).then(({ isValid }) => {
      if (isValid) {
        this.props.vacancyUploadStore.validate(
          this.props.form.values(),
          this.props.form
        );
      }
    });
  };

  prevStep = () => {
    this.props.form.$("step").set(this.props.form.$("step").get("value") - 1);
    this.props.popupStore.setCloseColor("#0078e6");
  };

  submit = (e) => {
    const { t } = this.props;
    e.preventDefault();
    const form = this.props.form;
    this.setState({
      errors: [],
      disabled: true,
    });

    if (this.props.form.$("terms").get("value") === false) {
      this.setState((prevState) => ({
        errors: [...prevState.errors, t("Agree with"), t("Privacy conditions")],
        disabled: false,
      }));
      return;
    } else {
      this.setState({
        errors: [],
      });
    }
    if (
      form.values().noFile === true ||
      (this.props.form.$("cv").files && this.props.form.$("cv").files[0])
    ) {
      if (form.values().newFile === true) {
        if (this.props.form.$("cv").files) {
          this.props.form.$("cv").set(this.props.form.$("cv").files[0][0]);
        } else {
          this.setState({
            errors: ["Geen CV geupload!"],
            disabled: false,
          });
          return;
        }
      } else {
        this.setState({
          errors: [],
        });
      }
      this.setState({
        loading: true,
      });
      ReactGA.event({
        category: "Sollicitatie",
        action: "Upload CV (submit)",
      });
      request
        .post(`${process.env.REACT_APP_API_URL}/vacancies/v1/vacancy/signup`)
        // .send(form.values())
        .field("language", localStorage.getItem("i18nextLng"))
        .field("firstName", form.values().firstName)
        .field("lastName", form.values().lastName)
        .field("emailaddress", form.values().emailaddress)
        .field("phonenumber", form.values().phonenumber)
        .field("terms", form.values().terms)
        .field("noFile", form.values().noFile)
        .field("id", this.props.details.id)
        .attach("cv", form.values().cv)
        .then((response) => {
          form.$("step").set(form.$("step").get("value") + 1);
          this.props.popupStore.setCloseColor("#143264");
        })
        .then(() => {
          this.setState({
            loading: false,
          });
          ReactGA.event({
            category: "Sollicitatie",
            action: "Upload CV (submit, gelukt)",
          });
        })
        .catch((error) => {
          this.setState({
            disabled: false,
            loading: false,
            errors: [error.response.body.message],
          });
          // this.error = error.response.body.message;
          // this.response = null;
        });
    } else {
      this.setState({
        errors: ["Geen CV geupload!"],
        disabled: false,
      });
    }
  };

  toggleTerms = (event) => {
    const { t } = this.props;
    event.preventDefault();

    if (event.target.tagName === "A") {
      window.open(event.target.getAttribute("href"), "_blank");
      return;
    }
    this.setState({
      terms: !this.state.terms,
    });

    if (this.state.terms === true) {
      this.props.form.$("terms").set(false);
      // error i18n
      this.setState({
        errors: [t("Agree with"), t("Privacy conditions")],
      });
    } else {
      this.props.form.$("terms").set(true);
      this.setState({
        errors: [],
      });
    }
  };

  render() {
    const { form, details, t } = this.props;
    const { loading, errors, terms, disabled } = this.state;
    const { user, isLoading } = this.props.vacancyUploadStore;
    return (
      <form
        onSubmit={form.onSubmit}
        className={[
          styles.form,
          form.$("step").get("value") === 2 ? styles["form--blue"] : null,
        ].join(" ")}
      >
        {form.$("step").get("value") === 0 ? (
          <React.Fragment>
            <Intro
              title={this.props.details.id ? details.title : "aan de slag."}
              subtitle={this.props.details.id ? t("resume-upload subtitle") : "Ik wil graag"}
              description={this.props.details.id ? t("resume-upload description") : "Laat hieronder je gegevens achter, dan gaan wij je helpen<br>bij het vinden van een geschikte baan!"}
            />

            <div className={styles["input-wrap"]}>
              <span className={styles.names}>
                <Input
                  label={t("firstName")}
                  field={form.$("firstName")}
                  autoComplete="fname"
                />
                <Input
                  label={t("lastName")}
                  field={form.$("lastName")}
                  autoComplete="lname"
                />
              </span>

              <Input
                label={t("emailaddress")}
                field={form.$("emailaddress")}
                autoComplete="email"
              />
              <Input
                label={t("phonenumber")}
                field={form.$("phonenumber")}
                autoComplete="tel"
              />
            </div>
            {this.renderErrors().length > 0 && (
              <p className={styles.error}>
                {this.renderErrors().map((item, i) => {
                  return <span key={i}>{item}</span>;
                })}
              </p>
            )}
            <Button
              type="dummy"
              className={["large", "blue"]}
              onClick={this.nextStep}
            >
              {isLoading
                ? t("resume-upload loading")
                : t("resume-upload description 3")}{" "}
              <ArrowRight />
            </Button>
          </React.Fragment>
        ) : form.$("step").get("value") === 1 ? (
          <React.Fragment>
            <Intro
              title={details.title}
              subtitle={t("resume-upload subtitle")}
              description={t("resume-upload description 2")}
            />
            <Dropzone
              field={form.$("cv")}
              translations={{
                alert: t("resume-upload-success alert"),
                change: t("resume-upload-success change"),
                known: t("resume-upload-success known"),
                click: t("resume-upload-success click"),
                or: t("resume-upload-success or"),
                drag: t("resume-upload-success drag"),
                here: t("resume-upload-success here"),
              }}
              user={user}
              form={form}
            />
            <Checkbox onClick={this.toggleTerms} active={terms}>
              {t("Agree with")}{" "}
              <a
                href={`${process.env.REACT_APP_API_SITE_URL}/resources/uploads/2019/01/privacy-verklaring.pdf`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("Privacy conditions")}
              </a>
            </Checkbox>
            {errors.length > 0 && (
              <p className={styles.error} style={{ marginTop: 0 }}>
                {errors.map((item, i) => {
                  return <span key={i}>{item}</span>;
                })}
              </p>
            )}
            <Button
              disabled={disabled}
              type="submit"
              onClick={this.submit}
              className={["large", "green", disabled ? "disabled" : null]}
            >
              {loading ? "Aan het versturen..." : "CV versturen"} <ArrowRight />
            </Button>
            <div onClick={this.prevStep} className={styles.back}>
              <ArrowRight /> {t("Go back")}
            </div>
          </React.Fragment>
        ) : form.$("step").get("value") === 2 ? (
          <React.Fragment>
            <Message
              title={t("resume-upload-success title")}
              button={{ to: "/vacatures", text: "Terug naar Vacatures" }}
            />
          </React.Fragment>
        ) : null}
      </form>
    );
  }
}

export default withNamespaces()(Form);
