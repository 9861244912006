import React, { Component } from "react";
import Notch from "components/notch";
import styles from "./styles.scss";

class PageDescription extends Component {
  render() {
    const { content } = this.props;
    return (
      <div className={styles.textblock}>
        <Notch variants={["right", "small"]} />
        <div className="wrapper">
          <h1>{content.title}</h1>
          <p dangerouslySetInnerHTML={{ __html: content.description }} />
        </div>
      </div>
    );
  }
}

export default PageDescription;
