import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import Member from 'components/member';

import styles from './styles.scss';

// decorators
@inject('aboutStore')
@observer
class Team extends Component {
  render() {
    const { team } = this.props.aboutStore.response;
    return (
      <div className={['wrapper', styles.list].join(' ')}>
        {team.map((item, i) => {
          return (
            <Member
              key={i}
              img={item.image}
              position={item.position}
              firstName={item.firstName}
              lastName={item.lastName}
              description={item.description}
            />
          );
        })}
      </div>
    );
  }
}
export default Team;
