import React from 'react';

const ArrowRight = props => (
  <svg {...props} width="17px" height="12px" viewBox="0 0 17 12">
    <g
      id="menu/transparant"
      transform="translate(-1187.000000, -32.000000)"
      fill={props.fill}
      fillRule="nonzero">
      <g transform="translate(1007.000000, 0.000000)">
        <path d="M196.133533,37.7785755 L190.207286,32.0319117 C190.18594,32.011212 190.156587,32 190.126347,32 C190.096107,32 190.066754,32.0120747 190.045408,32.032775 L188.756602,33.2868314 C188.753045,33.2902814 188.750376,33.2928689 188.747708,33.2963188 C188.710352,33.3420303 188.715689,33.4084413 188.761049,33.4481162 C188.763718,33.4507037 188.767276,33.453291 188.769944,33.4550161 L192.193355,36.7746876 L180.113848,36.7738252 C180.050699,36.7738252 180,36.8229877 180,36.8842233 L180,38.8274069 C180,38.8886425 180.050699,38.937805 180.113848,38.937805 L192.192444,38.937805 L188.755599,42.2705035 C188.734253,42.2912031 188.72269,42.319666 188.72269,42.3489899 C188.72269,42.3783139 188.735142,42.4067767 188.755599,42.4274764 L190.045293,43.6780884 C190.067529,43.6996504 190.09688,43.71 190.126232,43.71 C190.155584,43.71 190.184934,43.6987878 190.207171,43.6780884 L196.133418,37.9356197 C196.154765,37.91492 196.166327,37.8864572 196.166327,37.8571332 C196.166327,37.8278093 196.153875,37.7993464 196.133418,37.7786468 L196.133533,37.7785755 Z" />
      </g>
    </g>
  </svg>
);
export default ArrowRight;
