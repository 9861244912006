import React, { Component } from 'react';
import styles from './styles.scss';
import Marquee from 'components/marquee';
import PhoneAndMail from 'components/phone-and-mail';
import GridTest from 'components/grid-test';
import { withNamespaces } from 'react-i18next';

//icons
import Logo from 'icons/logo';
import ArrowRight from 'icons/arrow-right';
import Slogan from 'icons/slogan';

class Footer extends Component {
  render() {
    const { t } = this.props;

    return (
      <footer className={styles.footer}>
        {/* <div className={styles.grid} /> */}
        <div className={['wrapper', styles.wrapper].join(' ')}>
          <div className={styles.address}>
            <Logo fill="#ffffff" />
            <p>
              Hoogstraat 32 A <br />
              3011 PR Rotterdam
            </p>
            <a
              href="https://www.google.nl/maps/dir//Concreeto+Uitzendbureau,+Hoogstraat+32+%2FA,+3011+PR+Rotterdam/@51.922952,4.4921313,17z/data=!4m9!4m8!1m0!1m5!1m1!1s0x47c43345a9f1fa5d:0x74c6b5b12d1d3195!2m2!1d4.49432!2d51.922952!3e0"
              rel="noopener noreferrer"
              className={styles.route}
              target="_blank">
              {t('Directions')} <ArrowRight fill="#ffffff" />
            </a>
          </div>
          <PhoneAndMail variants={['black']} />
          <Slogan className={styles.slogan} />
        </div>
        <Marquee
          footer={true}
          text={t('READY? SET. WORK!')}
        />
        <div className={['wrapper', styles.wrapper].join(' ')}>
          <div className={styles.postfooter}>
            <span className={styles.copyright}>
              &copy; {new Date().getFullYear()} {t('Rights reserved')}
            </span>
            <span className={styles.pral}>
              <a
                href={`${
                  process.env.REACT_APP_API_SITE_URL
                }/resources/uploads/2019/01/privacy-verklaring.pdf`}
                target="_blank"
                rel="noopener noreferrer">
                {t('Privacy Declaration')}
              </a>
              <a
                href={`${
                  process.env.REACT_APP_API_SITE_URL
                }/resources/uploads/2019/01/algemene-voorwaarden.pdf`}
                target="_blank"
                rel="noopener noreferrer"
                to="/algemene-voorwaarden">
                {t('Terms and Conditions')}
              </a>
            </span>
          </div>
        </div>
        <GridTest variants={['default']} />
      </footer>
    );
  }
}

export default withNamespaces()(Footer);
