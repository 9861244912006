import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import request from 'superagent';

import { history } from 'store';

// components
import Input from 'components/form-text-input';
import Button from 'components/button';

// icons
import ArrowRight from 'icons/arrow-right';

// styles
import styles from './styles.scss';

@inject('vacancyUploadStore')
@inject('popupStore')
@observer
class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      response: null
    };
  }
  renderErrors() {
    let errors = [];
    let values = Object.values(this.props.form.errors()).filter(
      value => value !== null
    );

    for (let index = 0; index < values.length; index++) {
      errors.push(values[index]);
    }

    return errors;
  }

  submit = e => {
    e.preventDefault();
    const form = this.props.form;

    request
      .post(
        `${process.env.REACT_APP_API_URL}/vacancies/v1/alert/signup/unsubscribe`
      )
      .field('emailaddress', form.values().emailaddress)
      .then(response => {
        this.setState({
          response: response.body
        });
        setTimeout(() => {
          history.push('/');
        }, 4000);
      })
      .catch(error => {
        this.setState({
          response: error.response.body
        });
      });
  };

  render() {
    const { form } = this.props;
    const { response } = this.state;
    return (
      <form onSubmit={form.onSubmit} className={styles.form}>
        <div className={styles['input-wrap']}>
          <Input field={form.$('emailaddress')} />
        </div>
        <Button
          type="dummy"
          className={['large', 'blue']}
          onClick={this.submit}>
          Uitschrijven <ArrowRight />
        </Button>
        {response ? (
          <p className={styles.response}>{response.message}</p>
        ) : (
          this.renderErrors().length > 0 && (
            <p className={styles.response}>
              {this.renderErrors().map((item, i) => {
                return [<span>{item}</span>, <br />];
              })}
            </p>
          )
        )}
      </form>
    );
  }
}

export default Form;
