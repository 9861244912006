import React, { Component } from "react";

import styles from "./styles.scss";

class CvOverlayIntro extends Component {
  render() {
    const { title, subtitle, description } = this.props;
    return (
      <div className={styles.intro}>
        <h3
          className={styles.subtitle}
          dangerouslySetInnerHTML={{ __html: subtitle }}
        />
        <h2
          className={styles.title}
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <p
          className={styles.description}
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </div>
    );
  }
}

export default CvOverlayIntro;
