import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { withNamespaces } from 'react-i18next';

//styles
import styles from './styles.scss';

//icons
import ArrowRight from 'icons/arrow-right';

class ArticleGridItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mount: false,
    };
  }
  componentDidMount() {
    this.setState({
      mount: true,
    });
  }

  render() {
    const { delay, item, t } = this.props;
    const { mount } = this.state;
    return (
      item && (
        <CSSTransition
          in={mount}
          timeout={delay}
          classNames={{
            enterDone: styles['item-enter-done'],
          }}
          unmountOnExit
        >
          {(state) => (
            <Link to={`/blog/${item.slug}`} className={styles.item}>
              <img className={styles.image} src={item.image} alt={item.title} />
              <div className={styles.content}>
                <h4
                  className={styles.title}
                  dangerouslySetInnerHTML={{ __html: item.title }}
                />
                <span className={styles.subtitle}>
                  {item.sector && item.sector.replace(/\./g, '')}{' '}
                  {item.sector && item.area ? `/ ${item.area}` : item.area}
                </span>
                <div className={styles.description}>
                  <p dangerouslySetInnerHTML={{ __html: item.excerpt }} />
                  <span className={styles.link}>
                    {t('Read blog')}
                    <ArrowRight />
                  </span>
                </div>
              </div>
            </Link>
          )}
        </CSSTransition>
      )
    );
  }
}

export default withNamespaces()(ArticleGridItem);
