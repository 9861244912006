import React from 'react';

const Cross = props => (
  <svg {...props} width="13px" height="13px" viewBox="0 0 13 13">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        transform="translate(-75.000000, -32.000000)"
        fillRule="nonzero">
        <g>
          <g>
            <g>
              <g transform="translate(75.000000, 26.000000)">
                <path d="M1.63540227,6.00003093 C1.55807536,6.00118498 1.48479326,6.03176881 1.43054742,6.08659065 L0.0865595577,7.43057528 C-0.0288531859,7.54598774 -0.0288531859,7.73295963 0.0865595577,7.84836471 L4.73881955,12.4988408 L0.0865595577,17.1510896 L0.0865595577,17.1516666 C-0.0288531859,17.2670791 -0.0288531859,17.4540509 0.0865595577,17.569456 L1.43054742,18.9134407 C1.54596016,19.0288531 1.7329325,19.0288531 1.84833786,18.9134407 L6.49882512,14.2611919 L11.1510851,18.9134407 L11.1516621,18.9134407 C11.2670749,19.0288531 11.4540472,19.0288531 11.5694526,18.9134407 L12.9134404,17.569456 C13.0288532,17.4540436 13.0288532,17.2670717 12.9134404,17.1516666 L8.26118045,12.4994178 L12.9134404,7.84894174 L12.9134404,7.84836471 C13.0288532,7.73295225 13.0288532,7.54598036 12.9134404,7.43057528 L11.5694526,6.08659065 C11.4540398,5.97117819 11.2670675,5.97117819 11.1516621,6.08659065 L6.49940214,10.7388394 L1.84891489,6.08659065 L1.84833786,6.08659065 C1.79178598,6.03003891 1.71503669,5.99887718 1.63540227,6.00003093 Z" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export default Cross;
