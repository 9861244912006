import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Helmet } from 'react-helmet';

// components
import DnaCta from 'components/dna-cta';
// import FeaturedImage from 'components/featured-image';
import GridTest from 'components/grid-test';
import ImageCta from "components/image-cta";
// import Loading from "components/loading";
import MarqueeBlock from 'components/marquee-block';
import PageHero from 'components/page-hero';
import PageLoading from 'components/page-loading';
// import Pagination from "components/pagination";
import RadioFeaturedFilter from 'components/radio-featured-filter';
import RadioCompany from 'components/radio-company';
// import VacancyGrid from "components/vacancy-grid";
// import VacancyGridItem from "components/vacancy-grid-item";

// styles
import styles from './styles.scss';

// decorators
@inject('homeStore')
@inject('vacancyStore')

@observer
class RadioLanding extends Component {
  componentDidMount() {
    this.props.homeStore.getPageDetails();
    this.props.vacancyStore.getVacancyItems(0, false, true);
  }

  render() {
    const { response, isLoading } = this.props.homeStore;

    const dna = {
      title: "Concreeto klopt.",
      description: "Goed is niet goed genoeg, wij gaan altijd voor het beste \
      van het beste. Onze drive zorgt ervoor dat we de \
      stabiele partner zijn voor uitzendkracht en partner. We \
      zijn betrouwbaar in woorden en concreet in daden.",
      button: {
        link: "/over-concreeto",
        text: "Over Concreeto"
      }
    }

    return response ? (
      <React.Fragment>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{response.seo.siteTitle}</title>
          <meta name="robots" content="noindex,follow" />
          <meta name="description" content={response.seo.description} />
          <meta property="fb:pages" content="828024637343069" />
          <meta property="og:title" content={response.seo.title} />
          <meta property="og:description" content={response.seo.description} />
          <meta
            property="og:url"
            content={`${process.env.REACT_APP_SITE_URL}${
              window.location.pathname
            }`}
          />
          <meta property="og:image" content={response.seo.image} />
          <meta property="og:site_name" content={response.seo.siteName} />
        </Helmet>

        <PageHero
          loading={isLoading}
          title="<b>Geen woorden<br>maar banen.</b>"
          video={response.pageHero}
          subTitle={
            response.pageHero.firstName
              ? `${response.pageHero.firstName}${response.pageHero.age &&
                  `${', '}${response.pageHero.age}${' '}jaar`}`
              : null
          }
          slogan
          image={response.pageHero.image}
          share="home"
          variants={['story']}
        />

        <RadioFeaturedFilter preselect="Logistiek" />
        <RadioCompany />
        <ImageCta
          title="<b>Niet gevonden<br />wat je zocht?</b>"
          description="Ontvang een melding als jouw droombaan weer beschikbaar is!"
          image="https://api.concreeto.nl/resources/uploads/2019/01/not-found-1244x800.jpg"
          link="#"
          buttontext="Vacature alert"
          popup={true}
          vacancyPopup={{
            title: `Niet gevonden wat je zocht?`,
            categories: {},
          }}
          notch={["longer", "back", "top", "white"]}
        />
        <DnaCta response={dna} />
        <MarqueeBlock
          notch="true"
          pretext="Concreeto partners"
          partners={response.partners.items}
        />
        <GridTest variants={['default']} />
      </React.Fragment>
    ) : (
      <PageLoading color="#0078e6" />
    );
  }
}

export default RadioLanding;
