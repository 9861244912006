import React, { Component } from "react";
import { Link } from "react-router-dom";
import { inject, observer } from "mobx-react";

import { history } from "store";

// styles
import styles from "./styles.scss";

// icons
import Cog from "icons/cog";
import Logistics from "icons/logistics";
import Mop from "icons/mop";
import Ship from "icons/ship";
// decorators
@inject("vacancyStore")
@observer
class FeaturedFilterCategoriesItem extends Component {
  updateFilterPreferences = (id, slug, name) => {
    this.props.vacancyStore.addItemValue(0, id, slug, name);
    history.push("/vacatures/");
  };
  render() {
    const { category, type } = this.props;

    return (
      <Link
        to={""}
        onClick={e => {
          e.preventDefault();
          this.updateFilterPreferences(
            category.id,
            category.slug,
            category.title
          );
        }}
        className={[
          styles["category-item"],
          type === "simple" ? styles["category-item--simple"] : null
        ].join(" ")}
      >
        <span className={styles.icon}>
          {category.icon === "mop" && <Mop />}
          {category.icon === "cog" && <Cog />}
          {category.icon === "ship" && <Ship />}
          {category.icon === "logistics" && <Logistics />}
        </span>
        <div className={styles.content}>
          <h4 className={styles.title}>{category.title}</h4>
          <p className={styles.description}>{category.description}</p>
        </div>
      </Link>
    );
  }
}

export default FeaturedFilterCategoriesItem;
