import React, { Component } from 'react';

import styles from './styles.scss';

class Marquee extends Component {
  render() {
    const { text, footer, items } = this.props;
    return (
      <div
        className={[
          styles.marquee,
          footer === true ? styles['marquee--footer'] : null
        ].join(' ')}>
        <div className={styles.marqueeInner}>
          {footer ? (
            <span>
              <p>{text}</p>
            </span>
          ) : (
            <span>
              {items.map((item, i) => {
                return i % 2 ? (
                  <p key={i} style={{ color: '#143264', paddingRight: '30px' }}>
                    {item.title}
                  </p>
                ) : (
                  <p key={i} style={{ color: '#0078e6', paddingRight: '30px' }}>
                    {item.title}
                  </p>
                );
              })}
            </span>
          )}
        </div>
      </div>
    );
  }
}
export default Marquee;
