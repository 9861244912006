import { observable, action } from 'mobx';
import request from 'superagent';
import localStorage from 'mobx-localstorage';

import { history } from 'store';

export class vacancyStore {
  @observable isLoading = false;
  @observable overviewLoading = true;
  @observable overviewResponse = null;

  @observable filterSubcategoryResponse = null;
  @observable filterSubcategoryLoading = false;

  @observable overviewItemsResponse = null;
  @observable overviewItemsTotal = 0;
  @observable overviewItemsLoading = true;

  @observable overviewTotalsAmount = null;
  @observable overviewTotalsLoading = false;

  @observable response = null;
  @observable activeIndex = -1;
  @observable filterValues = [];

  @observable filter = [[], [], [], []];
  @observable filterPlaceholders = ['', '', '', ''];
  @observable isDisabledSubcategory = true;

  @observable currentOverviewPage = null;

  @action openCategory(index) {
    this.activeIndex = index;
    return true;
  }
  @action getFilterCategories() {
    this.isLoading = true;

    return request
      .get(
        `${process.env.REACT_APP_API_URL}/vacancies/v1/filter${
          localStorage.getItem('i18nextLng') === 'nl'
            ? ``
            : `?lang=${localStorage.getItem('i18nextLng')}`
        }`
      )
      .then((response) => {
        this.response = response.body;
      })
      .then(() => {
        this.isLoading = false;

        if (!localStorage.getItem('filter')) {
          localStorage.setItem('filter', this.filter);
        }
        if (this.filter[0].length > 0 && this.filter[0][0]) {
          this.getFilterSectorSubcategories(
            localStorage.getItem('filter', this.filter)[0][0].id
          );
        }

        if (!localStorage.getItem('filterPlaceholders')) {
          localStorage.setItem('filterPlaceholders', this.filterPlaceholders);
        }

        if (this.filter.length < 1) {
          this.filter = [[], [], [], []];
          this.filterPlaceholders = ['', '', '', ''];
          this.filterSubcategoryResponse = null;
        } else {
          this.filterPlaceholders = localStorage.getItem('filterPlaceholders');
          this.filter = localStorage.getItem('filter');
        }
      })
      .catch((error) => {
        this.response = error;
      });
  }
  @action getFilterCategoriesResponse() {
    // this.isLoading = true;
    // this.overviewItemsLoading = true;
    return request
      .get(
        `${process.env.REACT_APP_API_URL}/vacancies/v1/filter${
          localStorage.getItem('i18nextLng') === 'nl'
            ? ''
            : `?lang=${localStorage.getItem('i18nextLng')}`
        }`
      )
      .then((response) => {
        if (!localStorage.getItem('filter')) {
          localStorage.setItem('filter', this.filter);
        }
        if (this.filter[0].length > 0 && this.filter[0][0]) {
          this.getFilterSectorSubcategories(
            localStorage.getItem('filter', this.filter)[0][0].id
          );
        }

        if (!localStorage.getItem('filterPlaceholders')) {
          localStorage.setItem('filterPlaceholders', this.filterPlaceholders);
        }

        if (this.filter.length < 1) {
          this.filter = [[], [], [], []];
          this.filterPlaceholders = ['', '', '', ''];
          this.filterSubcategoryResponse = null;
        } else {
          this.filterPlaceholders = localStorage.getItem('filterPlaceholders');
          this.filter = localStorage.getItem('filter');
        }

        return response.body;
      });
  }

  @action getFilterSectorSubcategories(sector) {
    this.filter[1] = [];
    this.filterPlaceholders[1] = '';
    this.filterSubcategoryLoading = true;
    return request
      .get(
        `${process.env.REACT_APP_API_URL}/vacancies/v1/filter/subcategories${
          localStorage.getItem('i18nextLng') === 'nl'
            ? ''
            : `?lang=${localStorage.getItem('i18nextLng')}`
        }`
      )
      .query({ sector: sector })
      .then((response) => {
        this.filterSubcategoryResponse = response.body;
        localStorage.setItem('filterSubcategories', response.body);
        this.filterSubcategoryLoading = false;
        if (response.body.length > 0) {
          this.isDisabledSubcategory = false;
          // localStorage.setItem('filter', this.filter);
        }
      })
      .then(() => {
        this.filterSubcategoryLoading = false;
      })
      .catch((error) => {
        this.filterSubcategoryResponse = null;
        localStorage.setItem('filterSubcategories', null);
        this.isDisabledSubcategory = true;
        this.filter[1] = [];
        this.filterPlaceholders[1] = '';
      });
  }

  @action getVacancyItems(page, redirect = false, radio = false) {
    if (page === 0 && redirect === false && radio == false) {
      window.history.pushState('', '', '/vacatures');
    } else if (redirect === true) {
      history.push('/vacatures');
    }
    this.overviewItemsLoading = true;
    let values = [];
    let filterValues;
    if (localStorage.getItem('filter')) {
      filterValues = localStorage.getItem('filter');
    } else {
      filterValues = this.filter;
    }
    filterValues.map((item) => {
      let result = '';
      item.map((filter, i) => {
        if (item.length === i + 1) {
          result += `${filter.id}`;
        } else {
          result += `${filter.id}, `;
        }
        return false;
      });
      values.push(result);
      return false;
    });

    return request
      .get(
        `${process.env.REACT_APP_API_URL}/vacancies/v1/overview/items${
          localStorage.getItem('i18nextLng') === 'nl'
            ? ''
            : `?lang=${localStorage.getItem('i18nextLng')}`
        }`
      )
      .query({ page: page, filter: JSON.stringify(values) })
      .then((response) => {
        this.overviewItemsResponse = response.body;
        this.overviewItemsTotal = response.body.total;
      })
      .then(() => {
        this.overviewItemsLoading = false;
      })
      .catch((error) => {
        this.overviewItemsResponse = error;
        this.overviewItemsTotal = 0;
        this.overviewItemsLoading = false;
      });
  }

  @action getVacancyOverview(page, filter = null) {
    this.overviewLoading = true;
    this.overviewItemsLoading = true;
    let values = [];
    if (localStorage.getItem('filter')) {
      localStorage.getItem('filter').map((item, i) => {
        values.push(item.slug);
        return false;
      });
    } else {
      this.filterValues.map((item, i) => {
        values.push(item.slug);
        return false;
      });
    }

    return request
      .get(
        `${process.env.REACT_APP_API_URL}/vacancies/v1/overview${
          localStorage.getItem('i18nextLng') === 'nl'
            ? ''
            : `?lang=${localStorage.getItem('i18nextLng')}`
        }`
      )
      .query({ page: page, filter: JSON.stringify(values) })
      .then((response) => {
        this.overviewResponse = response.body;
      })
      .then(() => {
        this.overviewLoading = false;
      })
      .catch((error) => {
        this.overviewResponse = error;
      });
  }

  @action getVacancyOverviewTotals(page, filter = null) {
    this.overviewTotalsLoading = true;
    let values = [];
    this.filter.map((item) => {
      let result = '';
      item.map((filter, i) => {
        if (item.length === i + 1) {
          result += `${filter.id}`;
        } else {
          result += `${filter.id}, `;
        }
        return false;
      });
      values.push(result);
      return false;
    });

    return request
      .get(
        `${process.env.REACT_APP_API_URL}/vacancies/v1/overview/filter${
          localStorage.getItem('i18nextLng') === 'nl'
            ? ''
            : `?lang=${localStorage.getItem('i18nextLng')}`
        }`
      )
      .query({ filter: JSON.stringify(values) })
      .then((response) => {
        this.overviewTotalsAmount = response.body;
      })
      .then(() => {
        this.overviewTotalsLoading = false;
      })
      .catch((error) => {
        this.overviewTotalsAmount = 0;
      });
  }
  @action setCurrentOverviewPage(page) {
    this.currentOverviewPage = page;
  }

  @action uniqueValues(index, item) {
    let result = item.filter(function(a) {
      var key = a.value + '|' + a.label;
      if (!this[key]) {
        this[key] = true;
        return true;
      }
      return false;
    }, Object.create(null));

    // Set filter item placeholder
    let placeholder = '';
    result.map((item, i) => {
      let formattedLabel = item.label.replace(/\./g, '');
      if (result.length === i + 1) {
        placeholder += `${formattedLabel}`;
      } else {
        placeholder += `${formattedLabel}, `;
      }
      return false;
    });

    // update
    this.filter[index] = result;
    this.filterPlaceholders[index] = placeholder;

    localStorage.setItem('filter', this.filter);
    localStorage.setItem('filterPlaceholders', this.filterPlaceholders);

    if (index === 0) {
      this.filterSubcategoryResponse = null;
      this.isDisabledSubcategory = true;
      this.filter[1] = [];
      this.filterPlaceholders[1] = '';
    }

    //Update totals
    this.getVacancyOverviewTotals();
  }

  @action isItemSelected(index, value) {
    let filter;
    if (localStorage.getItem('filter')) {
      filter = localStorage.getItem('filter')[index];
    } else {
      filter = this.filter[index];
    }

    return filter.filter((item) => item.value === value).length > 0
      ? true
      : false;
  }

  @action addItemValue(index, id, value, label) {
    let filter;
    if (localStorage.getItem('filter')) {
      filter = localStorage.getItem('filter');
    } else {
      filter = this.filter;
    }
    // Empty array if index is 0
    if (index === 0) {
      filter[index] = [];
      filter[1] = [];
    }
    filter[index].push({ id: id, value: value, label: label });

    this.uniqueValues(index, filter[index]);
  }

  @action removeItemValue(index, id, value) {
    let filter;
    if (localStorage.getItem('filter')) {
      filter = localStorage.getItem('filter');
    } else {
      filter = this.filter;
    }

    filter[index] = filter[index].filter((item) => {
      return item.value !== value;
    });
    this.uniqueValues(index, filter[index]);
  }
}
export default new vacancyStore();
