import React from 'react';

const Phone = props => (
  <svg {...props} width="15px" height="15px" viewBox="0 0 15 15">
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd">
      <g
        id="menu/transparant"
        transform="translate(-1286.000000, -31.000000)"
        // fill="#143264"
        fillRule="nonzero">
        <g id="Group-31">
          <g id="Group-15-Copy" transform="translate(1239.000000, 0.000000)">
            <g id="Group-10" transform="translate(47.000000, 27.000000)">
              <g id="Group-16">
                <g
                  id="np_call_1543617_000000"
                  transform="translate(0.000000, 4.000000)">
                  <path
                    d="M0.272335669,5.10224148 C0.272335669,5.10224148 0.638266522,6.58376338 1.99191793,8.24738505 C3.05326378,9.56388704 5.02950994,11.7769168 7.00480468,13.1848161 C8.61504681,14.3370296 9.89584139,14.7206132 9.89584139,14.7206132 C11.0665274,15.21421 11.8533885,14.903481 11.8533885,14.903481 C12.4208741,14.7391852 12.9325918,14.3548775 13.3900053,13.9891419 C13.9024549,13.5683996 14.3963152,13.1112301 14.817282,12.6176333 C14.9637971,12.4347655 15.0366942,12.270477 14.9816655,12.0326026 C14.7801108,11.575433 13.3535659,10.2767789 12.9690458,9.96601336 C12.6945976,9.74671828 12.4387388,9.50884382 12.1457014,9.32597601 C12.0177683,9.25311415 11.8898425,9.17953912 11.7611812,9.14310819 C11.4681438,9.08810521 11.2673209,9.36240328 11.0843555,9.54527109 C10.737014,9.89242735 10.1330817,10.7524913 9.74856154,10.8617731 C9.3640414,10.9896379 8.28483813,10.2767424 7.93749656,9.98386126 C6.784668,9.03309491 5.55894602,7.84449068 5.01004974,7.0579762 C4.75419089,6.69224057 4.00373989,5.63146094 4.13166932,5.24787741 C4.241734,4.88214177 5.10153245,4.26068379 5.44887402,3.91279605 C5.63183944,3.72992824 5.90628758,3.54706042 5.85125158,3.23633143 C5.83266961,3.08989454 5.75976887,2.9620297 5.66828616,2.85201642 C5.48532073,2.55913533 5.2473193,2.2848336 5.02790716,2.02911125 C4.71701231,1.64479625 3.41766504,0.219012456 2.96021488,0.0175652695 C2.74222987,-0.0360095146 2.55926445,0.0368523381 2.37629902,0.183289228 C1.86384945,0.6040315 1.40643589,1.09762831 1.00405832,1.60980449 C0.638127468,2.06697403 0.253607328,2.57915021 0.0892311894,3.14560156 C0.0892311894,3.16417361 -0.203806238,3.95064055 0.272196616,5.10210433 L0.272335669,5.10224148 Z"
                    
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export default Phone;
