import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import PageHero from "components/page-hero";
import PageLoading from "components/page-loading";
import NotFound from "pages/not-found";
import Notch from "components/notch";
import Grid from "components/grid";
import { Helmet } from "react-helmet";

//styles
import styles from "./styles.scss";

//decorators
@inject("defaultPageStore")
@observer
class Default extends Component {
  componentDidMount() {
    this.props.defaultPageStore.getPageDetails(this.props.match.params.page);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.match.params.page !== nextProps.match.params.page) {
      this.props.defaultPageStore.getPageDetails(nextProps.match.params.page);
    }
  }

  render() {
    const { response, isLoading } = this.props.defaultPageStore;

    return (
      <React.Fragment>
        {response ? (
          <React.Fragment>
            <Helmet>
              <meta charSet="utf-8" />
              <title>{response.seo.siteTitle}</title>
              <meta name="description" content={response.seo.description} />
              <meta property="fb:pages" content="828024637343069" />
              <meta property="og:title" content={response.seo.title} />
              <meta
                property="og:description"
                content={response.seo.description}
              />
              <meta
                property="og:url"
                content={`${process.env.REACT_APP_SITE_URL}${window.location.pathname}`}
              />
              <meta property="og:image" content={response.seo.image} />
              <meta property="og:site_name" content={response.seo.siteName} />
            </Helmet>
            <PageHero
              loading={isLoading}
              title={`<b>${response.title}</b>`}
              image={response.image}
            />
            <Notch variants={["right"]} />
            <div className="wrapper">
              <div className={styles.content}>
                <p
                  className={styles.intro}
                  dangerouslySetInnerHTML={{ __html: response.intro }}
                />
                <article
                  className={styles.description}
                  dangerouslySetInnerHTML={{ __html: response.content }}
                />
              </div>
            </div>
            <Grid />
          </React.Fragment>
        ) : response === false ? (
          <NotFound loading={isLoading} />
        ) : (
          <PageLoading color="#0078e6" />
        )}
      </React.Fragment>
    );
  }
}

export default Default;
