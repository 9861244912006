import React from 'react';

const Shield = props => (
  <svg
    {...props}
    width="110px"
    height="104px"
    viewBox="0 0 110 104"
    version="1.1">
    <defs>
      <polygon
        id="path-1"
        points="0 0.486 90.99 0.486 90.99 102.5 0 102.5"></polygon>
    </defs>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g>
        <g>
          <g transform="translate(0.000000, 0.013000)">
            <g>
              <mask id="mask-2" fill="white">
                <use xlinkHref="#path-1"></use>
              </mask>
              <g id="a"></g>
              <path
                d="M84.24,42.562 C84.24,66.629 68.363,88.234 45.506,95.452 C22.636,88.23 6.75,66.626 6.75,42.561 L6.75,7.236 L84.242,7.236 L84.242,42.561 L84.24,42.562 Z M2.16840434e-19,0.486 L2.16840434e-19,42.561 C2.16840434e-19,69.928 18.319,94.461 44.55,102.215 L45.506,102.499 L46.463,102.215 C72.68,94.465 90.991,69.933 90.991,42.561 L90.991,0.486 L-0.001,0.486 L2.16840434e-19,0.486 Z"
                fill="#FFFFFF"
                fillRule="nonzero"
                mask="url(#mask-2)"></path>
            </g>
          </g>
          <polygon
            fill="#FFFFFF"
            fillRule="nonzero"
            points="46.938 58.796 47.353 58.381 66.77 38.963 63.9 36.093 61.029 33.221 44.068 50.183 35.255 41.373 32.385 44.243 29.513 47.114 44.068 61.667"></polygon>
        </g>
      </g>
    </g>
  </svg>
);
export default Shield;
