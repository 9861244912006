import React from 'react';

const Mop = props => (
  <svg {...props} width="100" height="101" viewBox="0 0 100 101">
    <g fill="none" fillRule="evenodd">
      <path
        fill={props.fill ? props.fill : '#0078E6'}
        d="M71.517 76.343V53.804h-4.5v22.54h-4.623v-22.54h-4.5v22.54H53.27v-22.54h-4.5v22.54h-5.374l1.537-26.33a3.65 3.65 0 0 1 3.642-3.435h23.137a3.65 3.65 0 0 1 3.64 3.435l1.539 26.33h-5.375zM54.97 37.176h5.173l5.173.02v4.883H54.97v-4.903zM57.838 4.5h4.613v28.176h-2.307l-2.306-.008V4.5zm13.875 37.58h-1.896v-9.404H66.95V0H53.338v32.676H50.47v9.403h-1.895a8.154 8.154 0 0 0-8.134 7.672l-1.815 31.092h43.035l-1.816-31.09a8.154 8.154 0 0 0-8.133-7.674z"
      />
      <path
        fill={props.fill ? props.fill : '#0078E6'}
        d="M65.635 85.844a6.183 6.183 0 0 1-3.353-.992l-2.961-1.907-.485 3.49c-.421 3.032-3.056 5.32-6.131 5.32a6.215 6.215 0 0 1-5.864-4.179l-.762-2.192-2.168.83a8.088 8.088 0 0 1-2.896.53c-4.494 0-8.151-3.656-8.151-8.15 0-2.6 1.198-4.984 3.287-6.54l-2.69-3.608a12.715 12.715 0 0 0-5.097 10.148c0 6.975 5.675 12.65 12.65 12.65.84 0 1.669-.082 2.479-.243a10.72 10.72 0 0 0 9.212 5.253c4.305 0 8.114-2.602 9.787-6.385a10.671 10.671 0 0 0 11.365-3.38L70.4 83.61a6.189 6.189 0 0 1-4.765 2.235"
      />
      <g transform="translate(85 65.663)">
        <mask id="b" fill="#fff">
          <path id="a" d="M.274.68h14.5v14.5H.274z" />
        </mask>
        <path
          fill={props.fill ? props.fill : '#0078E6'}
          d="M7.524 10.68a2.752 2.752 0 0 1-2.75-2.75 2.752 2.752 0 0 1 2.75-2.75 2.752 2.752 0 0 1 2.75 2.75 2.752 2.752 0 0 1-2.75 2.75m0-10C3.526.68.274 3.932.274 7.93s3.252 7.25 7.25 7.25 7.25-3.252 7.25-7.25S11.522.68 7.524.68"
          mask="url(#b)"
        />
      </g>
      <path
        fill={props.fill ? props.fill : '#0078E6'}
        d="M27.864 56.909a2.752 2.752 0 0 1 2.75-2.75 2.752 2.752 0 0 1 2.75 2.75 2.752 2.752 0 0 1-2.75 2.75 2.752 2.752 0 0 1-2.75-2.75m10 0c0-3.998-3.252-7.25-7.25-7.25s-7.25 3.252-7.25 7.25 3.252 7.25 7.25 7.25 7.25-3.252 7.25-7.25M39.764 96.163a2.25 2.25 0 0 0 0 4.5h.005a2.248 2.248 0 0 0 2.247-2.25c0-1.243-1.01-2.25-2.252-2.25"
      />
      <mask id="d" fill="#fff">
        <path id="c" d="M0 100.663h99.774V0H0z" />
      </mask>
      <path
        fill={props.fill ? props.fill : '#0078E6'}
        d="M85.024 32.693h5v-5h-5zM85.024 42.693h5v-5h-5zM90.024 37.693h5v-5h-5zM80.024 37.693h5v-5h-5zM18.827 76.567v4.5h4.51l-.01-4.5zM9.408 76.567v4.5h4.521l-.02-4.5zM0 76.567v4.5h4.51l-.01-4.5z"
        mask="url(#d)"
      />
    </g>
  </svg>
);
export default Mop;
