import React, { Component } from 'react';
import styles from './styles.scss';
import Loading from 'components/loading';
class PageLoading extends Component {
  render() {
    const { color } = this.props;
    return (
      <div className={styles.loading}>
        <Loading color={color} />
      </div>
    );
  }
}

export default PageLoading;
