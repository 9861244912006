import React from 'react';
import { observer } from 'mobx-react';

// styles
import styles from './styles.scss';

export default observer(
  ({ field, type = 'text', placeholder = null, autocomplete, label }) => (
    <label htmlFor={field.id} className={styles.label}>
      {label}
      <input
        autoComplete={autocomplete}
        {...field.bind({ type, placeholder })}
        className={[
          styles.input,
          field.changed
            ? !field.isValid
              ? styles.invalid
              : styles.valid
            : null,
        ].join(' ')}
        type={type}
      />
    </label>
  )
);
