import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import Notch from 'components/notch';
import styles from './styles.scss';
import SimpleContent from 'components/simple-content';

// icons
import Compass from 'icons/go/compass';
import Chat from 'icons/go/chat';
import Flange from 'icons/go/flange';
import Helmet from 'icons/go/helmet';
import Platform from 'icons/go/platform';
import Question from 'icons/go/question';
import Shield from 'icons/go/shield';

// decorators
@inject('goStore')
@observer
class GoIcons extends Component {
  render() {
    const { details } = this.props;
    return (
      <div className={styles.textblock}>
        <Notch variants={['blue', 'left', 'half']} />
        <div className="wrapper">
          <SimpleContent details={details.default} type="blue" />
          {details.items && (
            <div class={styles.list}>
              {details.items.map((item, i) => {
                return (
                  <div className={styles.item} key={i}>
                    {(() => {
                      switch (item.icon) {
                        case 'compass':
                          return <Compass className={styles.icon} />;
                        case 'chat':
                          return <Chat className={styles.icon} />;
                        case 'flange':
                          return <Flange className={styles.icon} />;
                        case 'helmet':
                          return <Helmet className={styles.icon} />;
                        case 'platform':
                          return <Platform className={styles.icon} />;
                        case 'question':
                          return <Question className={styles.icon} />;
                        case 'shield':
                          return <Shield className={styles.icon} />;
                        default:
                          return null;
                      }
                    })()}

                    <div className={styles.text}>
                      <span
                        className={styles.title}
                        dangerouslySetInnerHTML={{ __html: item.text }}
                      />
                      <span
                        className={styles.description}
                        dangerouslySetInnerHTML={{ __html: item.description }}
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default GoIcons;
