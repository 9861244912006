import React, { Component } from "react";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";

//styles
import styles from "./styles.scss";

//icons
import ArrowRight from "icons/arrow-right";

// decorators
@observer
class RelatedVacanciesItem extends Component {
  render() {
    const { item, t } = this.props;
    return (
      item && (
        <Link to={`/blog/${item.slug}`} className={styles.item}>
          <img className={styles.image} src={item.image} alt={item.title} />
          <div className={styles.content}>
            <h4
              className={styles.title}
              dangerouslySetInnerHTML={{ __html: item.title }}
            />
            <span className={styles.subtitle}>
              {item.sector && item.sector.replace(/\./g, "")}{" "}
              {item.sector && item.area ? `/ ${item.area}` : item.area}
            </span>
            <div className={styles.description}>
              <p dangerouslySetInnerHTML={{ __html: item.excerpt }} />
              <span className={styles.link}>
                {t('Read blog')}
                <ArrowRight />
              </span>
            </div>
          </div>
        </Link>
      )
    );
  }
}

export default withNamespaces()(RelatedVacanciesItem);
