import React, { Component } from 'react';
import styles from './styles.scss';

class Checkbox extends Component {
  render() {
    const { children, onClick, active } = this.props;
    return (
      <div
        className={[styles.radio, active ? styles['is-active'] : null].join(
          ' '
        )}
        onClick={onClick}>
        <span />
        <input type="checkbox" value="0" />
        <label>{children}</label>
      </div>
    );
  }
}

export default Checkbox;
