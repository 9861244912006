import React from 'react';

const Work = props => (
  <svg {...props} width="434px" height="234px" viewBox="0 0 434 234">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g  fillRule="nonzero" fill="#FFFFFF">
        <g transform="translate(114.000000, 5.000000)">
          <path d="M173.31,57.73 L178.26,52.73 C163.818965,36.796368 144.706951,25.8402137 123.66,21.43 L123.66,28.59 C142.825601,32.9342115 160.172324,43.1151479 173.31,57.73 L173.31,57.73 Z" />
          <path d="M197.73,121.2 C197.740234,171.305266 158.784442,212.779804 108.776714,215.904696 C58.768986,219.029587 14.9530625,182.727302 8.72560111,133.010539 C2.4981397,83.2937756 36.006503,37.305944 85.24,28 L85.24,20.86 C41.9881283,28.4365774 8.45095959,62.8631029 2.00875034,106.298421 C-4.43345892,149.733739 17.6676782,192.412388 56.8584987,212.216644 C96.0493192,232.020899 143.518642,224.498215 174.666029,193.547138 C205.813417,162.596061 213.636075,115.17525 194.08,75.86 L188.84,81.1 C194.714962,93.6506775 197.75035,107.342357 197.73,121.2 L197.73,121.2 Z" />
          <path d="M82.33,84.51 C74.5088151,84.5172962 68.0453547,90.6126681 67.58,98.42 L64.16,157 L144.73,157 L141.31,98.41 C140.849503,90.6003994 134.383165,84.5023143 126.56,84.5 L122.14,84.5 L122.14,66.5 L116.66,66.5 L116.66,0.11 L92.24,0.11 L92.24,66.54 L86.76,66.54 L86.76,84.54 L82.33,84.51 Z M99.24,66.51 L99.24,7.11 L109.66,7.11 L109.66,66.54 L104.45,66.54 L99.24,66.51 Z M93.76,80.51 L93.76,73.51 L104.45,73.51 L115.14,73.51 L115.14,84.51 L93.76,84.51 L93.76,80.51 Z M122.14,91.51 L126.56,91.51 C130.673667,91.5183486 134.071815,94.7238176 134.32,98.83 L137.32,150.01 L125.39,150.01 L125.39,106.13 L118.39,106.13 L118.39,150 L108,150 L108,106.13 L101,106.13 L101,150 L90.51,150 L90.51,106.13 L83.51,106.13 L83.51,150 L71.58,150 L74.58,98.82 C74.8281851,94.7138176 78.2263325,91.5083486 82.34,91.5 L122.15,91.5 L122.14,91.51 Z" />
          <path d="M114.94,168.18 C112.514885,168.186564 110.139563,167.492025 108.1,166.18 L103.5,163.18 L102.74,168.6 C101.932917,174.39 97.2634989,178.872809 91.4454127,179.44321 C85.6273266,180.01361 80.1762019,176.523011 78.26,171 L77.07,167.59 L73.7,168.88 C71.8422101,169.591189 69.8692608,169.953914 67.88,169.95 C60.8196863,169.942873 54.5573704,165.415244 52.3376433,158.712939 C50.1179161,152.010634 52.439681,144.640056 58.1,140.42 L53.92,134.81 C45.1508627,141.429907 42.1714215,153.260997 46.7592685,163.244614 C51.3471154,173.22823 62.2653736,178.672805 73,176.33 C76.6760528,182.981558 83.8580887,186.917001 91.442611,186.43575 C99.0271334,185.954499 105.654151,181.142846 108.46,174.08 C116.197363,176.784316 124.800444,174.391248 130.03,168.08 L124.65,163.6 C122.254687,166.493662 118.696426,168.172017 114.94,168.18 Z" />
          <path d="M166.35,157 C171.637725,156.999944 176.403627,153.811446 178.421501,148.923889 C180.439374,144.036331 179.310975,138.414316 175.56339,134.683948 C171.815805,130.95358 166.188654,129.851081 161.31044,131.891439 C156.432226,133.931797 153.265707,138.712331 153.29,144 C153.328491,151.187111 159.162788,156.994604 166.35,157 Z M166.35,137.89 C169.696846,137.89 172.41,140.603154 172.41,143.95 C172.41,147.296846 169.696846,150.01 166.35,150.01 C163.003154,150.01 160.29,147.296846 160.29,143.95 C160.295516,140.60706 163.007056,137.899995 166.35,137.9 L166.35,137.89 Z" />
          <path d="M61.06,112.06 C61.06,104.847161 55.2128388,99 48,99 C40.7871612,99 34.94,104.847161 34.94,112.06 C34.94,119.272839 40.7871612,125.12 48,125.12 C55.208272,125.108987 61.0489866,119.268272 61.06,112.06 Z M41.94,112.06 C41.94,108.713154 44.6531544,106 48,106 C51.3468456,106 54.06,108.713154 54.06,112.06 C54.06,115.406846 51.3468456,118.12 48,118.12 C44.6554375,118.114495 41.9455055,115.404562 41.94,112.06 L41.94,112.06 Z" />
          <path d="M65.49,186.5 C62.7838047,186.5 60.59,188.693805 60.59,191.4 C60.59,194.106195 62.7838047,196.3 65.49,196.3 L65.49,196.3 C68.1961953,196.3 70.39,194.106195 70.39,191.4 C70.39,188.693805 68.1961953,186.5 65.49,186.5 Z" />
          <polygon points="206.01 54.06 196.44 44.49 182.8 58.13 177.82 63.12 164.56 76.38 149.88 61.7 140.32 71.27 164.56 95.5 185.47 74.6 190.59 69.48" />
        </g>
      </g>
    </g>
  </svg>
);
export default Work;
