import React from 'react';

const Clock = props => (
  <svg {...props} width="434px" height="234px" viewBox="0 0 434 234">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g fillRule="nonzero" fill="#FFFFFF">
        <g id="clock" transform="translate(54.000000, 8.000000)">
          <path d="M234.79,115 C230.99325,109.002852 223.361783,106.714269 216.891439,109.632443 C210.421095,112.550618 207.084876,119.785709 209.066796,126.601359 C211.048715,133.41701 217.744425,137.734922 224.770667,136.728431 C231.796909,135.72194 237.011169,129.697955 237,122.6 C237,122.26 237,121.93 236.95,121.6 L290.57,104 L288.38,97.36 L234.79,115 Z" />
          <path d="M226.26,60.45 L226.26,40.93 C225.09,40.93 223.93,40.84 222.76,40.84 C221.59,40.84 220.42,40.84 219.26,40.93 L219.26,60.45 L226.26,60.45 Z" />
          <path d="M226.26,19.91 L226.26,7 L245.42,7 L245.42,0 L200.09,0 L200.09,7 L219.26,7 L219.26,19.91 C220.42,19.91 221.58,19.84 222.76,19.84 C223.94,19.84 225.09,19.87 226.26,19.91 Z" />
          <path d="M284.9,126.1 L304.42,126.1 C304.47,124.93 304.51,123.77 304.51,122.6 C304.51,121.43 304.51,120.26 304.42,119.1 L284.9,119.1 L284.9,126.1 Z" />
          <path d="M219.26,184.74 L219.26,204.26 C220.42,204.31 221.58,204.35 222.76,204.35 C223.94,204.35 225.09,204.35 226.26,204.26 L226.26,184.74 L219.26,184.74 Z" />
          <path d="M176.34,164.06 L162.56,177.84 C164.14,179.56 165.79,181.21 167.51,182.79 L181.29,169 L176.34,164.06 Z" />
          <path d="M160.61,119.1 L141.09,119.1 C141.04,120.26 141,121.42 141,122.6 C141,123.78 141,124.93 141.09,126.1 L160.61,126.1 L160.61,119.1 Z" />
          <path d="M181.29,76.18 L167.51,62.4 C165.79,63.98 164.14,65.63 162.56,67.35 L176.34,81.13 L181.29,76.18 Z" />
          <polygon points="29.55 62.41 29.55 55.41 22.54 55.41 22.55 62.41" />
          <polygon points="108.1 62.41 108.1 55.41 101.09 55.41 101.1 62.41" />
          <polygon points="60.97 62.41 60.97 55.41 53.95 55.41 53.97 62.41" />
          <polygon points="76.68 62.41 76.68 55.41 69.67 55.41 69.68 62.41" />
          <polygon points="45.26 62.41 45.26 55.41 38.24 55.41 38.26 62.41" />
          <polygon points="92.39 62.41 92.39 55.41 85.38 55.41 85.39 62.41" />
          <polygon points="123.81 55.41 116.8 55.41 116.81 62.41 123.81 62.41" />
          <rect
            id="Rectangle-path"
            x="13.94"
            y="182.78"
            width="79.71"
            height="7"
          />
          <rect
            id="Rectangle-path"
            x="55.3"
            y="150.94"
            width="56.21"
            height="7"
          />
          <rect
            id="Rectangle-path"
            x="43.35"
            y="87.25"
            width="56.21"
            height="7"
          />
          <polygon points="0.01 126.09 7.01 126.09 7.01 119.09 0 119.09" />
          <polygon points="54.83 126.09 54.83 119.09 47.81 119.09 47.83 126.09" />
          <polygon points="38.89 126.09 38.89 119.09 31.87 119.09 31.89 126.09" />
          <polygon points="22.95 126.09 22.95 119.09 15.93 119.09 15.95 126.09" />
          <polygon points="70.77 126.09 70.77 119.09 63.76 119.09 63.77 126.09" />
          <polygon points="86.71 126.09 86.71 119.09 79.7 119.09 79.71 126.09" />
          <path d="M264.22,169 L278,182.79 C279.72,181.21 281.38,179.56 282.95,177.84 L269.17,164.06 L264.22,169 Z" />
          <path d="M293.62,30.17 L315.18,51.73 L305.36,61.55 C306.766667,63.4633333 308.1,65.4166667 309.36,67.41 L325.04,51.73 L293.62,20.27 L277.94,36 C279.94,37.28 281.94,38.63 283.8,40 L293.62,30.17 Z" />
          <path d="M222.76,26.84 C184.027786,26.8359553 149.107146,50.1645656 134.282166,85.9473049 C119.457187,121.730044 127.647538,162.919767 155.033919,190.309009 C182.4203,217.698251 223.609168,225.892903 259.393455,211.07166 C295.177743,196.250418 318.51,161.332215 318.51,122.6 C318.449393,69.7422402 275.617753,26.9061271 222.76,26.84 Z M311.51,122.6 C311.51,158.500979 289.883238,190.866728 256.714671,204.604504 C223.546105,218.342279 185.368062,210.746517 159.983666,185.359262 C134.599271,159.972006 127.007811,121.793107 140.749323,88.6260889 C154.490836,55.4590704 186.859021,33.8359553 222.76,33.84 C271.756326,33.8951199 311.4604,73.6036686 311.51,122.6 L311.51,122.6 Z" />
        </g>
      </g>
    </g>
  </svg>
);
export default Clock;
