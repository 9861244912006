import React from "react";

const year10 = (props) => (
  <svg {...props} viewBox="0 0 289.91 408.12" xmlns="http://www.w3.org/2000/svg">
    <g stroke="#fff" stroke-miterlimit="10">
      <g fill="none" stroke-linecap="square" stroke-width="17">
        <path d="m64.45 235.82-41.51 120.88 54.88-15.24 31.23 47.63 46.41-123.27" />
        <path d="m144.96 304.32 38.22 85 28.03-49.58 55.76 11.59-49.01-109.01" />
        <path d="m154.22 19 17.12-8.36a21.09 21.09 0 0 1 27.9 9.06l8.93 16.83a21.12 21.12 0 0 0 15 10.9l18.77 3.29a21.08 21.08 0 0 1 17.24 23.73l-2.68 18.88a21.09 21.09 0 0 0 5.73 17.67l13.25 13.69a21.1 21.1 0 0 1 0 29.34l-13.25 13.7a21.08 21.08 0 0 0 -5.73 17.62l2.67 18.86a21.09 21.09 0 0 1 -17.24 23.74l-18.77 3.29a21.1 21.1 0 0 0 -15 10.89l-8.93 16.83a21.09 21.09 0 0 1 -27.9 9.07l-17.12-8.37a21.08 21.08 0 0 0 -18.53 0l-17.11 8.34a21.09 21.09 0 0 1 -27.9-9.07l-8.93-16.83a21.1 21.1 0 0 0 -15-10.89l-18.74-3.31a21.11 21.11 0 0 1 -17.24-23.74l2.67-18.86a21.08 21.08 0 0 0 -5.73-17.62l-13.27-13.68a21.1 21.1 0 0 1 0-29.34l13.25-13.66a21.09 21.09 0 0 0 5.73-17.62l-2.67-18.92a21.1 21.1 0 0 1 17.26-23.73l18.77-3.29a21.12 21.12 0 0 0 15-10.9l8.93-16.83a21.09 21.09 0 0 1 27.9-9.06l17.09 8.35a21.08 21.08 0 0 0 18.53 0z" />
      </g>
      <path
        d="m105.1 116.64-20.1 7.17v-15.56l35.69-12.61v67.45h18.31v14.8h-53.1v-14.8h19.2z"
        fill="#fff"
        stroke-width="3"
      />
      <path
        d="m210.2 137.4c0 30-14.23 41.94-31.36 41.94-17.35 0-31.87-11.92-31.87-41.94s14.52-41.94 31.87-41.94c17.16 0 31.36 13.02 31.36 41.94zm-47.09 0c0 20.36 7.35 27 15.73 27 8.1 0 15.28-6.6 15.28-27 0-19.2-7.18-26.95-15.28-26.95-8.38 0-15.73 6.55-15.73 26.95z"
        fill="#fff"
        stroke-width="3"
      />
    </g>
  </svg>
);
export default year10;
