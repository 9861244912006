import React, { Component } from 'react';

// styles
import styles from './styles.scss';

class GridTest extends Component {
  classNames(variants) {
    let classes = [];
    classes.push('blueprint');
    classes.push(styles['blueprint']);
    if (variants) {
      variants.forEach(function(variant) {
        classes.push(styles[`blueprint--${variant}`]);
      });
    }
    return classes.join(' ');
  }
  componentDidMount() {}
  render() {
    const { variants } = this.props;
    return (
      <div className={this.classNames(variants)}>
        <span />
        <span />
        <span />
        <span />
        <span />
        <span />
        <span />
        <span />
        <span />
        <span />
        <span />
        <span />
        <span />
        <span />
        <span />
        <span />
        <span />
        <span />
        <span />
        <span />
      </div>
    );
  }
}

export default GridTest;
