import React from 'react';

const WellDone = props => (
  <svg {...props} width="434px" height="234px" viewBox="0 0 434 234">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g  fillRule="nonzero" fill="#FFFFFF">
        <g id="well-done" transform="translate(118.000000, -21.000000)">
          <polygon points="146.43 27.85 146.43 34.85 191.28 34.85 191.28 268.29 7 268.29 7 34.85 51.85 34.85 51.85 27.85 0 27.85 0 275.29 198.28 275.29 198.28 27.85" />
          <polygon points="18.41 250.08 179.87 250.08 179.87 46.26 146.43 46.26 146.43 53.26 172.87 53.26 172.87 243.08 25.41 243.08 25.41 53.26 51.85 53.26 51.85 46.26 18.41 46.26" />
          <path d="M87,21 L58.85,21 L58.85,60 L139.43,60 L139.43,21 L111.24,21 C112.473806,18.8736182 113.122463,16.4584084 113.12,14 L113.12,14 C113.12,6.2680135 106.851986,-3.07926605e-15 99.12,-3.55271368e-15 C91.3880135,-4.0261613e-15 85.12,6.2680135 85.12,14 L85.12,14 C85.1175372,16.4584084 85.7661936,18.8736182 87,21 Z M132.39,53 L65.85,53 L65.85,28 L132.43,28 L132.39,53 Z M99.14,7 C103.005993,7 106.14,10.1340068 106.14,14 L106.14,14 C106.14,17.8659932 103.005993,21 99.14,21 C95.2740068,21 92.14,17.8659932 92.14,14 L92.14,14 C92.14,10.1340068 95.2740068,7 99.14,7 L99.14,7 Z" />
          <polygon points="84.88 93.26 84.88 89.76 84.88 86.26 77.88 86.26 77.88 93.26" />
          <polygon points="120.88 93.26 120.88 89.76 120.88 86.26 113.86 86.26 113.87 89.76 113.88 93.26" />
          <polygon points="138.88 93.26 138.88 89.76 138.88 86.26 131.86 86.26 131.87 89.76 131.88 93.26" />
          <polygon points="102.88 93.26 102.88 89.76 102.88 86.26 95.86 86.26 95.87 89.76 95.88 93.26" />
          <polygon points="156.87 93.26 156.87 86.26 149.86 86.26 149.86 89.76 149.87 93.26" />
          <polygon points="66.55 86.31 61.66 81.3 51.91 90.8 47.38 86.29 42.44 91.25 51.86 100.63" />
          <polygon points="84.88 133.54 84.88 130.04 84.88 126.54 77.88 126.54 77.88 133.54" />
          <polygon points="102.88 133.54 102.88 130.04 102.88 126.54 95.86 126.54 95.87 130.04 95.88 133.54" />
          <polygon points="120.88 133.54 120.88 130.04 120.88 126.54 113.86 126.54 113.87 130.04 113.88 133.54" />
          <polygon points="138.88 133.54 138.88 130.04 138.88 126.54 131.86 126.54 131.87 130.04 131.88 133.54" />
          <polygon points="156.87 133.54 156.87 126.54 149.86 126.54 149.86 130.04 149.87 133.54" />
          <polygon points="66.55 125.25 61.66 120.24 51.91 129.74 47.38 125.23 42.44 130.19 51.86 139.57" />
          <polygon points="84.88 173.82 84.88 170.32 84.88 166.82 77.88 166.82 77.88 173.82" />
          <polygon points="102.88 173.82 102.88 170.32 102.88 166.82 95.86 166.82 95.87 170.32 95.88 173.82" />
          <polygon points="120.88 173.82 120.88 170.32 120.88 166.82 113.86 166.82 113.87 170.32 113.88 173.82" />
          <polygon points="138.88 173.82 138.88 170.32 138.88 166.82 131.86 166.82 131.87 170.32 131.88 173.82" />
          <polygon points="156.87 173.82 156.87 166.82 149.86 166.82 149.86 170.32 149.87 173.82" />
          <polygon points="66.55 164.19 61.66 159.18 51.91 168.68 47.38 164.17 42.44 169.13 51.86 178.51" />
          <polygon points="84.88 212.76 84.88 209.26 84.88 205.76 77.88 205.76 77.88 212.76" />
          <polygon points="138.88 212.76 138.88 209.26 138.88 205.76 131.86 205.76 131.87 209.26 131.88 212.76" />
          <polygon points="120.88 212.76 120.88 209.26 120.88 205.76 113.86 205.76 113.87 209.26 113.88 212.76" />
          <polygon points="102.88 212.76 102.88 209.26 102.88 205.76 95.86 205.76 95.87 209.26 95.88 212.76" />
          <polygon points="156.87 212.76 156.87 205.76 149.86 205.76 149.86 209.26 149.87 212.76" />
          <polygon points="66.55 203.13 61.66 198.12 51.91 207.62 47.38 203.11 42.44 208.07 51.86 217.44" />
        </g>
      </g>
    </g>
  </svg>
);
export default WellDone;
