import React, { Component } from 'react';

// styles
import styles from './styles.scss';

// components

// icons

class Grid extends Component {
  render() {
    const { children } = this.props;
    return (
      <div className={styles.grid}>
        <div className={styles.blueprint} />
        <div className={['wrapper', styles.wrapper].join(' ')}>{children}</div>
      </div>
    );
  }
}

export default Grid;
