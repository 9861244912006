import React from 'react';

const Arrow = props => (
  <svg {...props}
    width="17"
    height="10"
    viewBox="0 0 17 10"
    // fill="#0078e6"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M-0.000165785 8.0581L1.81922 9.8208L8.31779 3.52544L14.8164 9.8208L16.6357 8.0581L10.1372 1.76275L8.31779 4.98176e-05L6.49841 1.76275L-0.000165785 8.0581Z" />
  </svg>
);

export default Arrow;
