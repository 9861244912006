import React from 'react';

const FlagRomanian = props => (
  <svg
    {...props}
    width="30"
    height="23"
    viewBox="0 0 30 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <rect x="0.500244" y="0.5" width="29" height="22" stroke="white"/>
    <rect x="29" y="1.00195" width="21" height="9" transform="rotate(90 29 1.00195)" fill="#FD5858"/>
    <rect x="20" y="1.00195" width="21" height="10" transform="rotate(90 20 1.00195)" fill="#EDCD28"/>
    <rect x="10" y="1.00195" width="21" height="9" transform="rotate(90 10 1.00195)" fill="#104295"/>
  </svg>

);

export default FlagRomanian;
