import React, { Component } from 'react';
import { withNamespaces } from "react-i18next";
// components
import Item from 'components/related-vacancies-item';

// styles
import styles from './styles.scss';

class RelatedVacancies extends Component {
  render() {
    const { items, t } = this.props;
    return (
      <div className={styles['related-wrap']}>
        <h2 className={styles.title}>{t('Other job offers')}</h2>
        <div className={styles.related}>
          {items &&
            items.map((item, i) => {
              return <Item item={item} key={i} />;
            })}
        </div>
      </div>
    );
  }
}

export default withNamespaces()(RelatedVacancies);
