import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Helmet } from 'react-helmet';
import { withNamespaces } from 'react-i18next';

// styles
import styles from './styles.scss';

// components
import Filter from 'components/filter';
import Pagination from 'components/pagination';
import VacancyGrid from 'components/vacancy-grid';
import VacancyGridItem from 'components/vacancy-grid-item';
import ImageCta from 'components/image-cta';
import Loading from 'components/loading';
import GridTest from 'components/grid-test';

// store
import { history } from 'store';

// helpers
import { trackPage } from 'helpers';

// decorators
@inject('vacancyStore')
@observer
class VacancyOverview extends Component {
    componentDidMount() {
        trackPage(this.props.location.pathname);

        if (this.props.match.params.category) {
            if (this.props.match.params.page) {
                this.props.vacancyStore.getVacancyOverview(this.props.match.params.page);
                this.props.vacancyStore.getVacancyItems(this.props.match.params.page, false);
            } else {
                this.props.vacancyStore.getFilterCategoriesResponse().then((res) => {
                    let filter = res.sector.filter((item) => {
                        return item.slug === this.props.match.params.category;
                    })[0];
                    if (filter) {
                        this.props.vacancyStore.addItemValue(0, filter.id, filter.slug, filter.name);
                        this.props.vacancyStore.getVacancyOverview(this.props.match.params.page);
                        this.props.vacancyStore.getVacancyItems(this.props.match.params.page, false);
                    } else {
                        history.push('/vacatures/');
                    }
                });
            }
        } else {
            this.props.vacancyStore.getVacancyOverview(this.props.match.params.page);
            this.props.vacancyStore.getVacancyItems(this.props.match.params.page, false);
            if (this.props.match.params.page > 0) {
                this.props.vacancyStore.setCurrentOverviewPage(`/vacatures/pagina/${this.props.match.params.page}`);
            } else {
                this.props.vacancyStore.setCurrentOverviewPage('/vacatures');
            }
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const currentParams = this.props.match.params;
        const nextParams = nextProps.match.params;
        if (currentParams.page !== nextParams.page) {
            this.props.vacancyStore.getVacancyItems(nextParams.page, false);
        }
        if (nextProps.location.pathname !== this.props.location.pathname) {
            trackPage(nextProps.location.pathname);
        }
    }

    render() {
        const { t } = this.props;
        const { overviewResponse, overviewItemsResponse, overviewItemsTotal, overviewItemsLoading } = this.props.vacancyStore;
        return (
            <React.Fragment>
                <div className={styles.overview}>
                    <div className={[styles.wrapper, 'wrapper'].join(' ')}>
                        <Filter />
                        {overviewItemsLoading ? (
                            <Loading />
                        ) : (
                            <React.Fragment>
                                {overviewItemsResponse && overviewItemsResponse.items ? (
                                    <React.Fragment>
                                        <h1 className={styles.title}>
                                            {overviewItemsTotal} {overviewItemsTotal > 1 ? t('Vacancies') : t('Vacancy')}
                                            <br />
                                            <em>{t('Press')}</em>
                                        </h1>
                                        <VacancyGrid>
                                            {overviewItemsResponse.items.map((item, i) => {
                                                return <VacancyGridItem delay={i * 100} item={item} key={i} />;
                                            })}
                                        </VacancyGrid>
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <h1 className={[styles.title, styles['title--no-result']].join(' ')}>{t('No vacancy  found')}</h1>
                                    </React.Fragment>
                                )}
                                {overviewItemsResponse && overviewItemsResponse.total > 9 && (
                                    <Pagination
                                        total={overviewItemsResponse.total}
                                        perPage={9}
                                        currentPage={
                                            this.props.match.params.page === undefined ||
                                            window.location.pathname === '/vacatures/' ||
                                            window.location.pathname === '/vacatures'
                                                ? 1
                                                : parseInt(this.props.match.params.page)
                                        }
                                        base={
                                            this.props.match.params.category
                                                ? `/vacatures/${this.props.match.params.category}/pagina`
                                                : '/vacatures/pagina'
                                        }
                                    />
                                )}
                            </React.Fragment>
                        )}
                    </div>
                </div>
                {overviewResponse && (
                    <React.Fragment>
                        <Helmet>
                            <meta charSet="utf-8" />
                            <title>{overviewResponse.seo.siteTitle}</title>
                            <meta name="description" content={overviewResponse.seo.description} />
                            <meta property="fb:pages" content="828024637343069" />
                            <meta property="og:title" content={overviewResponse.seo.title} />
                            <meta property="og:description" content={overviewResponse.seo.description} />
                            <meta property="og:url" content={`${process.env.REACT_APP_SITE_URL}${window.location.pathname}`} />
                            <meta property="og:image" content={overviewResponse.seo.image} />
                            <meta property="og:site_name" content={overviewResponse.seo.siteName} />
                        </Helmet>
                        <ImageCta
                            title={overviewResponse.cta.title}
                            description={overviewResponse.cta.description}
                            image={overviewResponse.cta.image}
                            link={overviewResponse.cta.button.link}
                            buttontext={overviewResponse.cta.button.text}
                            popup={true}
                            vacancyPopup={{
                                title: `Niet gevonden wat je zocht?`,
                                categories: {},
                            }}
                            notch={['longer', 'back', 'top', 'blue']}
                        />
                    </React.Fragment>
                )}
                <GridTest variants={['default']} />
            </React.Fragment>
        );
    }
}

export default withNamespaces()(VacancyOverview);
