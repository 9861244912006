import React, { Component } from 'react';
import Notch from 'components/notch';
import styles from './styles.scss';
import Button from 'components/button';
import GridTest from 'components/grid-test';

//Icons
import ArrowRight from 'icons/arrow-right';
class FeaturedImage extends Component {
  classNameImage(variants) {
    let image = [];
    image.push(styles['image']);
    if (variants) {
      variants.forEach(function(variant) {
        image.push(styles[`image--${variant}`]);
      });
    }
    return image.join(' ');
  }
  classNameBlock(variants) {
    let block = [];
    block.push(styles['block']);
    if (variants) {
      variants.forEach(function(variant) {
        block.push(styles[`block--${variant}`]);
      });
    }
    return block.join(' ');
  }
  classNameContent(variants) {
    let content = [];
    content.push(styles['content']);
    if (variants) {
      variants.forEach(function(variant) {
        content.push(styles[`content--${variant}`]);
      });
    }
    return content.join(' ');
  }

  render() {
    const { response, align } = this.props;
    return (
      <section
        className={[
          styles['featured-image'],
          align === 'right' ? styles['featured-image--right'] : null
        ].join(' ')}>
        <Notch variants={[align === 'right' ? 'right' : 'left', 'small']} />
        <div className={['wrapper', styles['image-wrapper']].join(' ')}>
          <div
            className={styles.image}
            style={{ backgroundImage: `url(${response.image})` }}
          />
        </div>
        <div className={['wrapper', styles.wrapper].join(' ')}>
          <div className={styles.content}>
            {response.subtitle && (
              <h3 className={styles.subtitle}>{response.subtitle}</h3>
            )}
            <h1
              className={styles.title}
              dangerouslySetInnerHTML={{ __html: response.title }}
            />
            <p
              className={styles.description}
              dangerouslySetInnerHTML={{ __html: response.description }}
            />
            <Button to={response.button.link} className={['large', 'blue']}>
              {response.button.text}
              <ArrowRight fill="white" />
            </Button>
          </div>
        </div>
        <GridTest variants={['default']} />
      </section>
    );
  }
}

export default FeaturedImage;
