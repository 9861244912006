import React from 'react';

const Flange = props => (
  <svg
    {...props}
    width="110px"
    height="104px"
    viewBox="0 0 110 104"
    version="1.1">
    <defs>
      <polygon
        id="path-1"
        points="0 0.132 101.868 0.132 101.868 102 0 102"></polygon>
    </defs>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g>
        <g>
          <path
            d="M53.92,25.837 L56.907,20.665 L53.92,15.493 L47.95,15.493 L44.962,20.665 L47.95,25.837 L53.92,25.837 Z M47.948,77.03 L44.96,82.2 L47.947,87.372 L53.918,87.372 L56.906,82.2 L53.918,77.028 L47.948,77.03 Z M30.724,35.445 L34.946,31.225 L33.401,25.454 L27.632,23.909 L23.408,28.131 L24.955,33.901 L30.724,35.445 Z M71.145,67.421 L66.921,71.644 L68.468,77.413 L74.237,78.959 L78.459,74.735 L76.914,68.966 L71.145,67.421 Z M25.338,48.447 L20.166,45.461 L14.994,48.447 L14.994,54.419 L20.166,57.407 L25.338,54.419 L25.338,48.447 Z M81.702,45.46 L76.53,48.447 L76.53,54.42 L81.702,57.406 L86.876,54.42 L86.876,48.447 L81.702,45.46 Z M24.954,68.967 L23.408,74.736 L27.632,78.96 L33.401,77.413 L34.947,71.644 L30.723,67.422 L24.954,68.967 Z M76.914,33.9 L78.46,28.13 L74.236,23.908 L68.467,25.453 L66.921,31.223 L71.145,35.445 L76.914,33.9 Z"
            fill="#FFFFFF"
            fillRule="nonzero"></path>
          <g transform="translate(0.000000, 0.368000)">
            <g>
              <mask id="mask-2" fill="white">
                <use xlinkHref="#path-1"></use>
              </mask>
              <g id="a"></g>
              <path
                d="M50.934,95.25 C26.571,95.25 6.75,75.429 6.75,51.066 C6.75,26.703 26.571,6.882 50.934,6.882 C75.297,6.882 95.118,26.703 95.118,51.066 C95.118,75.429 75.298,95.25 50.934,95.25 M50.934,0.132 C22.848,0.132 0,22.98 0,51.066 C0,79.152 22.848,102 50.934,102 C79.02,102 101.868,79.152 101.868,51.066 C101.868,22.98 79.02,0.132 50.934,0.132"
                fill="#FFFFFF"
                fillRule="nonzero"
                mask="url(#mask-2)"></path>
            </g>
          </g>
          <path
            d="M50.934,62.037 C45.087,62.037 40.331,57.28 40.331,51.435 C40.331,45.586 45.087,40.83 50.934,40.83 C56.781,40.83 61.538,45.586 61.538,51.435 C61.538,57.28 56.781,62.037 50.934,62.037 M50.934,34.08 C41.366,34.08 33.581,41.866 33.581,51.435 C33.581,61.003 41.366,68.787 50.934,68.787 C60.503,68.787 68.288,61.003 68.288,51.435 C68.288,41.866 60.503,34.08 50.934,34.08"
            fill="#FFFFFF"
            fillRule="nonzero"></path>
        </g>
      </g>
    </g>
  </svg>
);
export default Flange;
