import { Form } from 'mobx-react-form';
import Validator from 'validatorjs';

Validator.useLang(window.localStorage.getItem("i18nextLng"));
let messages = Validator.getMessages(window.localStorage.getItem("i18nextLng"));
Validator.setMessages(window.localStorage.getItem("i18nextLng"), messages);


class UnsubcribeForm extends Form {
  plugins() {
    return { dvr: Validator };
  }

  setup() {
    return {
      fields: [
        {
          name: 'emailaddress',
          label: 'E-mailadres',
          rules: 'required|email|string',
        },
      ],
    };
  }
}
export default UnsubcribeForm;
