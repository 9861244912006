import React, { Component } from "react";
import { detect } from "detect-browser";
import { withNamespaces } from "react-i18next";
// components
import Button from "components/button";

//styles
import styles from "./styles.scss";

class UpdateBrowserNotice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: true,
    };
  }

  setCookie() {
    const browser = detect();
    if (
      (browser && browser.name === "ie" && parseInt(browser.version) <= 11) ||
      (browser && browser.name === "opera-mini") ||
      (browser &&
        browser.name === "samsung" &&
        parseFloat(browser.version) <= 4) ||
      navigator.userAgent === "ReactSnap"
    ) {
      // TEMP
      // if (window.localStorage.getItem('acceptedOldBrowser') === null) {
      //   window.localStorage.setItem('acceptedOldBrowser', 'true');
      // }
      // FINAL
      if (window.localStorage.getItem("acceptedOldBrowser") === null) {
        window.localStorage.setItem("acceptedOldBrowser", "false");
        this.setState({ show: true });
      }
    } else {
      if (window.localStorage.getItem("acceptedOldBrowser") === null) {
        window.localStorage.setItem("acceptedOldBrowser", "true");
        this.setState({ show: false });
      }
    }
  }

  hide = () => {
    window.localStorage.setItem("acceptedOldBrowser", "true");
    this.setState({ show: false });
  };

  componentDidMount() {
    this.setCookie();
    if (window.localStorage.getItem("acceptedOldBrowser") === "true") {
      this.setState({ show: false });
    }
  }

  render() {
    const { t } = this.props
    return window.localStorage.getItem("acceptedOldBrowser") !== "true" ? (
      <div className={styles.notice}>
        <div className={styles.wrapper}>
          <h4 className={styles.title}>
            {t('update-browser title')}
          </h4>
          <div className={styles.content}>
            <p className={styles.description}>
              {t('update-browser description')}
            </p>
            <div className={styles["btn-container"]}>
              <Button
                type="external"
                to="https://browser-update.org/nl/update.html?force_outdated=true"
                className={["blue"]}
              >
                {t('update-browser button')}
              </Button>
              <Button
                type="dummy"
                // className={["large", "blue", disabled ? "disabled" : null]}
                onClick={this.hide}
              >
                {t('update-browser button 2')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    ) : null;
  }
}
export default withNamespaces()(UpdateBrowserNotice);
