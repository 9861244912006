import React, { Component } from 'react';
import Notch from 'components/notch';

// styles
import styles from './styles.scss';

// components
import Item from 'components/featured-filter-categories-item';

class PartnersSectors extends Component {
  render() {
    const { vacancies } = this.props;
    return (
      vacancies && (
        <section className={styles.background}>
          <Notch variants={['navy', 'left']} />
          <div className={['wrapper', styles.block].join(' ')}>
            <div className={styles.content}>
              <h3>{vacancies.subtitle}</h3>
              <h1 dangerouslySetInnerHTML={{ __html: vacancies.title }} />
              <p dangerouslySetInnerHTML={{ __html: vacancies.description }} />
            </div>
            <div className={styles.categories}>
              {vacancies.categories.map((category, i) => {
                return <Item category={category} type="simple" key={i} />;
              })}
            </div>
          </div>
        </section>
      )
    );
  }
}

export default PartnersSectors;
