import React, { Component } from 'react';
import Button from 'components/button';

import styles from './styles.scss';

//icons
import DNA from 'icons/dna';
import ArrowRight from 'icons/arrow-right';

class DnaCta extends Component {
  render() {
    const { response } = this.props;
    return response ? (
      <section className={styles.background}>
        <div className={['wrapper', styles.block].join(' ')}>
          <div className={styles.image}>
            <DNA />
          </div>
          <div className={styles.content}>
            <h1>{response.title}</h1>
            <p dangerouslySetInnerHTML={{ __html: response.description }} />
            <Button to={response.button.link} className={['large', 'white']}>
              {response.button.text} <ArrowRight />
            </Button>
          </div>
        </div>
        {/* <Grid /> */}
      </section>
    ) : null;
  }
}

export default DnaCta;
