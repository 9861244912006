import React, { Component } from "react";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";

// styles
import styles from "./styles.scss";
// icons

import Cog from "icons/cog";
import Logistics from "icons/logistics";
import Mop from "icons/mop";
import Ship from "icons/ship";

// decorators
@observer
class RelatedVacanciesItem extends Component {
  render() {
    const { item, t } = this.props;
    return (
      <Link to={item.slug} className={styles.item}>
        {item.sector === t('Purification') && (
          <Mop fill="white" className={styles.icon} />
        )}
        {item.sector === t('Technics') && <Cog className={styles.icon} />}
        {item.sector === t('Construction') && <Ship className={styles.icon} />}
        {item.sector === t('Logistics') && <Logistics className={styles.icon} />}
        <h4 className={styles.title}>{item.title}</h4>
        <span className={styles.subtitle}>
          {item.sector && item.sector.replace(/\./g, "")} / {item.area}
        </span>
      </Link>
    );
  }
}

export default withNamespaces()(RelatedVacanciesItem);
