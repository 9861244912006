import React from 'react';

const Checkmark = props => (
  <svg {...props} width="18px" height="14px" viewBox="0 0 18 14">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        transform="translate(-818.000000, -2828.000000)"
        fill="#FFFFFF"
        fillRule="nonzero">
        <g transform="translate(-42.000000, 2523.000000)">
          <g>
            <g transform="translate(845.000000, 0.000000)">
              <polygon points="29.6980892 305 28.1159236 306.587402 21.7414013 312.716535 19.8840764 310.99685 18.233121 309.409449 15 312.51811 20.0904459 317.412598 21.6726115 319 33 308.108661" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Checkmark;
