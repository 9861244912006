import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import Notch from 'components/notch';
import Button from 'components/button';

import styles from './styles.scss';

//Icons
import ArrowRight from 'icons/arrow-right';
import Checkmark from 'icons/checkmark';

@inject('homeStore')
@observer
class RadioCompany extends Component {
  render() {
    const { methods } = this.props.homeStore.response;
    return (
      <section>
        <Notch variants={['left']} />
        <div className={['wrapper', styles.block].join(' ')}>
          <div className={styles.content}>
            <h3>Wij zijn Concreeto</h3>
            <h1>Alles laten<br />kloppen.</h1>
            <p>
              We zijn al meer dan 10 jaar een echt Rotterdams
              uitzendbureau. Wij willen elke dag alles laten kloppen. Elkaar
              in de ogen kijken. ‘’Eerlijk en oprecht gewoon doen wat je
              zegt’’, dat vinden we belangrijk. Maar het allerbelangrijkste:
              Onze kracht is de uitzendkracht. Precies daarom werken wij
              dienstbaar en trouw in dienst van jou!
            </p>
            <Button type="external" to="https://www.youtube.com/watch?v=3X_ChfWKYcI" className={['large', 'blue']}>
              Check onze bedrijfsfilm!
              <ArrowRight fill="white" />
            </Button>
          </div>
          <div className={styles.flex}>
            <div className={styles.videopreview}>
              <a target="_blank" className={styles.playbutton} href="https://www.youtube.com/watch?v=3X_ChfWKYcI"></a>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default RadioCompany;
