import React from 'react';

const Search = props => (
  <svg {...props} width="15" height="15" viewBox="0 0 15 15">
    <g fill="#FFF" fillRule="evenodd">
      <path
        fillRule="nonzero"
        d="M6.035 12A6 6 0 1 1 12 6a5.993 5.993 0 0 1-5.965 6zm0-9.635a3.638 3.638 0 0 0-3.672 3.62A3.639 3.639 0 1 0 9.643 6a3.624 3.624 0 0 0-3.608-3.64v.005z"
      />
      <path d="M8 9.737L9.726 8 15 13.263 13.274 15z" />
    </g>
  </svg>
);
export default Search;
