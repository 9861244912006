import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { withNamespaces } from 'react-i18next';

// styles
import styles from './styles.scss';

// components
import RelatedArticles from 'components/related-articles';
import VacancyContent from 'components/vacancy-content';
import ArticleAuthor from 'components/article-author';
import Button from 'components/button';

import ArrowRight from 'icons/arrow-right';

class ArticleDetail extends Component {
  componentDidMount() {
    setTimeout(() => {
      //this.moveElements();
    }, 100);
    window.addEventListener('resize', this.moveElements);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.moveElements, false);
  }

  // moveElements = () => {
  //   if (window.innerWidth < 900) {
  //     if (this.refs.wrapper) {
  //       this.refs.wrapper.prepend(ReactDOM.findDOMNode(this.refs.related));
  //     }
  //   } else {
  //     if (this.refs.related) {
  //       this.refs.content.append(ReactDOM.findDOMNode(this.refs.related));
  //     }
  //   }
  // };

  render() {
    const { detail, t, variant } = this.props;
    return (
      <div className={styles.wrapper} ref='wrapper'>
        <aside className={styles.sidebar}>
          <ArticleAuthor
            detail={{
              id: detail.id,
              author: detail.author,
              categories: detail.categories,
              title: detail.title,
            }}
          />
        </aside>
        <div className={styles.content} ref='content'>
          {variant === 'salesbox' && (
            <img className={styles.logo} src={detail.logo} />
          )}
          <VacancyContent
            content={{ intro: detail.intro, content: detail.content }}
          />
          {detail.related && detail.related.length > 0 && (
            <RelatedArticles ref='related' items={detail.related} />
          )}
          {variant === 'salesbox' && (
            <div className={styles.buttonContainer}>
              <Button
                type='external'
                className={['large', 'blue']}
                to='/over-concreeto'
              >
                {t('About Concreeto')} <ArrowRight />
              </Button>
              <Button
                type='external'
                className={['large', 'blue']}
                to='/werkwijze'
              >
                {t('Workflow')} <ArrowRight />
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withNamespaces()(ArticleDetail);
